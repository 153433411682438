import React, { useEffect, useRef, useState } from "react";
import "./index.css";
import {
  Dialog,
  DialogContent,
  Grid,
  Slide,
  SwipeableDrawer,
} from "@mui/material";
import InfiniteScroll from "react-infinite-scroller";
import MessageCard from "../../../components/MessageCard";
import { constructMessage } from "../../../utils/groupChatUtils";
import EmojiPicker from "emoji-picker-react";
import { CometChat } from "@cometchat-pro/chat";
import { ReactionsList } from "../../../components/ReactionsList/ReactionsList";

const ChatList = ({
  groupMessageList,
  setGroupMessageList,
  messagesRequestRef,
  scrollContainerRef,
}) => {
  const [hasMore, setHasMore] = useState(true);
  const [emojiOpen, setOpenEmoji] = useState(false);
  const [previewIndex, setPreviewIndex] = useState(null);
  const [openEmojiSelector, setOpenEmojiSelector] = useState(false);
  const [bottomSheeet, setBottomSheet] = React.useState(false);
  const emojis = ["👍", "❤️", "😂", "😮", "😢", "🙏🏻"];

  const containerRef = useRef(null);
  const isAtBottomRef = useRef(true); // Flag to track if the container is at the bottom

  
  // Scroll the container to the bottom
  const scrollToBottom = () => {
    if (containerRef.current) {
      containerRef.current.scrollTop = containerRef.current.scrollHeight;
    }
  };

  // Handle scroll event
  const handleScroll = () => {
    const container = containerRef.current;
    const isNearBottom = container.scrollHeight - container.scrollTop <= container.clientHeight + 200;
    isAtBottomRef.current = isNearBottom;
  };

  // Add a scroll event listener to the container
  useEffect(() => {
    const container = containerRef.current;
    container.addEventListener('scroll', handleScroll);

    // Clean up the event listener
    return () => {
      container.removeEventListener('scroll', handleScroll);
    };
  }, []);
  
    // Effect to scroll to the bottom when a new item is added
    useEffect(() => {
      if (isAtBottomRef.current) {
        scrollToBottom();
      }
    }, [groupMessageList]);

  const style = {
    left: "0px",
    right: "0px",
    boxShadow: 24,
    padding: "1rem",
    maxHeight: "300px",
  };

  /* ------------------- Receive Messages On Scroll Up ------------------ */
  const fetchMessagesOnScroll = async () => {
    messagesRequestRef?.current
      ?.fetchPrevious()
      .then((messages) => {
        console.log("More message list fetched:", messages);
        if (messages?.length === 0) {
          setHasMore(false);
        }
        const newList = [...messages, ...groupMessageList];
        setGroupMessageList(newList);
      })
      .catch((error) => {
        console.log("More message fetching failed with error:", error);
      });
  };

  function onClick(emojiData, event) {
    onEmojieSelect(emojiData?.emoji);
  }

  const onEmojieSelect = (emoji) => {
    setPreviewIndex(null);
    const id = groupMessageList[previewIndex]?.id;
    CometChat.callExtension("reactions", "POST", "v1/react", {
      msgId: id,
      emoji: emoji,
    })
      .then(() => {})
      .catch((error) => {
        console.log("Reaction Error ", error);
        // ErrorHandler(error);
      });
  };

  const toggleDrawer = (open) => {
    setBottomSheet(open);
  };
  return (
    <Grid container className="chat-body-container1" ref={containerRef}>
      <InfiniteScroll
        loadMore={() => {
          fetchMessagesOnScroll();
        }}
        hasMore={hasMore}
        pageStart={0}
        loader={
          <div className="loader-container" key={`${new Date().getTime()}`}>
            <span className="load"></span>
          </div>
        }
        isReverse={true}
        useWindow={false}
      >
        <Dialog
          elevation={0}
          hideBackdrop
          open={previewIndex}
          sx={{ margin: 0, padding: 0 }}
          style={{
            position: "fixed",
            backdropFilter: "blur(10px)",
            WebkitBackdropFilter: "blur(10px)",
            boxShadow: "none",
            margin: "-32px",
          }}
          PaperProps={{
            style: {
              backgroundColor: "transparent",
              boxShadow: "none",
            },
          }}
          onClose={() => {
            setPreviewIndex(null);
            setOpenEmojiSelector(false);
          }}
        >
          <DialogContent
            sx={{
              backgroundColor: "transparent",
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
              alignItems: "center",
              overflow: "hidden",
              padding: 0,
            }}
          >
            <Grid
              container
              alignItems="center"
              justifyContent="center"
              style={{ width: "100%" }}
            >
              <Grid
                container
                item
                justifyContent="center"
                style={{ marginBottom: 10, maxWidth: "380px" }}
              >
                <Grid
                  container
                  item
                  xs
                  style={{
                    backgroundColor: "#ffffff",
                    borderRadius: 30,
                    padding: "0px 1rem",
                    boxShadow: "0 1px 10px rgba(0,0,0,0.12)",
                  }}
                >
                  {emojis.map((emoji, index) => (
                    <Grid
                      onClick={(e) => {
                        onEmojieSelect(emoji);
                      }}
                      key={index}
                      item
                      xs
                      style={{ fontSize: 25, padding: 8 }}
                    >
                      {emoji}
                    </Grid>
                  ))}
                  <Grid
                    onClick={() => {
                      setOpenEmojiSelector(!openEmojiSelector);
                    }}
                    item
                    container
                    alignItems="center"
                    xs
                    style={{ fontSize: 30 }}
                  >
                    <div
                      style={{
                        color: "#ffffff",
                        backgroundColor: "#6206BF",
                        borderRadius: "50%",
                        height: 30,
                        width: 30,
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <span style={{ position: "absolute", top: 4.7 }}>+</span>
                    </div>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid
              item
              style={{
                maxHeight: "200px",
                padding: "0.5rem",
                overflowX: "hidden",
                margin: "0px -24px",
                backgroundColor: "#ffffff",
                borderRadius: "8px 8px 0px 0px",
                width: "100%",
              }}
              onClick={() => {
                setOpenEmojiSelector(false);
              }}
            >
              <MessageCard
                messageData={constructMessage(groupMessageList[previewIndex])}
                emojiOpen={emojiOpen}
                setOpenEmoji={setOpenEmoji}
                disableReactions
              />
            </Grid>
            {openEmojiSelector && (
              <div style={{ position: "static", marginTop: "-20px" }}>
                <EmojiPicker
                  searchDisabled
                  onEmojiClick={onClick}
                  height={450}
                  width={"100vw"}
                />
              </div>
            )}
          </DialogContent>
        </Dialog>
        {groupMessageList.length !== 0 &&
          groupMessageList?.map((item, index) => {
            return (
              <MessageCard
                setPreviewIndex={setPreviewIndex}
                messageData={constructMessage(item)}
                key={index}
                index={index}
                emojiOpen={emojiOpen}
                setOpenEmoji={setOpenEmoji}
                toggleDrawer={toggleDrawer}
              />
            );
          })}
        {/* <div className="scroll-contianer" ref={scrollContainerRef}></div> */}
      </InfiniteScroll>
      <SwipeableDrawer
        onClose={() => {
          setBottomSheet(false);
        }}
        anchor={"bottom"}
        open={bottomSheeet}
      >
        {bottomSheeet && (
          <ReactionsList
            anchor={"bottom"}
            toggleDrawer={toggleDrawer}
            bottomSheeet={bottomSheeet}
          />
        )}
      </SwipeableDrawer>
    </Grid>
  );
};

export default ChatList;
