import { createSlice } from "@reduxjs/toolkit";

export const groupChatSlice = createSlice({
  name: "todos",
  initialState: {
    groupDescription: [],
  },
  reducers: {
    setGroupDescription: (state, action) => {
      state.groupDescription = action.payload;
    },
  },
});

export const groupChatActions  = groupChatSlice.actions;
export default groupChatSlice.reducer;
