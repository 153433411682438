import * as React from "react";
import { Button } from "@mui/material";
import { alpha, styled } from "@mui/material/styles";
import { COLORS } from "../../constants";

const OutlineButtonStyled = styled(Button)(
  ({ theme, buttonColor, maxWidth }) => {
    return {
      borderColor: buttonColor,
      borderRadius: "100px",
      width: "100%",
      maxWidth: maxWidth || "100%",
      display: "flex",
      minWidth: "max-content",
      textAlign: "center",
      whiteSpace: "nowrap",
      justifyContent: "center",
      alignItems: "center",
      gap: "8px",
      margin: "5px",
      color: buttonColor,
      textTransform: "initial",
      '&:hover': {
         borderColor: `${buttonColor}!important`,
      },
    };
  }
);

export default function OutlineButton({
  children,
  onClick,
  maxWidth,
  buttonColor = COLORS.PURPLE,
  style,
}) {
  return (
    <OutlineButtonStyled
      variant="outlined"
      style={{maxWidth: maxWidth, ...style}}
      maxWidth={maxWidth}
      onClick={onClick}
      buttonColor={buttonColor}
    >
      {children}
    </OutlineButtonStyled>
  );
}
