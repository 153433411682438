import * as React from "react";
import "./index.css";
import { Box, Card, CardActionArea, CardActions } from "@mui/material";
import { getAuth, signOut } from "firebase/auth";
import { Button } from "@mui/material";
import { isLoaded, useFirestoreConnect } from "react-redux-firebase";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useFirebaseAuth } from "../../utils/contexts/FirebaseAuthContext.js";
import GradientContainer from "../../components/GradientContainer";
import GradientButton from '../../components/GradientButton';


export default function Dashboard() {
  const { user } = useFirebaseAuth();
  // const { uid } = useSelector((state) => state.firebase.auth);

  const navigate = useNavigate();

  // fixed for testing purposes!
  const uid = user.uid;

  console.log(`user uid: ${uid}`);

  useFirestoreConnect([
    {
      collection: "groups",
      where: [
        ["members", "array-contains", uid],
        ["isActive", "==", true],
      ],
      storeAs: "userGroups",
    },
  ]);

  useFirestoreConnect([
    {
      collection: "users",
      doc: uid,
      storeAs: "userInfo",
    },
  ]);

  const groups = useSelector((state) => state.firestore.data["userGroups"]);

  const userInfo = useSelector((state) => state.firestore.data.userInfo);

  const GroupRow = ({ group }) => {
    return (
      <Box
        key={group.groupId}
        sx={{
          justifyContent: "center",
          alignItems: "center",
          marginTop: "20px",
        }}
      >
        <GradientContainer borderRadiusOverride={"20px"}>
          <Card
            sx={{
              flexGrow: 1,
              width: "100%",
              borderRadius: "20px",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <CardActionArea>
              <CardActions
                onClick={() => {
                  navigate(`/groups/${group.groupId}`);
                }}
              >
                <h2 style={{ margin: "25px 5px" }}>{group.name}</h2>
              </CardActions>
            </CardActionArea>
          </Card>
        </GradientContainer>

        {/* <GradientButton onClick={() => navigate(`/groups/${group.groupId}/calendar`)}>View Group Calendar</GradientButton>
      <GradientButton onClick={() => navigate(`/groups/${group.groupId}`)}>View Group</GradientButton> */}
      </Box>
    );
  };

  return isLoaded(groups) && isLoaded(userInfo) && (

    <Box
      alignItems="center"
      justifyContent="center"
      display="flex"
      flexDirection="column"
      paddingTop="24px"
      width="100%"
    >
      <h2 style={{margin: 0}}>Zeal is better on the phone app</h2>
      <p style={{textAlign: 'center'}}>See who's going to events, share photos, send polls, organize activities and more!  </p>
      <a style={{width: "70%"}} href="https://apps.apple.com/us/app/zeal-activities-with-friends/id6446917140">
        <GradientButton style={{minWidth: '225px', marginTop: '5px'}}>Download from App Store now</GradientButton>
      </a>
      <p style={{textAlign: 'center'}}> Android coming soon.</p>
      {groups && (
        <Box width="100%">
          {Object.entries(groups).map(([key, value]) => (
            <GroupRow group={{ ...value, groupId: key }} />
          ))}
        </Box>
      )}
      <Button
        variant="text"
        style={{marginTop: 48, fontFamily: "Causten Regular", textTransform: 'none', color: '#6206BF'}}
        onClick={() => {
          navigate("/");
          signOut(getAuth());
        }}
      >
        Sign Out
      </Button>
      {/* <p style={{padding: '20px', margin: '10px', lineBreak: 'break-word'}}>{JSON.stringify(groups)}</p> */}
    </Box>
  );
}
