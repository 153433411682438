import { Grid, ImageList, ImageListItem } from "@mui/material";
import React, { useEffect, useState } from "react";
import ImageViewer from "react-simple-image-viewer";
import "./index.css";

const ImageMessage = ({ message }) => {
  const images = message?.originalMsg?.data?.attachments;
  const [currentImage, setCurrentImage] = useState(0);
  const [isViewerOpen, setIsViewerOpen] = useState(false);

  const openImageViewer = (index) => {
    setCurrentImage(index);
    setIsViewerOpen(true);
  };

  const closeImageViewer = () => {
    setCurrentImage(0);
    setIsViewerOpen(false);
  };

  // Create a state to store the loaded image dimensions
  const [imageDimensions, setImageDimensions] = useState([]);

  useEffect(() => {
    // Load each image to get its dimensions
    const dimensionsPromises = images.map((item) => {
      return new Promise((resolve) => {
        const img = new Image();
        img.src = item.url;
        img.onload = () => {
          resolve({
            width: img.naturalWidth,
            height: img.naturalHeight,
          });
        };
      });
    });

    // Wait for all dimensions promises to resolve
    Promise.all(dimensionsPromises).then((dimensions) => {
      setImageDimensions(dimensions);
    });
  }, [images]);

  return (
    <Grid container>
      <Grid container style={{ padding: "8px 0px" }}>
        <Grid container style={{ wordBreak: "break-all" }}>
          {message?.message}
        </Grid>

        <ImageList
          sx={{
            gridAutoFlow: "column",
            // gridTemplateColumns:
            //   "repeat(auto-fill,minmax(200px,1fr)) !important",
            // gridAutoColumns: "minmax(200px, 1fr)",
          }}
          // style={{ gap: "20px" }}
        >
          {images.map((item, index) => (
            <ImageListItem onClick={() => openImageViewer(index)}>
              <img
                style={
                  imageDimensions[index]?.width > imageDimensions[index]?.height
                    ? {
                        width: "180px",
                        height: "150px",
                        objectFit: "cover",
                        borderRadius: "4px",
                      }
                    : {
                        width: "120px",
                        height: "150px",
                        objectFit: "cover",
                        borderRadius: "4px",
                      }
                }
                src={item.url}
              />
            </ImageListItem>
          ))}
        </ImageList>

        {/* <ImageList
          style={{ flexWrap: "nowrap", transform: "translateZ(0)" }}
          cols={2.5}
        >
          {images.map((item, index) => (
            <ImageListItem onClick={() => openImageViewer(index)}>
              <img
                style={
                  imageDimensions[index]?.width > imageDimensions[index]?.height
                    ? {
                        width: "180px",
                        height: "150px",
                        objectFit: "cover",
                        borderRadius: "4px",
                      }
                    : {
                        width: "120px",
                        height: "150px",
                        objectFit: "cover",
                        borderRadius: "4px",
                      }
                }
                src={item.url}
              />
            </ImageListItem>
          ))}
        </ImageList> */}
      </Grid>
      {isViewerOpen && (
        <ImageViewer
          src={images.map((item) => item?.url)}
          currentIndex={currentImage}
          disableScroll={false}
          closeOnClickOutside={true}
          onClose={closeImageViewer}
        />
      )}
    </Grid>
  );
};

export default ImageMessage;
