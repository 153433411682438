import moment from "moment";
import { FileTypeConstants } from "./FileTypeConstants";
export const recomposeMessage = (message) => {
  if (message?.getMetadata()?.mention) {
    let text = message?.data?.text;
    message?.getMetadata()?.mention?.forEach((item) => {
      text = text.replaceAll(`{${item.name}|${item.uid}}`, item.name);
    });
    return text;
  } else {
    return message?.data?.text;
  }
};

const getDateTime = (timeStamp) => {
  if (!timeStamp) return;
  const date = moment.unix(timeStamp);
  return {
    time: date.format("LT"),
    date: date.format("D"),
    month: date.format("MMM"),
  };
};

const getReactions = (message) => {
  let reactionsObj;
  const metadata = message?.getMetadata();
  if (metadata != null) {
    const injectedObject = metadata["@injected"];
    if (
      injectedObject != null &&
      injectedObject?.hasOwnProperty("extensions")
    ) {
      const extensionsObject = injectedObject?.extensions;
      if (
        extensionsObject != null &&
        extensionsObject?.hasOwnProperty("reactions")
      ) {
        reactionsObj = extensionsObject?.reactions;
      }
    }
  }
  return reactionsObj;
};

const getPoll = (message) => {
  let pollObj;
  const metadata = message?.getMetadata();
  if (metadata != null) {
    const injectedObject = metadata["@injected"];
    if (
      injectedObject != null &&
      injectedObject?.hasOwnProperty("extensions")
    ) {
      const extensionsObject = injectedObject?.extensions;
      if (
        extensionsObject != null &&
        extensionsObject?.hasOwnProperty("polls")
      ) {
        pollObj = extensionsObject?.polls;
      }
    }
  }
  return pollObj;
};
const getAttachments = (message, fileType) => {
  return message?.data?.attachments
    ?.filter((attachment) => attachment?.mimeType?.includes(fileType))
    ?.map((attachment) => attachment);
};

const getSnnipetObject = (message) => {
  const tempObj = {};
  const metadata = message?.getMetadata();
  if (metadata != null) {
    const injectedObject = metadata["@injected"];
    if (
      injectedObject != null &&
      injectedObject?.hasOwnProperty("extensions")
    ) {
      const extensionsObject = injectedObject?.extensions;
      if (
        extensionsObject != null &&
        extensionsObject?.hasOwnProperty("rich-media")
      ) {
        const linkPreviewObject = extensionsObject["rich-media"];
        const links = linkPreviewObject?.links;
        const icon = links?.icon;
        const thumbnail = links?.thumbnail;
        const meta = linkPreviewObject?.meta;
        tempObj.image = icon?.length > 0 ? icon[0]?.href : "";
        tempObj.favicon = thumbnail?.length > 0 ? thumbnail[0]?.href : "";
        tempObj.description = meta?.description;
        tempObj.title = meta?.title;
        tempObj.url = linkPreviewObject?.url;
      }
    }
  }
  if (tempObj != null && tempObj?.hasOwnProperty("url")) {
    return tempObj;
  } else return false;
};

export const constructMessage = (message) => {
  return {
    originalMsg: message,
    id: message?.id,
    conversationId: message?.conversationId,
    parentMessageId: message?.parentMessageId,
    receiverType: message?.receiverType,
    receiverId: message?.receiverId,
    senderInfo: message?.sender,
    message: recomposeMessage(message),
    sentAt: getDateTime(message?.sentAt),
    // role: "admin",
    // images: getAttachments(message, FileTypeConstants.IMAGE),
    // videos: getAttachments(message, FileTypeConstants.VIDEO),
    files: getAttachments(message, FileTypeConstants.PDF),
    reactions: getReactions(message),
    // color: !!message?.getMetadata()?.mention,
    // mention: message?.getMetadata()?.mention,
    snnipetObj: getSnnipetObject(message),
    pollObj: getPoll(message),
    eventObj: message?.getMetadata()?.eventData,
    // readAt: message?.readAt,
    type: message?.type,
    category: message?.category,
    replyCount: message?.replyCount,
    // tags: message?.getTags(),
  };
};
