import React, { useState, useEffect } from 'react';
import DayPicker from '../../components/DayPicker';
import { Box } from '@mui/material';
import 'reactjs-popup/dist/index.css';

import { ROUTES } from '../../constants';

import { useOutletContext } from 'react-router-dom';

import GradientButton from '../../components/GradientButton';
import GradientContainer from '../../components/GradientContainer';


export default function ActivityDays() {

  const { groupId, invitationId, setNextPageCallback, continueCallback, setHasSkip } = useOutletContext();

  const [selectedDays, setSelectedDays] = useState([]);
  const [error, setError] = useState(false);

  useEffect(() => {
    setHasSkip(false)
    setNextPageCallback(ROUTES.Availability);
  }, []);

  const nextPage = () => {
    console.log(selectedDays)
    continueCallback(undefined, {
      selectedDaysData: selectedDays,
    });
  }

  useEffect(() => {
    if (selectedDays.length > 0) {
      setError(false);
    } 
  }, [selectedDays])

  return (
    <Box id='preferred-times-container'>
      <Box id='preferred-times-body'>
        <Box sx={{display: 'flex', justifyContent: 'center', flexDirection: 'column', textAlign: 'center', alignItems: 'center'}}>
          <h1>Which days of the week are you generally available?</h1>
          <p>Since Zeal can’t see your calendar, we need your general availability for all group activities.</p>
          <Box sx={{display: 'flex', flex: 1, justifyContent: 'center', alignItems: 'center', maxWidth: '400px', flexDirection: 'column'}}>
            <Box sx={{display: 'flex', flex: 1, alignItems: 'center', justifyContent: 'center', flexDirection: 'column', textAlign: 'left', width: '100%'}}>
              <DayPicker selectedDays={selectedDays} setSelectedDays={setSelectedDays} label="Select all the days of the week that work for you." />
            </Box>
            <Box sx={{width: '100%', textAlign: 'left', marginLeft: '10px'}}>
              {error && <span style={{color: 'red', textAlign: 'left'}}>You haven't selected any days.</span>}
            </Box>
            <Box style={{marginTop: '10px', width: '70%'}}>
              <GradientButton onClick={() => {
                if (selectedDays.length === 0) {
                  setError(true);
                  return;
                } else {
                  nextPage();
                }
              }}  maxWidth='none'>Continue</GradientButton>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
