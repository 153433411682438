import mediaIcon from "../static/img/groupDashboard/composerOptions/media.svg";
import pollIcon from "../static/img/groupDashboard/composerOptions/poll.svg";
import fileIcon from "../static/img/groupDashboard/composerOptions/file.svg";
import cameraIcon from "../static/img/groupDashboard/mediaOptions/camera.svg";
import imageIcon from "../static/img/groupDashboard/mediaOptions/imageGallery.svg";
import videoIcon from "../static/img/groupDashboard/mediaOptions/videoGallery.svg";
import plusIcon from "../static/img/groupDashboard/composerInput/plus.svg";
import crossIcon from "../static/img/groupDashboard/composerInput/cross.svg";
import sendIcon from "../static/img/groupDashboard/composerInput/send.svg";
import zealLogo from "../static/img/zealLogo.svg";
import zealHorizontalLogo from "../static/img/zealHorizontalLogo.svg";
import zealProfile from "../static/img/zealProfile.svg";

const images = {
  mediaIcon,
  pollIcon,
  fileIcon,
  cameraIcon,
  imageIcon,
  videoIcon,
  plusIcon,
  crossIcon,
  sendIcon,
  zealLogo,
  zealHorizontalLogo,
  zealProfile,
};

export default images;
