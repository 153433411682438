import React, { useState } from "react";
import "./index.css";
import ComposerInput from "./ComposerInput";
import ComposerOptions from "./ComposerOptions";
import Preview from "./Preview";
import CompressionProgressBar from "./CompressionProgressBar";
import UploadingProgressBar from "./UploadingProgressBar";

const Composer = ({
  receiverId,
  receiverType,
  scrollToBottom,
  setMessageList,
  messageList,
}) => {
  const [isComposerOptionsVisible, setIsComposerOptionsVisible] =
    useState(false);
  const [previewList, setPreviewList] = useState([]);
  const [messageType, setMessageType] = useState("");
  const [isSendBtnEnabled, setIsSendBtnEnabled] = useState(false);
  const [compressionProgress, setCompressionProgress] = useState("");
  const [uploadingProgress, setUploadingProgress] = useState(0);
  const [isUploading, setIsUploading] = useState(false);

  return (
    <>
      {isUploading && (
        <UploadingProgressBar
          progress={uploadingProgress}
          setProgress={setUploadingProgress}
        />
      )}
      {compressionProgress && (
        <CompressionProgressBar progress={compressionProgress} />
      )}
      <Preview
        previewList={previewList}
        setPreviewList={setPreviewList}
        messageType={messageType}
        setIsSendBtnEnabled={setIsSendBtnEnabled}
      />
      <ComposerInput
        isComposerOptionsVisible={isComposerOptionsVisible}
        setIsComposerOptionsVisible={setIsComposerOptionsVisible}
        previewList={previewList}
        setPreviewList={setPreviewList}
        messageType={messageType}
        setMessageType={setMessageType}
        receiverId={receiverId}
        receiverType={receiverType}
        isSendBtnEnabled={isSendBtnEnabled}
        setIsSendBtnEnabled={setIsSendBtnEnabled}
        scrollToBottom={scrollToBottom}
        setMessageList={setMessageList}
        messageList={messageList}
        setUploadingProgress={setUploadingProgress}
        setIsUploading={setIsUploading}
      />
      {isComposerOptionsVisible && (
        <ComposerOptions
          setPreviewList={setPreviewList}
          setMessageType={setMessageType}
          setIsSendBtnEnabled={setIsSendBtnEnabled}
          setCompressionProgress={setCompressionProgress}
        />
      )}
    </>
  );
};

export default Composer;
