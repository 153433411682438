import { CometChat } from "@cometchat-pro/chat";
import { Grid } from "@mui/material";
import React, { useState } from "react";
import styles from "./createPoll.module.css";
import { useNavigate } from "react-router-dom";
import { useOutletContext } from "react-router-dom";
import ZealButton from "../../components/ZealButton";
import { triggerPollCloudFunction } from "../../utils/triggerCloudFunctions";

const CreatePoll = () => {
  const { groupId, group, conversationID } = useOutletContext();
  const [options, setOptions] = useState([{ text: "" }, { text: "" }]);
  const [pollTitle, setPollTitle] = useState("");
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const handlePollTitleChange = (event) => {
    setPollTitle(event.target.value);
  };

  const handleOptionChange = (index, value) => {
    const updatedOptions = [...options];
    updatedOptions[index].text = value;
    setOptions(updatedOptions);
  };

  const handleAddOption = () => {
    setOptions([...options, { text: "" }]);
  };

  const handleCancel = () => {
    navigate(-1);
  };

  const handleRemoveOption = (index) => {
    const updatedOptions = [...options];
    updatedOptions.splice(index, 1);
    setOptions(updatedOptions);
  };

  const createPoll = async () => {
    setLoading(true);
    try {
      const pollAnswersList = options.map(
        (item) => item.text !== "" && item.text
      );
      const filteredAnswersList = pollAnswersList.filter(Boolean);

      await CometChat.callExtension("polls", "POST", "v2/create", {
        question: pollTitle,
        options: filteredAnswersList,
        receiver: groupId,
        receiverType: CometChat.RECEIVER_TYPE.GROUP,
      }).then((response) => {
        console.log("Poll Created successfully", response);
        triggerPollCloudFunction(
          groupId,
          conversationID,
          group?.name,
          response["id"]
        );
      });
      setLoading(false);
      handleCancel();
    } catch (error) {
      console.log("Error in Poll Creation ", error);
    }
  };

  return (
    <Grid container className={styles.createPollContainer}>
      <Grid container justifyContent="center">
        <Grid item xs textAlign="center" className={styles.heading}>
          <span style={{ paddingLeft: 30 }}>New Poll</span>
        </Grid>
        <Grid
          item
          xs="auto"
          alignSelf="flex-end"
          className={styles.cancelButton}
          onClick={handleCancel}
        >
          Cancel
        </Grid>
      </Grid>
      <Grid container className={styles.textfield}>
        <label htmlFor="pollTitle" className={styles.PollTitleLabel}>
          Poll Title
        </label>
        <input
          type="text"
          id="pollTitle"
          placeholder="Name Poll"
          className={styles.inputField}
          value={pollTitle}
          onChange={handlePollTitleChange}
        />
      </Grid>
      <div className={styles.container}>
        <div className={styles.label}>Input Box</div>
        <div className={styles.inputRow}>
          {options.map((item, index) => (
            <div key={index} className={styles.inputBox}>
              <div className={styles.inputFieldContainer}>
                <input
                  type="text"
                  placeholder={`Options ${index + 1}`}
                  value={item.text}
                  onChange={(e) => handleOptionChange(index, e.target.value)}
                  className={styles.inputField}
                />
                <div
                  className={styles.removeButton}
                  onClick={() => handleRemoveOption(index)}
                >
                  -
                </div>
              </div>
            </div>
          ))}
        </div>
        <div className={styles.addButton} onClick={handleAddOption}>
          <div style={{ fontWeight: 600 }}>Add option</div>
          <div className={styles.plusIcon}>+</div>
        </div>
      </div>
      <Grid container justifyContent="center">
        <ZealButton
          loading={loading}
          onClick={createPoll}
          text="Post Poll"
          buttonStyle={{
            width: "400px",
            height: "35px",
            borderRadius: "20px",
            marginTop: 30,
          }}
        ></ZealButton>
      </Grid>
    </Grid>
  );
};

export default CreatePoll;
