// Header.js
import React, { useState, useEffect } from "react";
import styles from "./index.module.css";
import images from "../../../assets/images";
import { Grid } from "@mui/material";

const Header = () => {
  const [isFixed, setIsFixed] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.scrollY;
      setIsFixed(scrollPosition > 0);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <>
      <header className={`${styles.header} ${isFixed ? styles.fixed : ""}`}>
        <Grid container>
          {isFixed ? (
            <>
              <Grid
                item
                xs={2}
                style={{ position: "absolute", paddingLeft: "2rem" }}
              >
                <img
                  src={images.zealHorizontalLogo}
                  alt="group-logo"
                  className={styles.zealHorizontalLogo}
                />
              </Grid>

              <Grid container item xs={12} justifyContent="center">
                <h1 className={styles.headerTitle}>Discover Feeds</h1>
              </Grid>
            </>
          ) : (
            <>
              <Grid container justifyContent="center">
                <img
                  src={images.zealHorizontalLogo}
                  alt="group-logo"
                  className={styles.zealLogo}
                />
              </Grid>
            </>
          )}
        </Grid>
      </header>
      {isFixed && <span style={{ height: "70.54px" }}></span>}
    </>
  );
};

export default Header;
