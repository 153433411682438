export const recomposeMessage = (message) => {
  if (message?.getMetadata()?.mention) {
    let text = message?.data?.text;
    message?.getMetadata()?.mention?.forEach((item) => {
      text = text.replaceAll(`{${item.name}|${item.uid}}`, item.name);
    });
    return text;
  } else {
    return message?.data?.text;
  }
};
