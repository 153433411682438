import * as React from "react";
import { Button } from "@mui/material";
import { alpha, styled } from "@mui/material/styles";

const GradientButtonStyled = styled(Button)(
  ({ theme, startcolor, endcolor, maxWidth }) => {
    if (!startcolor || !endcolor) {
      startcolor = "#973DF2";
      endcolor = "#38A3A5";
    }
    return {
      width: "100%",
      minWidth: "200px",
      maxWidth: maxWidth || "100%",
      maxHeight: "50px",
      display: "flex",
      fontSize: "large",
      textAlign: "center",
      whiteSpace: "nowrap",
      minWidth: "max-content",
      justifyContent: "center",
      alignItems: "center",
      color: "#FFFFFF",
      flex: 1,
      gap: "8px",
      background: `linear-gradient(96.15deg, ${startcolor} 35.32%, ${endcolor} 114.8%)`,
      borderRadius: "100px",
      "& .MuiSlider-thumb": {
        "&:hover, &.Mui-focusVisible": {
          boxShadow: `0px 0px 0px 8px ${alpha(
            theme.palette.success.main,
            0.16
          )}`,
        },
        "&.Mui-active": {
          boxShadow: `0px 0px 0px 14px ${alpha(
            theme.palette.success.main,
            0.16
          )}`,
        },
      },
      "&.Mui-disabled": {
        background: `linear-gradient(96.15deg, rgba(98, 6, 191, 0.4) 35.32%, rgba(102, 51, 153, 0.4) 114.8%)`,
        color: "#FFFFFF",
      },
      textTransform: "initial",
    };
  }
);

export default function GradientButton({
  children,
  onClick,
  startcolor,
  endcolor,
  maxWidth,
  disabled,
  style,
  props,
}) {
  return (
    <GradientButtonStyled
      disabled={disabled}
      style={{ maxWidth: maxWidth, ...style }}
      className="zealGradientButton"
      startcolor={startcolor}
      endcolor={endcolor}
      onClick={onClick}
      {...props}
    >
      {children}
    </GradientButtonStyled>
  );
}
