import React, { useState } from "react";
import { useSelector } from "react-redux";
import { isLoaded, isEmpty, useFirestoreConnect } from "react-redux-firebase";
import { useNavigate, useParams } from "react-router-dom";
import { populate } from "react-redux-firebase";
import { Box, Button } from "@mui/material";
import { useFirebaseAuth } from "../../utils/contexts/FirebaseAuthContext.js";
import { Outlet } from "react-router-dom";
import "./index.css";
import GradientWrapperWithHeader from "../../components/GradientWrapper/GradientWrapperWithHeader.jsx";
import GroupHeader from "../../components/GroupHeader";
import { ROUTES } from "../../constants/index.js";

export default function Group() {
  const { groupId } = useParams();

  const { user } = useFirebaseAuth();
  let navigate = useNavigate();

  const profile = useSelector((state) => state.firebase.profile); // grab current user info

  const membersPopulates = [
    { child: "members", root: "users" }, // replace owner with user object
  ];

  const [prevPage, setPrevPage] = useState(null);
  const [nextPage, setNextPage] = useState(null);

  // sync document into redux store
  useFirestoreConnect([
    {
      collection: "groups",
      doc: groupId,
      storeAs: `group-${groupId}`,
      populates: membersPopulates,
    },
  ]);

  useFirestoreConnect(["activityTypes"]);

  useFirestoreConnect([
    {
      collection: "groups",
      doc: groupId,
      subcollections: [{ collection: "memberSnippets" }],
      storeAs: `memberSnippets-${groupId}`,
    },
  ]);

  useFirestoreConnect([
    {
      collection: "groups",
      doc: groupId,
      subcollections: [{ collection: "events" }],
      storeAs: `events-${groupId}`,
    },
  ]);

  const group = useSelector((state) =>
    populate(state.firestore, `group-${groupId}`, membersPopulates)
  );

  console.log(useSelector((state) => state.firestore.ordered.events ))

  // TO DO: Need a better, more precise way of implementing the following (commented out in the interim). Jake, I have faith in you :)

  const goBack = () => {
    if (prevPage) {
      navigate(`/groups/${groupId}/${prevPage}`);
      setPrevPage(null);
    } else {
      navigate(-1);
    }
  };
  const setPrevPageCallback = (page) => {
    setPrevPage(page);
  };
  // If user is not logged in or not in the, group then don't allow them to access the group info page
  // if(isLoaded(group) && !isEmpty(group) && group && group.members && Object.entries(group.members).filter(([_, member]) => member.id === user.uid ).length === 0) {
  //   return(
  //     <Box alignItems='center' justifyContent='center' className='backgroundGradient'>
  //     <Box className='innerBox' style={{display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'flex-start'}}>
  //       <h1>Sorry, group info could not be found.</h1>
  //       <img className='groupThumbnail' src='https://firebasestorage.googleapis.com/v0/b/joy-prototype.appspot.com/o/zeal_banner.svg?alt=media&token=9a574c6d-da7d-4557-958a-ddf83d38afca' alt='group thumbnail'/>
  //       <p>Please download the <a href='/app-download'>Zeal App</a> to unlock additional features such as group messaging!</p>
  //     </Box>
  //   </Box>
  //   )
  // }

  const setNextPageCallback = (page) => {
    setNextPage(page);
  };

  const continueCallback = (next) => {
    if (next === undefined) {
      next = nextPage;
    }
    navigate(`/groups/${groupId}/${next}`);
    setNextPage(null);
    setPrevPage(null);
  };

  // If user is not logged in or not in the, group then don't allow them to access the group info page
  if (
    isLoaded(group) &&
    !isEmpty(group) &&
    group &&
    group.members &&
    Object.entries(group.members).filter(
      ([_, member]) => member.id === user.uid
    ).length === 0
  ) {
    return (
      <Box
        alignItems="center"
        justifyContent="center"
        className="backgroundGradient"
      >
        <Box
          className="innerBox"
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "flex-start",
          }}
        >
          <h1>Sorry, group info could not be found.</h1>
          <img
            className="groupThumbnail"
            src="https://firebasestorage.googleapis.com/v0/b/joy-prototype.appspot.com/o/zeal_banner.svg?alt=media&token=9a574c6d-da7d-4557-958a-ddf83d38afca"
            alt="group thumbnail"
          />
          <p>
            Please download the <a href="/app-download">Zeal App</a> to unlock
            additional features such as group messaging!
          </p>
        </Box>
      </Box>
    );
  }

  return (
    <GradientWrapperWithHeader
      // noScroll
      displayHeader
      group={group}
      groupId={groupId}
    >
      {!isLoaded(group) && <div className="load"></div>}
      {isLoaded(group) && isEmpty(group) && <h1>Group is empty</h1>}
      {group && (
        <>
          <Outlet
            context={{
              groupId,
              group,
              user,
              setNextPageCallback,
              continueCallback,
              setPrevPageCallback,
              goBack,
            }}
          />
        </>
      )}
    </GradientWrapperWithHeader>
  );
}
