import { Grid } from "@mui/material";
import React, { useRef } from "react";
import "./index.css";
import { MessageTypes } from "../../../utils/groupChatUtils/MessageTypes";
import images from "../../../assets/images";
import Compressor from "compressorjs";

const MediaOptions = ({
  setMessageType,
  setPreviewList,
  setIsMediaOptionsVisible,
  setIsSendBtnEnabled,
  setCompressionProgress,
}) => {
  const imageRef = useRef(null);
  const videoRef = useRef(null);

  const handleFileChange = async (e, mediaType) => {
    let files = e.target.files;
    console.log("Selected files are ", files);
    if (files) files = Array.from(files);

    if (mediaType === MediaTypes.IMAGE) {
      setMessageType(MessageTypes.IMAGE);
      try {
        const compressedFileList = await compressFileList(files);
        setPreviewList(compressedFileList);
        setCompressionProgress("");
      } catch (error) {
        console.error("Error compressing files:", error);
      }
    }
    if (mediaType === MediaTypes.VIDEO) {
      setMessageType(MessageTypes.VIDEO);
      setPreviewList(files);
    }
    setIsSendBtnEnabled(true);
    setIsMediaOptionsVisible(false);
  };

  const openFileInput = (mediaType) => {
    if (mediaType === MediaTypes.IMAGE) imageRef.current.click();
    if (mediaType === MediaTypes.VIDEO) videoRef.current.click();
  };

  const compressFileList = (fileList) => {
    let compressing = 1;
    setCompressionProgress(`${compressing}/${fileList.length}`);
    return Promise.all(
      fileList.map((file) => {
        let promise = compressFile(file);
        promise.then((compressedFile) => {
          compressing++;
          setCompressionProgress(`${compressing}/${fileList.length}`);
        });
        return promise;
      })
    );
  };

  const compressFile = (file) => {
    return new Promise((resolve, reject) => {
      new Compressor(file, {
        quality: 0.8,
        success: (compressedBlob) => {
          // Convert Blob to File
          const compressedFile = new File([compressedBlob], file.name, {
            type: compressedBlob.type,
          });

          resolve(compressedFile);
        },
        error: (error) => {
          reject(error);
        },
      });
    });
  };

  return (
    <Grid container className="media-options">
      <Grid
        container
        alignItems="center"
        className="media-option"
        onClick={() => openFileInput(MediaTypes.IMAGE)}
      >
        <img src={images.imageIcon} alt="camera" />
        <span className="media-option-text">Select photos from gallery</span>
        <input
          type="file"
          accept="image/*"
          onChange={(e) => handleFileChange(e, MediaTypes.IMAGE)}
          ref={imageRef}
          multiple={true}
          style={{ display: "none" }}
        />
      </Grid>
    </Grid>
  );
};

const MediaTypes = {
  IMAGE: "image",
  VIDEO: "video",
};

export default MediaOptions;
