import React, { useState, useEffect } from "react";
import { Box, TextField } from "@mui/material";
import { useOutletContext } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import GradientButton from "../../components/GradientButton";
import FormCard from "../../components/FormCard";
import { useFirestore } from "react-redux-firebase";
import { useFirebaseAuth } from "../../utils/contexts/FirebaseAuthContext.js";
import { ROUTES } from "../../constants";
import { useSelector } from "react-redux";
import ZealInput from "../../components/ZealInput";

export default function SetLocationAndBio() {
  const {
    group,
    userId,
    invitation,
    continueCallback,
    isFastTrack,
    setHasSkip,
    setNextPageCallback,
    rsvpStatus,
  } = useOutletContext();

  const navigate = useNavigate();

  const firestore = useFirestore();

  const { user } = useFirebaseAuth();

  const zipRegEx = /^[0-9]{5}$/;

  const [zips, setZips] = useState(
    user?.locations && user?.locations.length > 0 ? user?.locations : [""]
  );
  const [bio, setBio] = useState("");
  const [nextPage, setNextPage] = useState("");

  const setZipsValue = (zips) => {
    if (zips.length <= 0) return;
    if (zips[0].length > 5) return;
    setZips(zips);
  };

  // sets locations in Firestore
  const setLocationsAndBio = () => {
    // filter out empty zipcodes
    const filteredZips = zips.filter((el) => el.length > 0);
    firestore
      .collection("users")
      .doc(user.uid)
      .update({ locations: filteredZips, bio })
      .then(() => {
        console.log("Locations successfully added to Firestore");
        handleContinue();
      });
  };

  useEffect(() => {
    setHasSkip(false);
    if (isFastTrack) {
      if (rsvpStatus == "declined") {
        setNextPage(ROUTES.AppDownload);
        setNextPageCallback(ROUTES.AppDownload);
      } else {
        if (!group.hasSchedule) {
          setNextPage(ROUTES.Calendar);
          setNextPageCallback(ROUTES.Calendar);
        } else if (group?.plusOnes && parseInt(group?.plusOnes) > 0) {
          setNextPage(ROUTES.InviteFriends);
          setNextPageCallback(ROUTES.InviteFriends);
        } else {
          setNextPage(ROUTES.AppDownload);
          setNextPageCallback(ROUTES.AppDownload);
        }
      }
    } else {
      setNextPage(ROUTES.Calendar);
      setNextPageCallback(ROUTES.Calendar);
    }
  }, []);

  const handleContinue = () => {
    continueCallback(nextPage);
  };

  return (
    <FormCard center={true}>
      <h1>Share more about you</h1>
      <h4 style={{ textAlign: "left" }}>
        Zeal will recommend people and activities near you
      </h4>
      <ZealInput
        value={bio}
        label="Your Bio"
        onChange={(e) => {
          setBio(e.target.value);
        }}
        style={{ width: "80%" }}
        placeholder="Type a short bio"
        multiline
      />
      {zips.map((item, index) => (
        <ZealInput
          value={item}
          label="Zip Code"
          onChange={(e) => {
            const nextZips = zips.map((el, i) => {
              if (i === index) {
                return e.target.value;
              }
              return el;
            });
            setZipsValue(nextZips);
          }}
          style={{ width: "80%", margin: "16px 0px 0px 0px" }}
          placeholder="Your zip code"
          key={index}
        />
      ))}

      <Box
        style={{
          width: "100%",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          marginTop: "24px",
        }}
      >
        <GradientButton
          maxWidth="343px"
          disabled={
            zips.length == 0 ||
            zips[0] == "" ||
            zips.filter((z) => zipRegEx.test(z)).length === 0
          }
          onClick={() => {
            setLocationsAndBio();
          }}
        >
          Continue
        </GradientButton>
      </Box>
    </FormCard>
  );
}
