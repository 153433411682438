import * as React from "react";
import "./index.css";
import { Grid } from "@mui/material";

const GradientWrapper = ({ children, displayHeading, noScroll }) => {
  return (
    <Grid
      container
      alignItems="center"
      justifyContent="center"
      className={noScroll ? "noScrollbackgroundGradient" : "backgroundGradient"}
    >
      <Grid
        item
        xs={12}
        alignItems="center"
        justifyContent="flex-start"
        display="flex"
        flexDirection="column"
        className="innerWhiteBox"
      >
        {displayHeading ? (
          <div
            style={{ width: "100%", display: "flex", justifyContent: "center" }}
          >
            <img
              className="logoImg"
              src="https://firebasestorage.googleapis.com/v0/b/joy-prototype.appspot.com/o/zeal-logo-stacked.svg?alt=media&token=d5b70703-8a4d-4626-a75f-63ab7664f6f7"
              alt="zeal logo"
            />
          </div>
        ) : (
          ""
        )}
        {children}
      </Grid>
    </Grid>
  );
};

export default GradientWrapper;
