import React, { useEffect } from "react";
import { Box, LinearProgress, Typography } from "@mui/material";

const UploadingProgressBar = ({ progress, setProgress }) => {
  useEffect(() => {
    // Simulate the progress increase every 500 milliseconds
    const timer = setInterval(() => {
      // Update the progress by a random value between 5 to 15
      setProgress((prevProgress) => {
        const nextProgress = prevProgress + Math.floor(Math.random() * 2) + 5;
        return nextProgress <= 100 ? nextProgress : 100;
      });
    }, 1000);

    return () => {
      clearInterval(timer);
    };
  }, [progress]);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        mb: 1,
        pt: 1,
      }}
    >
      <Box sx={{ width: "70%", mb: 1 }}>
        <LinearProgress
          variant="determinate"
          value={progress}
          sx={{
            // Change the color of the LinearProgress bar
            "& .MuiLinearProgress-bar": {
              backgroundColor: "#6206bf",
            },
            // Change the background strip color
            "& .MuiLinearProgress-background": {
              backgroundColor: "rgba(98, 6, 191, 0.2)",
            },
          }}
        />
      </Box>
      <Box>
        <Typography
          variant="body2"
          style={{ color: "#6206BF" }}
        >{`Uploading ${progress}%`}</Typography>
      </Box>
    </Box>
  );
};

export default UploadingProgressBar;
