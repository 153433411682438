import { Box, Typography } from "@mui/material";
import * as React from "react";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { isLoaded } from "react-redux-firebase";
import { useOutletContext } from "react-router-dom";
import GroupHeader from "../../components/GroupHeader/index.jsx";
import { ROUTES } from "../../constants/index.js";
import { useFirebaseAuth } from "../../utils/contexts/FirebaseAuthContext.js";
import "./index.css";

export default function AboutGroup() {
  const { group, groupId, continueCallback } = useOutletContext();
  const { user } = useFirebaseAuth();

  // fixed for testing purposes!
  const uid = user.uid;

  console.log(`user uid: ${uid}`);

  const activityTypes = useSelector(
    (state) => state.firestore.data.activityTypes
  );

  const memberSnippets = useSelector(
    (state) => state.firestore.ordered[`memberSnippets-${groupId}`]
  );

  return (
    isLoaded(activityTypes) &&
    isLoaded(memberSnippets) && (
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
        }}
      >
        <GroupHeader
          onPress={() => {
            continueCallback(ROUTES.AboutGroup);
          }}
          groupName={group.name}
          groupSize={Object.keys(group.members).length}
          groupActivity={activityTypes[group.activityId].name}
        />
        <Typography variant="h6" sx={{ marginTop: 3, marginBottom: 3 }}>
          Members
        </Typography>
        {memberSnippets.map((member) => {
          return (
            <Box
              key={member.id}
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                borderBottom: "1px solid #D3D3D4",
                padding: "8px 0px",
              }}
            >
              {member.profilePicture ? (
                <img
                  className="memberProfilePicture"
                  alt="Member Profile Pic"
                  src={member.profilePicture}
                />
              ) : (
                <img
                  className="memberProfilePicture"
                  alt="Default Profile Pic"
                  src={require("../../static/img/placeholder.png")}
                />
              )}

              <Typography
                sx={{ display: "inline-block" }}
              >{`${member.firstName} ${member.lastName}`}</Typography>
              {member.memberType === "admin" && (
                <>
                  &nbsp;
                  <Typography
                    sx={{ display: "inline-block", fontWeight: "bold" }}
                  >
                    - Admin
                  </Typography>
                </>
              )}
            </Box>
          );
        })}
      </Box>
    )
  );
}
