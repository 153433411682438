import React, { useEffect } from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import {
  createBrowserRouter,
  createRoutesFromElements,
  Route,
  RouterProvider,
} from "react-router-dom";
import { ROUTES } from "./constants";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";


import Root from "./routes/root";
import LogIn from "./routes/LogIn";
import OF3LogIn from "./routes/OF3LogIn";
import Group from "./routes/Group";
import Invitation from "./routes/Invitations"; // temporary!
import FindInvitation from "./routes/FindInvitation";
import Landing from "./routes/Landing";
import Home from "./routes/Home";
import SignUp from "./routes/SignUp";
import IntegrateCalendar from "./routes/CalendarIntegration";
import ActivityDays from "./routes/ActivityDays";
import PreferredTimes from "./routes/PreferredTimes";
import ErrorPage from "./routes/error-page";
import OnboardingFlow from "./routes/OnboardingFlow";
import Dashboard from "./routes/Dashboard";
import InviteFriends from "./routes/InviteFriends";
import UserConsent from "./routes/UserConsent";
import AppDownload from "./routes/AppDownload";
import PollResultsList from "./routes/PollResultsList/PollResultsList";
import SelectInterests from "./routes/SelectInterests";
import SetLocationAndBio from "./routes/SetLocation";

import "firebase/firestore"; // <- needed if using firestore

import { Provider } from "react-redux";
import firebase, { rrfConfig } from "./services/firebaseConfig";
import { createStore, combineReducers } from "redux";
import {
  firebaseReducer,
  ReactReduxFirebaseProvider,
} from "react-redux-firebase";
import { firestoreReducer, createFirestoreInstance } from "redux-firestore";

import {
  AuthenticatedRoute,
  UnauthenticatedRoute,
} from "./utils/navigation/authenticatedRoutes";
import GroupHome from "./routes/GroupHome";
import { FirebaseAuthProvider } from "./utils/contexts/FirebaseAuthContext";
import theme from "./styles/theme";
import { ThemeProvider } from "@mui/material";
import UserStack from "./routes/UserStack";
import AboutGroup from "./routes/AboutGroup";
import { CometChat } from "@cometchat-pro/chat";
import EmailVerified from "./routes/EmailVerified";
import GroupDetails from "./routes/GroupDetails";
import GroupChat from "./routes/GroupChat";
import groupChat from "./store/group-chat";
import EmailUnsubscribe from "./routes/EmailUnsubscribe";
import CreatePoll from "./routes/createPollComponent";
import SignUpFast from "./routes/SignUpFast";
import PersonalEventLanding from "./routes/PersonalEventLanding";
import SignUpPE from "./routes/SignUpPE";
import SignUpFastPE from "./routes/SignUpFastPE";
import ThankYou from "./routes/ThankYou";
import DiscoverScreen from "./routes/DiscoverScreen";
// import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route path={ROUTES.Home} element={<Root />}>
      <Route path={ROUTES.Home} element={<Home />} />
      <Route path={ROUTES.SignUp} element={<SignUp />} />
      <Route path={ROUTES.LogIn} element={<LogIn />} />
      <Route path={ROUTES.AdminLogIn} element={<LogIn admin={true} />} />

      <Route path={ROUTES.Calendar} element={<IntegrateCalendar />} />

      <Route path={ROUTES.Availability} element={<PreferredTimes />} />

      {/* <Route element={<UnauthenticatedRoute/>}>
        <Route path='signup' element={<SignUp />} />
      </Route> */}
      {/* <Route element={<UnauthenticatedRoute/>}>
        <Route path='login' element={<LogIn />} />
      </Route> */}
      {/* <Route element={<AuthenticatedRoute/>}>
        <Route path='dashboard' element={<Dashboard />} />
      </Route> */}
      <Route path={ROUTES.JoinPersonalEvent} element={<OnboardingFlow />}>
        <Route path={ROUTES.Root} element={<PersonalEventLanding />} />
        <Route path={ROUTES.SignUpPE} element={<SignUpPE />} />
        <Route path={ROUTES.SignUpFastPE} element={<SignUpFastPE />} />
        <Route element={<AuthenticatedRoute />}>
          <Route path={ROUTES.ThankYou} element={<ThankYou />} />
          <Route path={ROUTES.SelectInterests} element={<SelectInterests />} />
        </Route>
      </Route>

      <Route path={ROUTES.Join} element={<OnboardingFlow />}>
        <Route path={ROUTES.Root} element={<Landing />} />
        <Route path={ROUTES.SignUp} element={<SignUp />} />
        <Route path={ROUTES.SignUpFast} element={<SignUpFast />} />
        <Route path={ROUTES.LogIn} element={<OF3LogIn />} />

        <Route element={<AuthenticatedRoute />}>
          <Route path={ROUTES.Calendar} element={<IntegrateCalendar />} />
          <Route path={ROUTES.ActivityDays} element={<ActivityDays />} />
          <Route path={ROUTES.Availability} element={<PreferredTimes />} />
          <Route path={ROUTES.InviteFriends} element={<InviteFriends />} />
          <Route path={ROUTES.UserConsent} element={<UserConsent />} />
          <Route path={ROUTES.AppDownload} element={<AppDownload />} />
          <Route path={ROUTES.SelectInterests} element={<SelectInterests />} />
          <Route path={ROUTES.SetLocation} element={<SetLocationAndBio />} />
        </Route>
      </Route>
      <Route element={<AuthenticatedRoute />}>
        <Route path={ROUTES.User} element={<UserStack />}>
          <Route path={ROUTES.Root} element={<Dashboard />} />
          <Route path={ROUTES.DiscoverScreen} element={<DiscoverScreen />} />
        </Route>
      </Route>
      <Route path={ROUTES.Invitation} element={<Invitation />} />
      <Route path={ROUTES.MobileRedirect} element={<FindInvitation />} />
      <Route path={ROUTES.EmailVerified} element={<EmailVerified />} />
      <Route path={ROUTES.EmailUnsubscribe} element={<EmailUnsubscribe />} />

      <Route element={<AuthenticatedRoute />}>
        <Route path={ROUTES.Group} element={<Group />}>
          {/*<Route path={ROUTES.Root} element={<GroupCalendar />} />*/}
          <Route path={ROUTES.Root} element={<GroupDetails />} />
          <Route path={ROUTES.GroupChat} element={<GroupChat />} />
          <Route path={ROUTES.AboutGroup} element={<AboutGroup />} />
          <Route path={ROUTES.PollResultsList} element={<PollResultsList />} />
          <Route path={ROUTES.CreatePoll} element={<CreatePoll />} />
        </Route>
      </Route>
      <Route path={ROUTES.Star} element={<ErrorPage />} />
    </Route>
  )
);

// Add firebase to reducers
const rootReducer = combineReducers({
  firebase: firebaseReducer,
  firestore: firestoreReducer,
  groupChat: groupChat, // <- needed if using firestore
});

// Create store with reducers and initial state
const initialState = {};
const store = createStore(
  rootReducer,
  initialState,
  window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
);

const rrfProps = {
  firebase,
  config: rrfConfig,
  dispatch: store.dispatch,
  logListenerError: true,
  createFirestoreInstance, // <- needed if using firestore
};

const fontTheme = theme;

// initialize cometchat

const App = () => {
  useEffect(() => {
    initializeCometchat();
  }, []);

  const initializeCometchat = () => {
    let appID = process.env.REACT_APP_COMET_CHAT_APP_ID;
    let region = process.env.REACT_APP_COMET_CHAT_REGION;
    let appSetting = new CometChat.AppSettingsBuilder()
      .subscribePresenceForAllUsers()
      .setRegion(region)
      .autoEstablishSocketConnection(true)
      .build();
    CometChat.init(appID, appSetting).then(
      () => {
        console.log("Initialization completed successfully");
      },
      (error) => {
        console.log("Initialization failed with error:", error);
      }
    );
  };

  return <RouterProvider router={router} />;
};

ReactDOM.createRoot(document.getElementById("root")).render(
  <Provider store={store}>
    <ReactReduxFirebaseProvider {...rrfProps}>
      <FirebaseAuthProvider>
        <ThemeProvider theme={fontTheme}>
          <App />
          <ToastContainer />
        </ThemeProvider>
      </FirebaseAuthProvider>
    </ReactReduxFirebaseProvider>
  </Provider>
);
