import SearchIcon from "@mui/icons-material/Search";
import { Box, Grid, TextField } from "@mui/material";
import InputAdornment from "@mui/material/InputAdornment";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useFirestoreConnect } from "react-redux-firebase";
import Activity from "../Activity";

const CATEGORIES_RANK = {
  sports: 0,
  creative: 1,
  "table games": 2,
  food: 3,
  other: 4,
};

const ActivitySelector = ({
  activityLimit,
  selectedActivities,
  setSelectedActivities,
}) => {
  useFirestoreConnect(["activityTypes"]);
  const activities = useSelector(
    (state) => state.firestore.ordered.activityTypes
  );

  const [searchTerm, setSearchTerm] = useState("");
  const [categories, setCategories] = useState([]);
  const [filteredCategories, setFilteredCategories] = useState([]);
  const [searchLabelPadding, setSearchLabelPadding] = useState(0);
  const [width, setWidth] = useState(window.innerWidth);

  const handleWindowSizeChange = () => {
    setWidth(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);
  const isMobile = width <= 768;

  // set the relation between redux activities and local state
  useEffect(() => {
    const categories = [];
    activities?.forEach((activity) => {
      const category = categories.find((el) => el.name === activity.category);
      if (!category) {
        let categoryRank;
        if (activity.category.toLowerCase() in CATEGORIES_RANK) {
          categoryRank = CATEGORIES_RANK[activity.category.toLowerCase()];
        } else {
          categoryRank = Object.keys(CATEGORIES_RANK).length;
        }

        categories.push({
          name: activity.category,
          activities: [activity],
          rank: categoryRank,
        });
      } else {
        category.activities.push(activity);
      }
    });
    categories.sort((a, b) => a.rank - b.rank);
    for (const category of categories) {
      category.activities.sort((a, b) => {
        if (a.name > b.name) {
          return 1;
        } else if (a.name < b.name) {
          return -1;
        } else {
          return 0;
        }
      });
    }
    setCategories(categories);
    setFilteredCategories(categories);
  }, [activities]);

  // update filtered categories according to search term
  const searchActivities = (val) => {
    setSearchTerm(val);
    setFilteredCategories(
      categories.map((category) => ({
        id: category.id,
        name: category.name,
        activities: category.activities.filter((activity) =>
          activity.name
            .toString()
            .toLowerCase()
            .includes(val.toString().toLowerCase())
        ),
      }))
    );
  };

  // selects activity upon press
  const selectActivity = (id) => {
    const activityIndex = selectedActivities.indexOf(id);
    if (
      activityIndex === -1 &&
      (!activityLimit || selectedActivities.length < activityLimit)
    ) {
      setSelectedActivities([...selectedActivities, id]);
    } else if (
      activityIndex === -1 &&
      activityLimit === 1 &&
      selectedActivities.length === 1
    ) {
      setSelectedActivities([id]);
    } else {
      setSelectedActivities(
        selectedActivities.filter((_, index) => index != activityIndex)
      );
    }
  };

  return (
    <Box
      style={{
        width: isMobile ? "120%" : "100%",
        marginBottom: isMobile ? "48px" : "0px",
      }}
    >
      <TextField
        onChange={(e) => {
          searchActivities(e.target.value);
        }}
        label="Search"
        style={{
          color: "black",
          height: 30,
          flex: 1,
          marginLeft: isMobile ? 2 : -2,
          width: isMobile ? "83%" : "100%",
          borderWidth: 0,
        }}
        autoCorrect={false}
        placeholder="Activity"
        placeholderTextColor={"#A6A6B3"}
        value={searchTerm}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon size="small" />
            </InputAdornment>
          ),
        }}
      />
      <Box style={{ justifyContent: "center", alignItems: "center", marginTop: "50px" }}>
        {filteredCategories?.map(
          (category, index) =>
            category.activities.length > 0 && (
              <Box
                key={index}
                style={{
                  display: "flex",
                  flexDirection: "column",
                  marginTop: 14,
                }}
              >
                <p style={{ textAlign: "left" }}>{category.name}</p>
                <Grid container>
                  {category.activities.map((activity) => (
                    <Activity
                      key={activity.id}
                      id={activity.id}
                      name={activity.name}
                      emoji={activity?.webEmoji}
                      selected={selectedActivities.includes(activity.id)}
                      onPress={() => {
                        selectActivity(activity.id);
                      }}
                    />
                  ))}
                </Grid>
              </Box>
            )
        )}
      </Box>
    </Box>
  );
};

export default ActivitySelector;
