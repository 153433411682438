import React from "react";
import placeHolder from "../../../static/img/placeholder.png";

function MembersDisplay({ size, members, limit }) {
  const styles = {
    container: {
      display: "flex",
      flexDirection: "row",
      margin: "0.5rem",
      paddingRight: "1rem",
    },
    pillContainer: {
      border: "1px solid gray",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
  };

  if (!limit)  {
    limit = 6;
  }

  if (!members || members.length < 1) {
    return null;
  }

  return (
    <div style={{ paddingLeft: size / 5 }}>
      <div style={styles.container}>
        {members.length > limit && (
          <div
            style={{
              borderRadius: 50,
              borderColor: "rgba(0,0,0,0.2)",
              borderWidth: 1,
              marginLeft: -(size / 5),
            }}
          >
            <div
              style={{
                alignItems: "center",
                justifyContent: "center",
                width: size,
                height: size,
                borderRadius: 50,
                ...styles.pillContainer,
              }}
            >
              <span style={{ fontSize: size / 3 }}>
                +{members.length - limit}
              </span>
            </div>
          </div>
        )}
        {members.slice(0, limit).map((asset, index) => (
          <div
            key={index}
            style={{
              borderRadius: 50,
              borderColor: "rgba(0,0,0,0.2)",
              borderWidth: 1,
              marginLeft: -(size / 5),
            }}
          >
            {asset && asset.profilePicture ? (
              <img
                src={asset.profilePicture}
                alt="Profile"
                style={{
                  height: size,
                  width: size,
                  borderRadius: 50,
                }}
              />
            ) : asset && asset.firstName && asset.lastName ? (
              <div
                style={{
                  height: size,
                  width: size,
                  borderRadius: 50,
                  ...styles.pillContainer,
                }}
              >
                {`${asset.firstName[0].toUpperCase()}${asset.lastName[0].toUpperCase()}`}
              </div>
            ) : (
              <img
                src={placeHolder}
                alt="Placeholder"
                style={{
                  height: size,
                  width: size,
                  borderRadius: 50,
                }}
              />
            )}
          </div>
        ))}
      </div>
    </div>
  );
}

export default MembersDisplay;
