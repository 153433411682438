import { Box } from "@mui/system";
import React, { useEffect } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import "./index.css";
import { ROUTES } from "../../constants";

export default function UserStack() {
  let { state } = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (state?.admin) {
      navigate(ROUTES.DiscoverScreen);
    }
  }, [state?.admin]);

  const currentPath = useLocation().pathname;
  const isDiscoverScreen = currentPath.includes(ROUTES.DiscoverScreen);

  return isDiscoverScreen ? (
    <Outlet />
  ) : (
    <Box
      alignItems="center"
      justifyContent="center"
      className="backgroundGradient"
    >
      <Box
        className="innerBox"
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "flex-start",
        }}
      >
        <img
          className="logoImg"
          src="https://firebasestorage.googleapis.com/v0/b/joy-prototype.appspot.com/o/zeal-logo-stacked.svg?alt=media&token=d5b70703-8a4d-4626-a75f-63ab7664f6f7"
          alt="zeal logo"
        />
        <Outlet />
      </Box>
    </Box>
  );
}
