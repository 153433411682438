import { Grid } from "@mui/material";
import Box from "@mui/material/Box";
import * as React from "react";
import GroupPrimaryHeader from "../GroupPrimaryHeader";
import "./index.css";

const GradientWrapperWithHeader = ({ children, group, groupId }) => {
  const [showHeader, setShowHeader] = React.useState(false);
  React.useEffect(() => {
    const queryParameters = window.location.pathname.split("/").pop();
    queryParameters == "" ? setShowHeader(false) : setShowHeader(true);
  }, []);

  return (
    <Grid className="noScrollbackgroundGradient">
        {!!showHeader && <GroupPrimaryHeader group={group} groupId={groupId} />}
      <Grid
        item
        xs={12}
        sm={12}
        md={10}
        className="GCinnerBox"
        alignItems="center"
        justifyContent="flex-start"
        display="flex"
        flexDirection="column"
      >
        {children}
      </Grid>
    </Grid>
  );
};

export default GradientWrapperWithHeader;
