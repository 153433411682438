import { CometChat } from "@cometchat-pro/chat";
import { Box, Checkbox, Grid } from "@mui/material";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useFirestore } from "react-redux-firebase";
import { useOutletContext } from "react-router-dom";
import firebase from "../../../services/firebaseConfig";
import GradientButton from "../../GradientButton";
import MembersDisplay from "../MemberDisplay/MemberDisplay";
import styles from "./votepolls.module.css";

const VotePoll = ({
  incomingData,
  memberSnipptById,
  votedMembers,
  voteOptions,
  memberSnippets,
}) => {
  const firestore = useFirestore();
  const { uid } = useSelector((state) => state.firebase.auth);
  const { groupId, group } = useOutletContext();
  const pollMessage = incomingData?.pollObj?.question;
  const options = incomingData?.pollObj?.options;
  const totalVotes = incomingData?.results?.total || 0;
  const [checked, setChecked] = useState([]);
  const [loading, setLoading] = useState(false);

  const handleCheckbox = (id) => {
    if (checked.includes(id)) {
      const updateChecked = checked.filter((item) => {
        return item !== id;
      });
      setChecked(updateChecked);
    } else {
      setChecked([...checked, id]);
    }
  };

  function appendObjects(obj1, obj2) {
    for (let key in obj1) {
      if (obj1.hasOwnProperty(key)) {
        if (key in obj2) {
          obj2[key].push(...obj1[key]);
        } else {
          obj2[key] = obj1[key];
        }
      }
    }
    return obj2;
  }

  const submitVote = async () => {
    setLoading(true);
    let submitPollOptions;
    const pollObj = incomingData?.pollObj;
    const formatPollOptions = {};
    checked.forEach((optionId) => {
      formatPollOptions[optionId] = [uid.toLowerCase()];
    });

    await firestore.runTransaction(async (transaction) => {
      await transaction
        .get(
          firestore
            .collection("conversations")
            .doc(groupId)
            .collection("polls")
            .doc(pollObj?.id)
        )
        .then((data) => {
          const docData = data?.data();
          const arr = docData?.pollOptions;
          if (docData?.pollOptions) {
            submitPollOptions = appendObjects(formatPollOptions, arr);
          } else {
            // To account for polls made prior to multi-option poll launch, if there
            // is no poll options document, also consider the Comet Chat poll object's votes.
            // This considers the previous votes made on CometChat single poll.
            const resultOptions = pollObj?.results?.options;
            const initialOptions = Object?.fromEntries(
              Object?.keys(resultOptions).map((option) => [
                option,
                Object?.keys(resultOptions[option].voters || []),
              ])
            );

            submitPollOptions = appendObjects(
              initialOptions,
              formatPollOptions
            );
          }
          transaction.set(
            firestore
              .collection("conversations")
              .doc(groupId)
              .collection("polls")
              .doc(pollObj?.id),
            {
              title: pollObj?.question,
              pollOptions: submitPollOptions,
            },
            { merge: true }
          );
          CometChat.callExtension("polls", "POST", "v2/vote", {
            vote: 1,
            id: pollObj?.id,
          })
            .then((response) => {
              callFirebaseFunction(
                groupId,
                "group_" + group.cometChatGroupId,
                group.name,
                response["id"],
                Object.keys(memberSnippets),
                [...new Set(votedMembers)],
                uid
              );
            })
            .catch((err) => {})
            .finally(() => setLoading(false));
        })
        .catch((err) => console.log("Error while getting note data ", err));
    });
  };

  const callFirebaseFunction = async (
    receiverID,
    conversationID,
    groupName,
    pollId,
    groupMembers,
    votedMembers,
    uid
  ) => {
    const data = {
      groupId: receiverID,
      conversationID: conversationID,
      groupName: groupName,
      messageId: pollId,
      groupMembers: groupMembers,
      votedMembers: votedMembers,
      uid: uid,
    };
    try {
      const onUserVoteOnPoll = firebase
        .functions()
        .httpsCallable("onUserVoteOnPoll");
      onUserVoteOnPoll(data);
      console.log("Cloud function result:");
    } catch (error) {
      console.error("Error calling Cloud Function:", error);
    }
  };

  return (
    <Grid container>
      <Grid container className={styles.poll_votes}>
        Poll - {totalVotes} votes
      </Grid>
      <Grid container className={styles.pollMessage}>
        {pollMessage}
      </Grid>
      {Object.values(options).map((item, index) => {
        return (
          <Grid container key={index} alignItems="center">
            <Grid item xs="auto">
              <Checkbox
                onClick={() => {
                  handleCheckbox(index + 1);
                }}
                size="small"
                style={{ color: "#6206BF" }}
                className={styles.checkBox}
              />
            </Grid>
            <Grid item xs container alignItems="center">
              {item}
            </Grid>
            <Grid container style={{ marginLeft: 40 }}>
              <MembersDisplay
                size={32}
                limit={9}
                members={voteOptions[index]?.userIds.map((el) => {
                  return {
                    profilePicture: memberSnipptById[el]?.profilePicture,
                    firstName: memberSnipptById[el]?.firstName,
                    lastName: memberSnipptById[el]?.lastName,
                  };
                })}
              />
            </Grid>
          </Grid>
        );
      })}
      <Grid
        container
        flexDirection="column"
        style={{ marginLeft: 40, height: "100px" }}
      >
        <span className={styles.poll_Option_Message}>Pending</span>
        <Box>
          <MembersDisplay
            size={32}
            limit={9}
            members={Object.keys(memberSnipptById)
              .filter((key) => !votedMembers.includes(key))
              .map((el) => ({
                profilePicture: memberSnipptById[el]?.profilePicture,
                firstName: memberSnipptById[el]?.firstName,
                lastName: memberSnipptById[el]?.lastName,
              }))}
          />
        </Box>
      </Grid>
      <Grid item xs={12} style={{ marginBottom: "10px" }}>
        <GradientButton
          disabled={loading}
          onClick={() => {
            submitVote();
          }}
          maxWidth="300px"
        >
          Vote
        </GradientButton>
      </Grid>
    </Grid>
  );
};

export default React.memo(VotePoll);
