import { Grid, Paper } from "@mui/material";
import React from "react";
import "./index.css";

const VideoMessage = ({ message }) => {
  return (
    <Grid style={{ padding: "8px 0px" }}>
      <Grid container style={{ margin: "5px 0px", wordBreak: "break-all" }}>
        {message?.message}
      </Grid>
      <Grid container spacing={2}>
        {message?.originalMsg?.data?.attachments?.map((item, index) => (
          <Grid item key={index} xs={12} sm={6} md={4}>
            <Paper elevation={3} className="video-paper-container">
              <video
                controls
                controlsList="nodownload noplaybackrate"
                disablePictureInPicture
                className="video-msg"
                preload="metadata"
              >
                <source src={item.url + `#t=0.1`} type="video/mp4" />
              </video>
            </Paper>
          </Grid>
        ))}
      </Grid>
    </Grid>
  );
};

export default VideoMessage;
