import { Button } from "@mui/material";
import React from "react";
import { useNavigate, useOutletContext } from "react-router-dom";
import GradientButton from "../../components/GradientButton";

import FormCard from "../../components/FormCard";
import { ROUTES } from "../../constants";
import "./index.css";
import { isAndroid } from "react-device-detect";

export default function AppDownload() {
  const { group, rsvpStatus, isFastTrack, isPersonalEvent } =
    useOutletContext();

  const navigate = useNavigate();

  const handleContinue = () => {
    navigate("/user");
  };

  const handleRedirect = () => {
    navigate("/user");
  };

  return (
    <FormCard center bottom>
      {isFastTrack || isPersonalEvent ? (
        rsvpStatus == "declined" ? (
          <h1>Download the App for future events</h1>
        ) : (
          <h1>Download the App to see who's going</h1>
        )
      ) : (
        <h1>
          Don’t miss out! <br></br> Get the Zeal app today!
        </h1>
      )}
      <h3>
        {isFastTrack || isPersonalEvent ? (
          rsvpStatus == "declined" ? (
            <>
              We've let the organizer know that you've <strong>DECLINED</strong>
              .
            </>
          ) : rsvpStatus == "attending" ? (
            <>
              We've let the organizer know that you are{" "}
              <strong>CONFIRMED</strong>!
            </>
          ) : (
            <>
              We've let the organizer know that you are a <strong>Maybe</strong>
              . Please RSVP soon!
            </>
          )
        ) : (
          "See who's going to events, share photos, send polls, organize activities and more!"
        )}
        <br /> <br />
        Download Zeal from the App Store now. Android coming soon.
      </h3>
      <a
        style={{ width: "100%" }}
        href={isAndroid ? ROUTES.PlayStore : ROUTES.AppStore}
        className="downloadApp"
      >
        <GradientButton maxWidth="100%">Download App</GradientButton>
      </a>

      <Button
        className="link"
        onClick={() => {
          handleContinue();
        }}
        style={{
          color: "rgba(98, 6, 191, 1)",
          fontSize: "14px",
        }}
      >
        Continue via the web
      </Button>

      <div
        style={{
          height: "400px",
          marginTop: 20,
          bottom: "33px",
          position: "relative",
        }}
      >
        <img
          className="image"
          src={require("../../static/img/wide-mockup.png")}
          alt="zeal ios mockup"
        />
      </div>
    </FormCard>
  );
}
