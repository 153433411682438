import React from 'react';
import { Box } from '@mui/material';
import { getFormattedTimeFromDate } from '../../utils/dateManipulation';
import Icon from '../Icon';
import { Card, CardContent, CardActionArea } from '@mui/material';

const SelectedTime = ({ dayName, startEndTimes, onPress }) => {
    
    const formatTime = (startTime, endTime) => {
        let formattedStartTime;
        let formattedEndTime;
        if(startTime._isAMomentObject && endTime._isAMomentObject) {
            formattedStartTime = startTime.format('hh:mm A');
            formattedEndTime = endTime.format('hh:mm A');
        }
        else {
            formattedStartTime = getFormattedTimeFromDate(startTime);
            formattedEndTime = getFormattedTimeFromDate(endTime)
        }
        return `${formattedStartTime} - ${formattedEndTime}`
    }
    

	return (
        <Card sx={{ flexGrow: 1, width: '100%', boxShadow:'none' }}>
            <CardActionArea>
                <CardContent onClick={onPress} style={{ width: '100%', padding:0}}>
                    <Box style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between', margin: '0px', padding: '25px 18px' }}>
                        <span style={{ fontSize: '18px', fontFamily: 'Causten Regular', margin:0, lineHeight: '1.0', fontWeight: 600}}>{dayName}</span>
                        <div style={{display: 'flex', alignItems: 'center'}}>
                            <div style={{display: 'flex', flexDirection: 'column'}}>
                            {startEndTimes.map(({startTime, endTime}) => (
                                <span style={{fontFamily: 'Causten Regular', fontSize: '18px'}}>{formatTime(startTime, endTime)}</span>
                            ))}
                            </div>
                            <Icon maxHeight='12px' src='https://firebasestorage.googleapis.com/v0/b/joy-prototype.appspot.com/o/small-arrow.png?alt=media&token=105ed599-2934-4a88-b1ba-d672e628888f' />
                        </div>
                    </Box>
                    
                </CardContent>
            </CardActionArea>
        </Card>
	);
};

export default SelectedTime;
