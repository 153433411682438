import React from 'react';
import {Box, Paper, Container } from '@mui/material';
import GradientButton from '../../components/GradientButton';

export default function InvitationNotFound() {
    return (
      <Paper sx={{width: '100%', backgroundColor: 'inherit', borderWidth: 0, justifyContent: 'center', display: 'flex', alignItems: 'center', flexDirection: 'column'}} square variant='outlined'>
        <img className='groupThumbnail mobileOnly' src='https://firebasestorage.googleapis.com/v0/b/joy-prototype.appspot.com/o/zeal_banner.svg?alt=media&token=9a574c6d-da7d-4557-958a-ddf83d38afca' alt='Zeal header'/>
        <Container maxWidth='lg'>
          <Box
            sx={{
              flexGrow: 1,
              justifyContent: 'center',
              display: 'flex',
              alignItems: 'center',
              mb: 2,
              flexDirection: 'column',
              textAlign: 'center'
            }}
          >
            <h1>Your invitation has expired or can't be found.</h1>
            
            <h3>Please reach out to the group organizer or download the Zeal app to create your own group!</h3>
            <p>Or, visit our <a href='http://getzeal.co'>landing page</a>.</p>
            <br />
            <GradientButton style={{width: '100%', flex: 1, margin: 5}} variant="outlined" onClick={() => console.log('DOWNLOAD THE APP!')}>Download the app</GradientButton>
          </Box>
        </Container>
      </Paper>
    );
};