import { Alert, Collapse, Grid } from "@mui/material";
import React from "react";
import fileIcon from "../../../static/img/groupDashboard/files/file-icon.svg";
import rightArrow from "../../../static/img/groupDashboard/files/right-arrow.svg";
import styles from "./fileComponent.module.css";

const FileComponent = ({ incomingData }) => {
  const senderName =
    incomingData?.senderInfo?.name === "System"
      ? "Zeal"
      : incomingData?.senderInfo?.name;
  return (
    <>
      <Grid container className={styles.files_wrapper}>
        {incomingData?.files.map((item, index) => {
          return (
            <Grid
              key={index}
              container
              item
              xs={12}
              className={styles.file_container}
            >
              <Grid item xs="auto">
                <img src={fileIcon} alt="file-icon" />
              </Grid>
              <Grid item xs className={styles.file_name}>
                {item.name}
              </Grid>
              <Grid item xs={1}>
                <img src={rightArrow} alt="file-icon" />
              </Grid>
            </Grid>
          );
        })}
      </Grid>
      <Collapse in={true}>
        <Alert
          icon={false}
          severity="error"
          style={{
            width: "fit-content",
            backgroundColor: "#F4F4F8",
            color: "#000000",
            marginBottom: "10px",
          }}
        >
          {senderName} posted a file. Download the Zeal app to view and upload
          files.
        </Alert>
      </Collapse>
    </>
  );
};

export default FileComponent;
