import { Grid, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import GradientButton from "../../components/GradientButton";
import GradientWrapper from "../../components/GradientWrapper";
import "./index.module.css";
import { ROUTES } from "../../constants";
import { useFirestore } from "react-redux-firebase";
import firebase from "../../services/firebaseConfig";

const EmailUnsubscribe = () => {
  const [emailUnsubscribed, setEmailUnsubscribed] = useState(false);
  const [userDetails, setUserDetails] = useState(null);
  const location = useLocation();
  const firestore = useFirestore();
  const urlParams = new URLSearchParams(location.search);
  const uid = urlParams.get("uid");

  useEffect(() => {
    fetchUserDetails();
  }, [uid]);

  const fetchUserDetails = async () => {
    const userDetails = (
      await firestore.collection("users").doc(uid).get()
    ).data();
    setUserDetails(userDetails);
  };

  const unsubscribeEmail = async () => {
    const data = {
      userId: uid,
    };

    const emailUnsubscribe = firebase
      .functions()
      .httpsCallable("onEmailUnsubscribe");

    emailUnsubscribe(data)
      .then((res) => {
        console.log("Email notifications successfully turned off ", res);
        setEmailUnsubscribed(true);
      })
      .catch((err) =>
        console.log("Error while turning off email notifications ", err)
      );
  };

  return (
    <GradientWrapper noScroll>
      <Grid
        container
        alignItems="center"
        flexDirection="column"
        className="innerWhiteBox"
      >
        <Grid item xs={8} container flexDirection="column" alignItems="center">
          {userDetails ? (
            <>
              <img
                className="logoImg"
                src="https://firebasestorage.googleapis.com/v0/b/joy-prototype.appspot.com/o/zeal-logo-stacked.svg?alt=media&token=d5b70703-8a4d-4626-a75f-63ab7664f6f7"
                alt="zeal logo"
              />
              <Typography
                className="heading"
                sx={[{ marginTop: "40px", marginBottom: "24px" }]}
                variant="h2"
              >
                {!emailUnsubscribed
                  ? `Update your Notifications Settings.`
                  : `Your email notifications have been turned off.`}
              </Typography>
              <Typography className="message" sx={[{ marginBottom: "24px" }]}>
                {!emailUnsubscribed ? (
                  <>
                    Visit Settings &gt; Notifications in the{" "}
                    <a className="link" href={ROUTES.AppStore} target="_blank">
                      Zeal app
                    </a>
                  </>
                ) : (
                  <>
                    Zeal will no longer send you email updates about your group
                    or groups activity. Zeal will still continue to send you
                    calendar events to: {userDetails?.email}.
                  </>
                )}
              </Typography>
              <Typography className="message" sx={[{ marginBottom: "24px" }]}>
                {!emailUnsubscribed
                  ? `If you turn off email notifications, Zeal will no longer be able
                to keep you updated on your group or groups activity. Zeal will
                still continue to send you calendar events to: ${userDetails?.email}.`
                  : `Visit Settings > Notifications on the Zeal app to restore email notifications.`}
              </Typography>
              {!emailUnsubscribed ? (
                <Typography
                  className="link"
                  sx={[{ marginBottom: "24px" }]}
                  onClick={unsubscribeEmail}
                >
                  Turn off email notifications now.
                </Typography>
              ) : (
                <GradientButton maxWidth="343px">
                  <a className="button" href={ROUTES.AppStore} target="_blank">
                    Continue to Zeal
                  </a>
                </GradientButton>
              )}
            </>
          ) : (
            <h1>Loading...</h1>
          )}
        </Grid>
      </Grid>
    </GradientWrapper>
  );
};

export default EmailUnsubscribe;
