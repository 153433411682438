import React from "react";
import styles from "./linkComponent.module.css";
import { Box, Grid } from "@mui/material";

const LinkComponent = ({ incomingData }) => {
  const title = incomingData?.snnipetObj?.title || "No Title Found";
  const description =
    incomingData?.snnipetObj?.description || "No Description Found";
  const image = incomingData?.snnipetObj?.image;

  // To be placed to a helper function file in future.
  function removeURLsFromString(str) {
    const urlRegex = /(?:https?|ftp):\/\/[\S]+/gi;
    return str.replace(urlRegex, "");
  }
  return (
    <Grid container>
      <Grid container>{removeURLsFromString(incomingData?.message)}</Grid>
      <Grid container className={styles.link}>
        <a href={incomingData?.snnipetObj?.url} target="_blank">
          {incomingData?.snnipetObj?.url}
        </a>
      </Grid>
      <a href={incomingData?.snnipetObj?.url} target="_blank">
        <Grid
          item
          xs={10}
          container
          className={styles.linkThumbnailContainer}
          columnSpacing={1}
        >
          <Grid container alignItems="center" item xs="auto">
            <img
              style={{ height: "82px", width: "100px" }}
              src={image}
              alt="group-logo"
            />
          </Grid>
          <Grid item xs className={styles.linkMetaContainer}>
            <Box className={styles.linkName}>{title}</Box>
            <Box className={styles.linkDescription}>{description}</Box>
          </Grid>
        </Grid>
      </a>
    </Grid>
  );
};

export default LinkComponent;
