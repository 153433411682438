import React, { useState, useEffect } from 'react';
import { Box, Checkbox, IconButton } from '@mui/material';
import { useOutletContext } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import GradientButton from '../../components/GradientButton';
import FormCard from '../../components/FormCard';
import { useFirestore } from 'react-redux-firebase';
import { useFirebaseAuth } from '../../utils/contexts/FirebaseAuthContext.js';
import { COLORS, ROUTES } from '../../constants';
import OutlineButton from '../../components/OutlinedButton';
import { Modal } from '@mui/material';

export default function UserConsent() {

  const { group, continueCallback, isFastTrack } = useOutletContext();

  const navigate = useNavigate();

  const firestore = useFirestore();

  const [hasConsentedToTexts, setHasConsentedToTexts] = useState(false);
  const [confirmModal, setConfirmModal] = useState(false);

  const { user } = useFirebaseAuth();


  const handleContinue = () => {
    firestore.collection('users').doc(user.uid).set({ notificationPreferences: { allowSmsAlerts: hasConsentedToTexts } }, { merge: true});
    continueCallback(ROUTES.SelectInterests)
  }

    return (
      <FormCard>
        {isFastTrack ? (
          <h1>Allow Text Updates</h1>
        ):(
          <h1>Always stay in the know!</h1>
        )}
        <h4>Let Zeal send you texts about schedule changes, event reminders and other important info so you never miss a thing.</h4>
        <Box sx={{display: 'flex', flexDirection: 'row', textAlign: 'left', paddingVertical: 7, marginBottom: 2, alignItems: 'flex-start', justifyContent: 'center'}}>
          <Checkbox style={{color: COLORS.PURPLE}} checked={hasConsentedToTexts} onChange={() => {setHasConsentedToTexts(prevCheck => !prevCheck)}} inputProps={{ 'aria-label': 'controlled' }} sx={{padding: 0, marginRight: 1}} />
          <p style={{margin:'0px',}}>By checking this box, I consent by electronic signature to receive text messages from Zeal sent by any means, technology or automated system for any purposes, including administrative, informational, promotional, marketing or sales purposes, at the phone number I have provided. I also understand that my consent is not required to purchase products from or use Zeal’s services. You can opt-out at any time in Settings or by texting STOP. Msg & Data rates may apply.</p>
        </Box>
        <Box style={{width: "100%", display: "flex", flexDirection:"column", alignItems:"center"}}>
          <GradientButton maxWidth="343px" disabled={!hasConsentedToTexts} onClick={() => {
            if(hasConsentedToTexts) { handleContinue() }}}>Yes, send me texts</GradientButton>
            <OutlineButton maxWidth="343px" style={{ marginLeft: "0px"}} onClick={() => {
            setConfirmModal(true) }}>Continue without texts</OutlineButton>
        </Box>
        <Modal
        open={confirmModal}
        onClose={() => setConfirmModal(false)}
        aria-labelledby='modal-modal-title'
        aria-describedby='modal-modal-description'
      >
        <Box sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: 400,
          bgcolor: 'background.paper',
          borderRadius: '15px',
          boxShadow: 24,
          padding: '40px',
          display: 'flex',
          justifyContent: 'center',
          flexDirection: 'column',
          alignItems: 'center',
          maxHeight: '60vh',
        }}>

          <h1>Are you sure?</h1>
          <p style={{textAlign: 'center'}}>
            Refusing texts means{" "}
            <strong>you won't get notified{" "}</strong>
            of any event updates, polls, or group chat messages!
          </p>
          <GradientButton 
            maxWidth="343px" 
            style={{marginTop: '24px'}}
            onClick={() => {
            setHasConsentedToTexts(false);
            handleContinue()
          }}>
              I understand, continue without texts
          </GradientButton>
          <button
            type="button"
            onClick={() => setConfirmModal(false)}
            style={{
              marginTop: '25px',
              background: "none",
              color: COLORS.PURPLE,
              border: "none",
              padding: "0",
              font: "inherit",
              cursor: "pointer",
              outline: "inherit",
            }}
          >
            Go Back
          </button>
        </Box>
      </Modal>
    </FormCard>
    );
};