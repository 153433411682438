import moment from "moment";



export function extractUrlsFromString(str) {
  const urlRegex = /(https?:\/\/[^\s]+)/g;
  const urls = str.match(urlRegex);
  return urls || [];
}
export function extractDomain(url) {
  let domain;
  // Find & remove protocol (http, ftp, etc.) and get the domain
  if (url.indexOf("://") > -1) {
    domain = url.split("/")[2];
  } else {
    domain = url.split("/")[0];
  }

  // Find & remove port number
  domain = domain.split(":")[0];

  return domain;
}

export const getCurrentTimezone = () => {
  const now = new Date();
  const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  return timezone;
};

export const timeZones = [
  { label: "Eastern Time (ET)", value: "America/New_York", short: "ET" },
  { label: "Pacific Time (PT)", value: "America/Los_Angeles", short: "PT" },
  { label: "Central Time (CT)", value: "America/Chicago", short: "CT" },
  { label: "Mountain Time (MT)", value: "America/Denver", short: "MT" },
  {
    label: "Hawaii-Aleutian Time (HT)",
    value: "Pacific/Honolulu",
    short: "HT",
  },
  { label: "Alaska Time (AKT)", value: "America/Anchorage", short: "AKT" },
];


export const formatEventDateTime = (dateTime) => {
  if (!dateTime) return "";

  // Parse the input string with the correct format
  const parsedDateTime = moment(dateTime, "M/D/YYYY, h:mm:ss A");

  // Format the parsed datetime in the desired format
  const formattedDateTime = parsedDateTime.format("MMM DD, hh:mm A");

  return formattedDateTime;
};

export function removeLinksFromString(inputString) {
  // Regular expression to match URLs
  const urlRegex = /https?:\/\/[^\s/$.?#].[^\s]*/g;

  // Replace all occurrences of URLs with an empty string
  const stringWithoutLinks = inputString.replace(urlRegex, "");

  return stringWithoutLinks;
}

export const getDateTime = (timeStamp) => {
  if (!timeStamp) return;
  const date = moment.unix(timeStamp.seconds);
  return {
    time: date.format("LT"),
    date: date.format("D"),
    month: date.format("MMM"),
  };
};