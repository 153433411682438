import { Box } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useFirestore } from "react-redux-firebase";
import { useOutletContext } from "react-router-dom";
import ActivitySelector from "../../components/ActivitySelector";
import FormCard from "../../components/FormCard";
import GradientButton from "../../components/GradientButton";
import ZealInput from "../../components/ZealInput/index.jsx";
import { ROUTES } from "../../constants";
import { useFirebaseAuth } from "../../utils/contexts/FirebaseAuthContext.js";
import { useFirestoreConnect } from "react-redux-firebase";
import { useSelector } from "react-redux";
import { Zeal_Admin_ID, createAmplitudeEvent } from "../../utils/amplitude.js";

export default function SelectInterests() {
  const {
    group,
    continueCallback,
    isFastTrack,
    setHasSkip,
    isPersonalEvent,
    invitation,
    calendarId,
    eventId,
    setNextPageCallback,
  } = useOutletContext();

  const zipRegEx = /^[0-9]{5}$/;

  const firestore = useFirestore();

  const { user } = useFirebaseAuth();

  useFirestoreConnect([
    {
      collection: "users",
      doc: user.uid,
      storeAs: "userInfo",
    },
  ]);

  const userInfo = useSelector((state) => state.firestore.data.userInfo);

  const [selectedActivities, setSelectedActivities] = useState(
    userInfo?.interests || []
  );

  useEffect(() => {
    if (userInfo?.interests) {
      setSelectedActivities(userInfo.interests);
    }
  }, [JSON.stringify(userInfo?.interests)]);

  useEffect(() => {
    if (user.uid) {
      createAmplitudeEvent(user.uid, "Web Event", {
        screenName: "select_interests",
      });
    }
  }, [user.uid]);

  const [zips, setZips] = useState(
    userInfo?.locations && userInfo?.locations.length > 0
      ? userInfo?.locations
      : [""]
  );

  useEffect(() => {
    const getDefaultValues = async () => {
      const eventDoc = await firestore
        .collection("zealCalendars")
        .doc(calendarId)
        .collection("calendarEvents")
        .doc(eventId)
        .get();
      if (!eventDoc.exists) return;

      const eventActivityId = eventDoc.data().activityId;
      if (
        eventActivityId &&
        (!userInfo?.interests || userInfo?.interests.length === 0)
      ) {
        setSelectedActivities([eventActivityId]);
      }

      const creatorId = eventDoc.data().creatorId;
      if (
        creatorId &&
        (!userInfo?.locations || userInfo?.locations.length === 0)
      ) {
        const creatorDoc = await firestore
          .collection("users")
          .doc(creatorId)
          .get();
        if (
          creatorDoc.exists &&
          creatorDoc.data().locations &&
          creatorDoc.data().locations.length > 0
        ) {
          const { locations } = creatorDoc.data();
          const locationToAdd = locations[0];
          setZips([locationToAdd]);
        }
      }
    };
    if (
      isPersonalEvent &&
      eventId &&
      calendarId &&
      (userInfo?.locations?.length === 0 || userInfo?.interests?.length)
    ) {
      getDefaultValues();
    }
  }, [
    isPersonalEvent,
    eventId,
    calendarId,
    userInfo?.locations?.length,
    userInfo?.interests?.length,
  ]);

  const handleContinue = () => {
    if (calendarId) {
      continueCallback(
        ROUTES.JoinPersonalEvent,
        {},
        `/join-event?c=${calendarId}&${eventId ? `e=${eventId}` : ""}`
      );
    } else {
      continueCallback(ROUTES.SetLocation);
    }
  };

  // sets activity preferences in Firestore
  const setActivityPreferences = async () => {
    await setLocations();
    const updatedActivities =
      selectedActivities.includes(group?.activityId) || !group?.activityId
        ? selectedActivities
        : [...selectedActivities, group?.activityId];
    await firestore
      .collection("users")
      .doc(user.uid)
      .update({ interests: updatedActivities });

    console.log("Activity preferences successfully added to Firestore");
    handleContinue();
  };

  useEffect(() => {
    if (isPersonalEvent) {
      setNextPageCallback("");
      setHasSkip(true);
    } else {
      setHasSkip(false);
    }
  }, [isPersonalEvent]);

  useEffect(() => {
    // If a user leaves this screen without locations or interests, prefill based on the event
    const prefillUserInterestAndLocation = async () => {
      const userDoc = await firestore.collection("users").doc(user.uid).get();
      if (!userDoc.exists) return;

      const { interests, locations } = userDoc.data();
      const updateData = {};

      const eventDoc = await firestore
        .collection("zealCalendars")
        .doc(calendarId)
        .collection("calendarEvents")
        .doc(eventId)
        .get();
      if (!eventDoc.exists) return;

      if (!interests || interests.length === 0) {
        const eventActivityId = eventDoc.data().activityId;
        if (eventActivityId) {
          updateData["interests"] = [eventActivityId];
        }
      }

      if (!locations || locations.length === 0) {
        const creatorId = eventDoc.data().creatorId;
        if (creatorId) {
          const creatorDoc = await firestore
            .collection("users")
            .doc(creatorId)
            .get();
          if (
            creatorDoc.exists &&
            creatorDoc.data().locations &&
            creatorDoc.data().locations.length > 0
          ) {
            const { locations } = creatorDoc.data();
            const locationToAdd = locations[0];
            updateData["locations"] = [locationToAdd];
          }
        }
      }

      if (Object.keys(updateData).length > 0) {
        await firestore
          .collection("users")
          .doc(user.uid)
          .set(updateData, { merge: true });
      }
    };
    return () => {
      if (user?.uid && isPersonalEvent && eventId && calendarId) {
        prefillUserInterestAndLocation();
      }
    };
  }, [user?.uid, isPersonalEvent, eventId, calendarId]);

  let dateString = "";
  let timeString = "";
  if (invitation && invitation.personalEventDetails) {
    const eventDate = new Date(
      invitation.personalEventDetails.startDateTime.seconds * 1000 +
        invitation.personalEventDetails.startDateTime.nanoseconds / 1000000
    );

    dateString = eventDate.toLocaleDateString("en-us", {
      weekday: "long",
      year: "numeric",
      month: "short",
      day: "numeric",
    });

    const endTS = new Date(
      eventDate.getTime() +
        invitation.personalEventDetails.duration * 60 * 60 * 1000
    );
    timeString = `${eventDate.toLocaleString("en-US", {
      hour: "numeric",
      minute: "numeric",
      hour12: true,
    })} - ${endTS.toLocaleString("en-US", {
      hour: "numeric",
      minute: "numeric",
      hour12: true,
    })}`;
  }

  const setZipsValue = (zips) => {
    if (zips.length <= 0) return;
    if (zips[0].length > 5) return;
    setZips(zips);
  };

  const setLocations = async () => {
    // filter out empty zipcodes
    const filteredZips = zips.filter((el) => el.length > 0);
    await firestore
      .collection("users")
      .doc(user.uid)
      .update({ locations: filteredZips })
      .then(() => {
        console.log("Locations successfully added to Firestore");
      });
  };

  return (
    <FormCard>
      {isPersonalEvent && invitation && (
        <>
          <h2 style={{ fontWeight: 700, fontSize: "24px", marginBottom: 0 }}>
            You're going to
          </h2>
          <h2 style={{ fontWeight: 700, fontSize: "24px", marginTop: 0 }}>
            {invitation.personalEventDetails.title
              ? invitation.personalEventDetails.title
              : "Main Activity"}
          </h2>
          <h2 style={{ fontWeight: 700, fontSize: "18px", margin: 0 }}>
            {dateString}
          </h2>
          <h2 style={{ fontWeight: 700, fontSize: "18px", margin: 0 }}>
            {timeString}
          </h2>
          <br></br>
          <h3 style={{ fontWeight: 600, fontSize: "24px" }}>
            {invitation.personalEventDetails.location}
          </h3>

          <h4 style={{ fontWeight: 600, fontSize: "16px", margin: 0 }}>
            Your zip code to help {invitation.from.split(" ")[0]} schedule more
            events
          </h4>

          {zips.map((item, index) => (
            <ZealInput
              value={item}
              label="Zip Code"
              onChange={(e) => {
                const nextZips = zips.map((el, i) => {
                  if (i === index) {
                    return e.target.value;
                  }
                  return el;
                });
                setZipsValue(nextZips);
              }}
              style={{ width: "100%", margin: "16px 0px 0px 0px" }}
              placeholder="Add your zip code"
              key={index}
            />
          ))}
        </>
      )}

      <h1 style={{ fontWeight: 600, fontSize: "16px" }}>
        Share your interests
      </h1>

      <h4 style={{ textAlign: "left" }}>
        Find out which friends share your interests and connect with others who
        also share your interests
        <br />
        <br />
      </h4>

      <ActivitySelector
        activityLimit={null}
        selectedActivities={selectedActivities}
        setSelectedActivities={setSelectedActivities}
      />
      <Box
        style={{
          width: "100%",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          marginTop: "24px",
          position: "fixed",
          left: 0,
          bottom: 36,
          right: 0,
        }}
      >
        <GradientButton
          maxWidth="343px"
          disabled={
            selectedActivities.length === 0 ||
            (isPersonalEvent &&
              invitation &&
              zips.filter((z) => zipRegEx.test(z)).length === 0)
          }
          onClick={() => {
            setActivityPreferences();
          }}
        >
          Continue
        </GradientButton>
      </Box>
    </FormCard>
  );
}
