import { Box, Grid } from "@mui/material";
import React from "react";
import styles from "./reactionsList.module.css";

export const ReactionsList = ({ anchor, toggleDrawer, bottomSheeet }) => {
  return (
    <Box
      sx={{
        width: anchor === "top" || anchor === "bottom" ? "auto" : 250,
      }}
      role="presentation"
      onClick={() => {
        toggleDrawer(false);
      }}
      onKeyDown={() => {
        toggleDrawer(false);
      }}
    >
      <Grid container justifyContent="center">
        <Grid container style={{ padding: "1rem" }}>
          <Grid container justifyContent="center">
            <Grid item xs={2} className={styles.drawerhandle}></Grid>
          </Grid>
          <Grid item xs textAlign="center" className={styles.heading}>
            <span style={{ paddingLeft: 30 }}>Reactions</span>
          </Grid>
          <Grid
            item
            xs="auto"
            alignSelf="flex-end"
            className={styles.cancelButton}
            onClick={() => {
              toggleDrawer(false);
            }}
          >
            Cancel
          </Grid>
        </Grid>
        <Grid container style={{ padding: "1rem 0px" }}>
          {Object.entries(bottomSheeet.reactions).map(
            ([emoji, reactionData]) => (
              <Grid item xs={12} key={emoji}>
                <Grid
                  item
                  xs={12}
                  style={{ backgroundColor: "#EDEDEE", padding: "2px 0px 2px 10px" }}
                >
                  {emoji}
                </Grid>
                {Object.entries(reactionData)?.map(([key, user]) => (
                  <Grid
                    container
                    key={key}
                    alignItems="center"
                    style={{ paddingLeft: 10 }}
                  >
                    <Grid item xs="auto">
                      <img
                        src={user?.avatar}
                        alt={user?.name}
                        style={{
                          height: 40,
                          width: 40,
                          borderRadius: 50,
                          marginRight: 20,
                        }}
                      />
                    </Grid>
                    <Grid item xs>
                      <p>{user.name}</p>
                    </Grid>
                  </Grid>
                ))}
              </Grid>
            )
          )}
        </Grid>
      </Grid>
    </Box>
  );
};

export default ReactionsList;