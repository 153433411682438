import { Button, Grid, Menu, MenuItem } from "@mui/material";
import React from "react";
import styles from "./style.module.css";
import defaultAvatar from "../../static/img/groupDashboard/avatar.png";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import { useEffect } from "react";
import {
  extractDomain,
  formatEventDateTime,
  getDateTime,
  removeLinksFromString,
} from "../../utils/commonUtils";
import { useState } from "react";
import { useFirestore } from "react-redux-firebase";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import images from "../../assets/images";

const FeedCard = ({ item, setEditPostData, sidebarActivePostType }) => {
  const navigate = useNavigate();
  const postId = item?.postId;
  const firestore = useFirestore();

  const eventLocation = item?.metadata?.event?.location;
  const startDate = item?.metadata?.event?.dateTimeString;
  const endDate = item?.metadata?.event?.endDateTimeString;
  const createdAt = getDateTime(item?.createdAt);

  const handleEdit = () => {
    setEditPostData(item);
    handleClose();
  };

  const handleDelete = async () => {
    // Reference to the post document
    const postRef = firestore
      .collection(
        `${sidebarActivePostType === "preview" ? "previewPosts" : "Post"}`
      )
      .doc(postId);

    try {
      // Delete the post document
      await postRef.delete();
      console.log(`Post with postId ${postId} deleted successfully.`);
      toast(`Post deleted successfully.`, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        icon: ({ theme, type }) => <img src={images.zealProfile} />,
        // transition: Bounce,
      });
    } catch (error) {
      toast.error(`Error deleting post`, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        // transition: Bounce,
      });
    } finally {
      handleClose();
    }
  };

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [linkPreview, setLinkPreview] = useState({
    title: "",
    linkUrl: "",
    thumbnailUrl: "",
  });

  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    if (!!item?.metadata?.link?.linkUrl) {
      setLinkPreview({
        title: item?.metadata?.link?.title,
        linkUrl: item?.metadata?.link?.linkUrl,
        thumbnailUrl: item?.metadata?.link?.thumbnailUrl,
      });
    }
    if (item?.metadata?.event?.linkUrl) {
      setLinkPreview({
        title: item?.metadata?.event?.title,
        linkUrl: item?.metadata?.event?.linkUrl,
        thumbnailUrl: item?.metadata?.event?.thumbnailUrl,
      });
    }
  }, [item?.metadata]);

  const [truncateContent, setTruncateContent] = useState(true);

  // Function to toggle content truncation
  const toggleContentTruncation = () => {
    setTruncateContent(!truncateContent);
  };

  // Calculate the number of words in the content
  const wordCount = item?.content?.split(" ").length;

  useEffect(() => {
    console.log(wordCount);
    // You can adjust the threshold as needed
    if (wordCount > 500) {
      setTruncateContent(true);
    }
  }, [wordCount]);

  return (
    <Grid style={{ padding: "0px 2rem" }}>
      <Grid className={styles.container}>
        <Grid container alignItems="center">
          <Grid item="auto">
            <img
              src={item.authorData?.profilePicture || defaultAvatar}
              width="100%"
              height="10px"
              className={styles.profilePicture}
            />
          </Grid>
          <Grid alignItems="center" item="auto" className={styles.userName}>
            {item?.authorData?.firstName + " " + item?.authorData?.lastName}
          </Grid>
          <Grid alignItems="center" item="auto" className={styles.createdAt}>
            {createdAt?.month} {createdAt?.date} {createdAt?.time}
          </Grid>
          <Grid container justifyContent="flex-end" xs>
            <Button
              id="basic-button"
              aria-controls={open ? "basic-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={open ? "true" : undefined}
              onClick={handleClick}
              variant="text"
              className={styles.menuButton}
              endIcon={<MoreHorizIcon />}
            ></Button>
            <Menu
              id="basic-menu"
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              MenuListProps={{
                "aria-labelledby": "basic-button",
              }}
            >
              <MenuItem onClick={handleEdit} className={styles.menuItem}>
                Edit
              </MenuItem>
              <MenuItem onClick={handleDelete} className={styles.menuItem}>
                Delete
              </MenuItem>
            </Menu>
          </Grid>
        </Grid>

        <Grid className={styles.messageContent}>
          {item?.content?.split(" ").length < 100 ? (
            removeLinksFromString(item?.content || "")
          ) : truncateContent ? (
            <>
              {removeLinksFromString(item?.content || "")
                .trim()
                ?.split(" ")
                .slice(0, 30)
                .join(" ") + " "}
              <span
                onClick={toggleContentTruncation}
                className={styles.readMore}
              >
                {" "}
                read more...
              </span>
            </>
          ) : (
            <>
              {removeLinksFromString(item?.content || "")}
              <span
                onClick={toggleContentTruncation}
                className={styles.readMore}
              >
                {" "}
                show less
              </span>
            </>
          )}
          {item?.metadata?.event?.eventName && (
            <Grid>
              <b> {item?.metadata?.event?.eventName}</b>
            </Grid>
          )}
        </Grid>
        {!!item?.mediaUrls?.length &&
          item?.mediaUrls.map((image, index) => (
            <Grid
              container
              justifyContent="center"
              style={{ position: "relative" }}
            >
              <img key={index} src={image?.uri} className={styles.postImage} />
              <img
                key={index}
                src={image?.uri}
                className={styles.postImageBlur}
              />
            </Grid>
          ))}

        {/* {linkPreview?.title && ( */}
        <Grid
          container
          justifyContent="center"
          alignItems="center"
          className={{
            margin: "8px 0px",
            fontSize: "14px",
          }}
        >
          {linkPreview?.thumbnailUrl && (
            <Grid container>
              <img
                src={linkPreview?.thumbnailUrl}
                className={styles.linkPreviewThumbnail}
              />
            </Grid>
          )}

          <Grid container>
            {linkPreview.linkUrl && (
              <Grid item xs={12} className={styles.domainText}>
                {extractDomain(linkPreview.linkUrl)}
              </Grid>
            )}
            {linkPreview.title && (
              <Grid item xs={12} className={styles.linkDescription}>
                {linkPreview.title}
              </Grid>
            )}
            {eventLocation && (
              <Grid>
                <b>Location: </b> {eventLocation}
              </Grid>
            )}
            {startDate || endDate ? (
              <Grid container>
                {startDate && (
                  <Grid xs="auto">
                    <b>Date: </b> {formatEventDateTime(startDate)}
                  </Grid>
                )}
                {endDate && (
                  <Grid xs>
                    <b>-</b> {formatEventDateTime(endDate)}
                  </Grid>
                )}
              </Grid>
            ) : null}
          </Grid>
        </Grid>
        {/* )} */}
      </Grid>
    </Grid>
  );
};

export default FeedCard;
