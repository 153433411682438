import { Outlet } from "react-router-dom";
import { LoadingProvider } from '../context/LoadingContext';

export default function Root() {
  return (
    <div className='app'>
      <div className='wrapper'>    
      <LoadingProvider>
        <Outlet />
      </LoadingProvider>  
      </div>
    </div>
  )
  }