import "./index.css";

import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import { Box, Button, Container, Paper } from "@mui/material";
import React, { useEffect, useState } from "react";
import { isAndroid, isMobile } from "react-device-detect";
import { useFirestore } from "react-redux-firebase";
import { useOutletContext } from "react-router-dom";
import GradientButton from "../../components/GradientButton";
import { MembersDisplay } from "../../components/MembersDisplay";
import OutlineButton from "../../components/OutlinedButton";
import { ROUTES } from "../../constants";
import allowPaste from "../../static/img/allow-paste.png";
import { eventThemes } from "../../utils/event/eventThemes";
import { Zeal_Admin_ID, createAmplitudeEvent } from "../../utils/amplitude";

// import { useFirestoreConnect } from 'react-redux-firebase';

export default function Landing() {
  const firestore = useFirestore();
  const {
    group,
    invitation,
    groupId,
    eventId,
    event,
    invitationId,
    setNextPageCallback,
    continueCallback,
    rsvpStatus,
    setRsvpStatus,
    setHasBack,
    isFastTrack,
    setIsFastTrack,
  } = useOutletContext();
  const [activityName, setActivityName] = useState("");
  const [fromName, setFromName] = useState("");

  const [appLink, setAppLink] = useState("");
  const [openingApp, setOpeningApp] = useState(false);
  const [rsvp, setRsvp] = useState(false);
  const [userHasAccount, setUserHasAccount] = useState(false);

  const isOpenedInMessengerWebViewer = navigator.userAgent.match(
    /FBSN|FBAN|FBAV|FBSV|FBDV|FBMD|FBSS|FBCR|FBID|FBLC/i
  );

  useEffect(() => {
    setHasBack(false);

    // Temporary stop gap until we how children routes can handle having a skip/back button optionally

    return () => {
      setHasBack(true);
    };
  }, []);

  useEffect(() => {
    const markInvitationAsViewed = async () => {
      if (!groupId || !invitationId) return;
      const invitationDoc = await firestore
        .collection("groups")
        .doc(groupId)
        .collection("invitations")
        .doc(invitationId)
        .get();

      if (invitationDoc.exists) {
        await invitationDoc.ref.update({ viewedAt: new Date() });
      }
    };

    markInvitationAsViewed();
  }, [invitationId]);

  const buildLink = async () => {
    const domain =
      process.env.REACT_APP_ENVIRONMENT === "development"
        ? "joy-prototype.firebaseapp.com"
        : "lets.getzeal.co";

    const domainUriPrefix =
      process.env.REACT_APP_ENVIRONMENT === "development"
        ? "https://getjoy.page.link"
        : "https://getzeal.page.link";

    let bundleId =
      process.env.REACT_APP_ENVIRONMENT === "development"
        ? "com.joy.prototype-staging"
        : "com.getzeal.app";

    if (isAndroid) {
      bundleId =
        process.env.REACT_APP_ENVIRONMENT === "development"
          ? "com.joy.prototype.android"
          : "com.getzeal.app";
    }
    console.log("Bundle id:: ", bundleId);
    const res = await fetch(
      `https://firebasedynamiclinks.googleapis.com/v1/shortLinks?key=${process.env.REACT_APP_FIREBASE_API_KEY}`,
      {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          dynamicLinkInfo: {
            domainUriPrefix,
            link: `https://${domain}/join?g=${groupId}&i=${invitationId}${
              eventId ? `&e=${eventId}` : ""
            }`,
            androidInfo: {
              androidPackageName: bundleId,
              androidFallbackLink:
                "https://play.google.com/store/apps/details?id=com.getzeal.app&pli=1",
            },
            iosInfo: {
              iosBundleId: bundleId,
              iosAppStoreId: "6446917140",
            },
            navigationInfo: {
              enableForcedRedirect: true,
            },
          },
        }),
      }
    );

    if (res.ok) {
      const linkData = await res.json();

      setAppLink(linkData.shortLink);
    }
  };

  useEffect(() => {
    buildLink();
  }, [groupId, invitationId]);

  useEffect(() => {
    createAmplitudeEvent(Zeal_Admin_ID, "Web Event", {
      screenName: isFastTrack ? "fte_landing" : "group_event_landing",
      groupId: groupId,
      isFastTrack: isFastTrack,
    });
  }, []);

  console.log("AppLink:: ", appLink);

  // const populates = [
  //   { child: 'members', root: 'users' }, // replace members with user object
  //   { child: 'from', root: 'users' }, // replace from with user object
  // ]

  // const fromPopulates = [
  //   { child: 'from', root: 'users' } // replace owner with user object
  // ]

  // useFirestoreConnect([
  //   {
  //     collection: 'groups',
  //     doc: groupId,
  //     subcollections: [{ collection: 'invitations', orderBy: ['date', 'desc'] }],
  //     fromPopulates,
  //     storeAs: `group-${groupId}-invitations`
  //   },
  //   {
  //     collection: 'groups',
  //     doc: groupId,
  //     storeAs: `group-${groupId}`,
  //     populates,
  //   }
  // ])
  useEffect(() => {
    if (group?.activityId) {
      firestore
        .collection("activityTypes")
        .doc(group.activityId)
        .get()
        .then((doc) => {
          setActivityName(doc.data().name);
        });
    }
    if (invitation?.from) {
      firestore
        .collection("users")
        .doc(invitation.from.id)
        .get()
        .then((doc) => {
          setFromName(doc.data().firstName);
        });
    }
  }, [group?.activityId, invitation?.from]);

  const openAppLink = () => {
    window.location.href = appLink;
  };

  let dateString = "";
  let timeString = "";
  if (event) {
    dateString = event.startDateTime.toLocaleDateString("en-us", {
      weekday: "long",
      year: "numeric",
      month: "short",
      day: "numeric",
    });

    const endTS = new Date(
      event.startDateTime.getTime() + event.duration * 60 * 60 * 1000
    );
    timeString = `${event.startDateTime.toLocaleString("en-US", {
      hour: "numeric",
      minute: "numeric",
      hour12: true,
    })} - ${endTS.toLocaleString("en-US", {
      hour: "numeric",
      minute: "numeric",
      hour12: true,
    })}`;
  }
  useEffect(() => {
    if (rsvpStatus === "declined") {
      setNextPageCallback(ROUTES.SignUpFast);
    } else {
      setNextPageCallback(ROUTES.SignUp);
    }
  }, [rsvpStatus]);

  return (
    <Paper
      sx={{
        width: "100%",
        backgroundColor: "inherit",
        borderWidth: 0,
        justifyContent: "center",
        display: "flex",
        alignItems: "center",
        flexDirection: "column",
      }}
      square
      variant="outlined"
    >
      {!openingApp ? (
        <>
          {appLink.length || !isMobile ? (
            <Container maxWidth="lg">
              <Box className="landingContainer">
                {event && event.eventTheme && (
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      marginTop: "16px",
                    }}
                  >
                    <img
                      src={eventThemes[event.eventTheme] || event.eventTheme}
                      style={{
                        borderRadius: 10,
                        height: 260,
                        width: 260,
                      }}
                    />
                  </Box>
                )}
                <h2
                  style={{
                    fontWeight: 700,
                    fontSize: "18px",
                    marginBottom: 0,
                    marginTop: 16,
                  }}
                >
                  {isFastTrack
                    ? `${fromName} is inviting you to:`
                    : `Join ${group.name} for:`}
                </h2>
                {event ? (
                  <>
                    <h2 style={{ fontWeight: 700, fontSize: "24px" }}>
                      {event.title ? event.title : "Main Activity"}
                    </h2>
                    <h2
                      style={{ fontWeight: 700, fontSize: "18px", margin: 0 }}
                    >
                      {dateString}
                    </h2>
                    <h2
                      style={{ fontWeight: 700, fontSize: "18px", margin: 0 }}
                    >
                      {timeString}
                    </h2>
                    <h4 style={{ fontWeight: 500, fontSize: "18px" }}>
                      We're saving you a spot!
                    </h4>
                  </>
                ) : isFastTrack ? (
                  <>
                    <h2
                      style={{
                        fontWeight: 700,
                        fontSize: "24px",
                        marginTop: 16,
                        marginBottom: 0,
                      }}
                    >{`${group.name}`}</h2>
                    <h2
                      style={{ fontWeight: 500, fontSize: "18px", margin: 0 }}
                    >{`A ${activityName} event`}</h2>
                    <h4 style={{ fontWeight: 500, fontSize: "18px" }}>
                      We're saving you a spot!
                    </h4>
                  </>
                ) : (
                  <>
                    <h2
                      style={{ fontWeight: 700, fontSize: "24px" }}
                    >{`${activityName} event`}</h2>
                    <h4 style={{ fontWeight: 500, fontSize: "18px" }}>
                      We're saving you a spot!
                    </h4>
                  </>
                )}
                <h3></h3>
                <MembersDisplay members={Object.entries(group.members)} />

                <br />
                {isFastTrack && rsvp ? (
                  <>
                    <h2 style={{ fontWeight: 700, fontSize: "18px" }}>
                      Will you be attending?
                    </h2>
                    <Box
                      sx={{
                        justifyContent: "center",
                        display: "flex",
                        alignItems: "center",
                        mb: 2,
                        flexDirection: "row",
                      }}
                    >
                      <GradientButton
                        style={{
                          flex: 1,
                          margin: 5,
                          fontSize: "large",
                          minWidth: "150px",
                          fontWeight: 600,
                          flexDirection: "row",
                        }}
                        maxWidth="350px"
                        onClick={() => {
                          setRsvpStatus("attending");
                          if (userHasAccount) {
                            continueCallback(ROUTES.LogIn);
                          } else {
                            continueCallback(ROUTES.SignUp);
                          }
                        }}
                      >
                        <CheckIcon size="small" />
                        I'm going!
                      </GradientButton>
                      <OutlineButton
                        style={{
                          flex: 1,
                          margin: 5,
                          fontSize: "large",
                          minWidth: "150px",
                          fontWeight: 600,
                        }}
                        maxWidth="350px"
                        variant="outlined"
                        onClick={() => {
                          setRsvpStatus("declined");
                          if (userHasAccount) {
                            continueCallback(ROUTES.LogIn);
                          } else {
                            continueCallback(ROUTES.SignUpFast);
                          }
                        }}
                      >
                        <CloseIcon size="small" />
                        Can't make it
                      </OutlineButton>
                    </Box>
                    <Button
                      className="link"
                      onClick={() => {
                        setRsvpStatus("pending");
                        if (userHasAccount) {
                          continueCallback(ROUTES.LogIn);
                        } else {
                          continueCallback(ROUTES.SignUp);
                        }
                      }}
                      style={{
                        color: "rgba(98, 6, 191, 1)",
                        fontSize: "14px",
                        fontWeight: 600,
                      }}
                    >
                      Maybe
                    </Button>
                  </>
                ) : (
                  <>
                    {isMobile && !isOpenedInMessengerWebViewer && (
                      <GradientButton
                        style={{ flex: 1, margin: 5 }}
                        maxWidth="350px"
                        variant="outlined"
                        onClick={() => {
                          isAndroid ? openAppLink() : setOpeningApp(true); // Directly open app link in case of android, allow paste info is not required
                        }}
                      >
                        {isFastTrack
                          ? `RSVP via the ${isAndroid ? "Android" : "iOS"} app`
                          : `Join via the ${isAndroid ? "Android" : "iOS"} app`}
                      </GradientButton>
                    )}
                    {isMobile && !isOpenedInMessengerWebViewer ? (
                      <></>
                    ) : (
                      // <Button
                      //   className="link"
                      //   onClick={() => {
                      //     if (isFastTrack) setRsvp(true);
                      //     else continueCallback();
                      //   }}
                      //   style={{
                      //     color: "rgba(98, 6, 191, 1)",
                      //     fontSize: "14px",
                      //   }}
                      // >
                      //   {isFastTrack ? "RSVP via web" : "Join via web"}
                      // </Button>

                      <h2
                        style={{
                          fontWeight: 700,
                          fontSize: "18px",
                          marginBottom: 0,
                        }}
                      >
                        To join, open this link on your phone and download the
                        Zeal App.
                      </h2>
                      // <OutlineButton
                      //   style={{ flex: 1, margin: 5, fontSize: "large" }}
                      //   maxWidth="350px"
                      //   variant="outlined"
                      //   onClick={() => {
                      //     if (isFastTrack) setRsvp(true);
                      //     else continueCallback();
                      //   }}
                      // >
                      //   {isFastTrack ? "RSVP via Web" : "Join via Web"}
                      // </OutlineButton>
                    )}
                  </>
                )}

                {/* DON'T SHOW FOR MVP!<br />
                <a href='/nothanks'>No thanks, not for me</a> */}
                {/* <Button style={{width: '100%', margin: 5}} className='optionButton' variant="contained" onClick={() => navigate(`/test?group=${groupId}&invitation=${invitationId}`)}>Continue in App</Button>*/}
              </Box>
            </Container>
          ) : (
            <div></div>
          )}
        </>
      ) : (
        <>
          {isMobile && (
            <Container maxWidth="lg">
              <Box
                sx={{
                  flexGrow: 1,
                  justifyContent: "center",
                  display: "flex",
                  alignItems: "center",
                  mb: 2,
                  flexDirection: "column",
                  textAlign: "center",
                }}
              >
                <h2 style={{ fontSize: "24px" }}>
                  To join you Need to select “Allow Paste” once you download the
                  app
                </h2>
                <p style={{ fontSize: "18px", fontWeight: "500" }}>
                  Selecting “Allow Paste” lets the app take you to your
                  exclusive linked invitation.
                </p>
                <img
                  src={allowPaste}
                  width="250px"
                  height="180px"
                  alt="allow-paste"
                />
                <br />
                <GradientButton
                  style={{
                    flex: 1,
                    marginTop: 10,
                    marginBottom: 10,
                    marginRight: 5,
                    marginLeft: 5,
                  }}
                  maxWidth="350px"
                  variant="filled"
                  onClick={() => {
                    openAppLink();
                  }}
                >
                  I Understand
                </GradientButton>
                {/* IOS 2041 - removed because the flow is not very user friendly */}
                {/* <Button
                  className="link"
                  onClick={() => continueCallback()}
                  style={{ color: "rgba(98, 6, 191, 1)", fontSize: "14px" }}
                >
                  Having trouble with the app? &nbsp;
                  <strong> Join via the web.</strong>
                </Button> */}
              </Box>
            </Container>
          )}
        </>
      )}
    </Paper>
  );
}
