import { Box } from "@mui/material";
import GradientButton from "../GradientButton";


export default function HomeRedirection() {
  return (
    <Box 
      alignItems="center"
      justifyContent="center"
      display="flex"
      flexDirection="column"
      textAlign="center"
      margin="0 3rem">
      <h1>Sorry! We couldn’t find an account for this number.</h1>
      <h3>If you’re tying to start a group on Zeal, please download the Zeal app. Or if you believe you received an invitation, please contact the group organizer for the invitation link.</h3>
      <a href="/"><GradientButton>Return to Zeal Home</GradientButton></a>
    </Box>
  );
}
