import * as React from 'react';
import './index.css';

const GradientContainer = ({children, widthOverride,borderRadiusOverride}) => {
  if(widthOverride) {
    return <div style={{ width: widthOverride }} className='zealGradientContainer'>{children}</div>
  }
  if (borderRadiusOverride) {
    return <div style={{borderRadius: "20px"}} className='zealGradientContainer'>{children}</div>
  }
  return <div style={{maxWidth: 'fit-content'}} className='zealGradientContainer'>{children}</div>
}

export default GradientContainer;