import React, { useState, useEffect } from "react";
import { useOutletContext } from "react-router-dom";
import { useNavigate, useLocation } from "react-router-dom";
import GradientButton from "../../components/GradientButton";
import { populate } from "react-redux-firebase";
import { useSelector } from "react-redux";

import FormCard from "../../components/FormCard";
import { useFirestore } from "react-redux-firebase";
import { useFirebaseAuth } from "../../utils/contexts/FirebaseAuthContext.js";
import { ROUTES } from "../../constants";
import OutlineButton from "../../components/OutlinedButton";

export default function InviteFriends() {
  const {
    groupId,
    invitation,
    eventId,
    userId,
    continueCallback,
    invitationId,
    setHasSkip,
    setNextPageCallback,
    isFastTrack,
  } = useOutletContext();
  const [linkCopied, setLinkCopied] = useState(false);
  const [invitationLink, setInvitationLink] = useState("");
  const [allowedInvitationsNum, setAllowedInvitationsNum] = useState(0);
  const [width, setWidth] = useState(window.innerWidth);
  const [nextPage, setNextPage] = useState("");
  const [fromName, setFromName] = useState("");
  const [remainingSpots, setRemainingSpots] = useState(0);

  const { user } = useFirebaseAuth();

  const handleWindowSizeChange = () => {
    setWidth(window.innerWidth);
  };
  useEffect(() => {
    setHasSkip(true);
    setNextPageCallback(ROUTES.AppDownload);

    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);

  const isMobile = width <= 768;

  const domain =
    process.env.REACT_APP_ENVIRONMENT === "production"
      ? "lets.getzeal.co"
      : "joy-prototype.firebaseapp.com";

  const firestore = useFirestore();

  const populates = [
    { child: "from", root: "users" }, // replace owner with user object
  ];

  const group = useSelector(
    (state) => state.firestore.data[`group-${groupId}`]
  );

  const groupInvitationInfo = useSelector((state) =>
    populate(state.firestore, `group-${groupId}-invitations`, populates)
  );

  useEffect(() => {
    if (group) {
      const maxSize = group.groupSize.split("-")[1];
      setRemainingSpots(maxSize - group.members.length);

      if (
        allowedInvitationsNum !==
        Math.floor((maxSize - group.members.length) / group.members.length)
      ) {
        setAllowedInvitationsNum(
          Math.floor((maxSize - group.members.length) / group.members.length)
        );
      }

      if (!invitationId || invitationId === "") {
        firestore
          .collection("groups")
          .doc(groupId)
          .collection("invitations")
          .add({
            status: "pending",
            numSpots: allowedInvitationsNum,
            from: userId,
            date: Date.now(),
            expirationDate: Date.now() + 24 * 7 * 60 * 60,
          })
          .then((result) => {
            invitationId = result.id;
            setInvitationLink(
              `https://${domain}/join?g=${groupId}&i=${invitationId}${
                eventId ? `&e=${eventId}` : ""
              }`
            );
          });
      }
    } else {
      console.log("waiting for group to load...");
    }
    console.log(`group ID: ${groupId} & invitation ID: ${invitationId}`);
    if (
      invitationLink !==
      `https://${domain}/join?g=${groupId}&i=${invitationId}${
        eventId ? `&e=${eventId}` : ""
      }`
    ) {
      setInvitationLink(
        `https://${domain}/join?g=${groupId}&i=${invitationId}${
          eventId ? `&e=${eventId}` : ""
        }`
      );
    }
    console.log(invitationLink);
  }, [group, invitationId]);

  useEffect(() => {
    if (invitation?.from) {
      firestore
        .collection("users")
        .doc(invitation.from.id)
        .get()
        .then((doc) => {
          setFromName(doc.data().firstName);
        });
    }
  }, [invitation?.from]);

  const handleContinue = () => {
    continueCallback(nextPage);
  };

  const shareInvite = () => {
    navigator.clipboard.writeText(invitationLink);
    console.log(groupInvitationInfo);
    setLinkCopied(true);
  };

  return (
    <FormCard center>
      {isFastTrack ? (
        group?.plusOnes === "1" ? (
          <>
            <h1>You're allowed a plus-one to this event!</h1>
            <h4 style={{ fontWeight: 300 }}>
              {fromName} would like you to invite a plus-one who would be a
              great addition to the event.
            </h4>
          </>
        ) : (
          <>
            <h1>You're allowed plus-ones to this event!</h1>
            <h4 style={{ fontWeight: 300 }}>
              {fromName} would like you to invite up to {group?.plusOnes}{" "}
              plus-ones who would be a great addition to the event.
            </h4>
          </>
        )
      ) : (
        <>
          <h1>Let’s invite friends to join us!</h1>
          <h4 style={{ fontWeight: 300 }}>
            {fromName} would like you to invite good friends who would be great
            additions to the group.
          </h4>
          <h4 style={{ fontWeight: 300, marginTop: 0 }}>
            Hurry! The group has{" "}
            <span style={{ fontWeight: 600 }}>{remainingSpots} spots</span>{" "}
            left!
          </h4>
        </>
      )}

      <OutlineButton
        maxWidth="400px"
        onClick={() => {
          shareInvite();
        }}
      >
        {linkCopied ? "Link Copied!" : "Share Invite"}
        <img src={require("../../static/img/upload.png")} height={20} />
      </OutlineButton>

      {linkCopied && (
        <GradientButton
          maxWidth="400px"
          disabled={!linkCopied}
          onClick={() => {
            handleContinue();
          }}
        >
          Continue
        </GradientButton>
      )}
    </FormCard>
  );
}
