import * as React from "react";
import "./index.css";
import { Box, Button } from "@mui/material";
import InvitationNotFound from "../InvitationNotFound";

import { useQuery } from "../../utils/hooks/useQuery";

import {
  useFirestoreConnect,
  populate,
  isEmpty,
  isLoaded,
} from "react-redux-firebase";
import { useSelector } from "react-redux";

import { Outlet, useNavigate } from "react-router-dom";

import { useEffect, useState } from "react";
import { COLORS } from "../../constants";
import { ROUTES } from "../../constants";
import IconButton from "../../components/IconButton";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { rrulestr } from "rrule";
import { useFirestore } from "react-redux-firebase";

const LANDING_PAGE = "/join";

export default function OnboardingFlow() {
  let query = useQuery();

  let navigate = useNavigate();

  const [prevPage, setPrevPage] = useState(null);
  const [nextPage, setNextPage] = useState(null);
  const [hasSkip, setHasSkip] = useState(true);
  const [hasBack, setHasBack] = useState(true);
  const [rsvpStatus, setRsvpStatus] = useState(false);
  const [isFastTrack, setIsFastTrack] = useState(false);

  const [userId, setUserId] = useState(null);

  let invitationId = query.get("i");

  const unwantedSubstring = "undefined";

  if (invitationId.endsWith(unwantedSubstring)) {
    // Remove the unwanted substring from the end @September 9th
    // Prob not the best solution and need to find the main issue why undefined was added
    invitationId = invitationId.slice(0, -unwantedSubstring.length);
  }
  let groupId = query.get("g");
  let eventId = query.get("e");

  let calendarId = query.get("c");

  const isPersonalEvent = !!calendarId;

  const onLandingPage = window.location.pathname === LANDING_PAGE;
  console.log(
    "calendarId:: ",
    calendarId,
    eventId,
    isPersonalEvent,
    onLandingPage
  );

  if (!invitationId || !groupId || invitationId === "" || groupId === "") {
    invitationId = "INVITATION_ID_PLACEHOLDER";
    groupId = "GROUP_ID_PLACEHOLDER";
  }

  const populates = [
    { child: "members", root: "users" }, // replace members with user object
    { child: "from", root: "users" }, // replace from with user object
  ];

  const fromPopulates = [
    { child: "from", root: "users" }, // replace owner with user object
  ];

  let firestoreConnectCall = {};

  if (isPersonalEvent) {
    ///zealCalendars/phnmLuRNt4zczdAb5Zn2/calendarEvents/iLpMjFGn8MdlseIcLGmf
    firestoreConnectCall = [
      {
        collection: "zealCalendars",
        doc: calendarId,
        subcollections: [{ collection: "calendarEvents", doc: eventId }],
        storeAs: `personalEvent-${eventId}-${calendarId}`,
      },
    ];
  } else if (eventId) {
    firestoreConnectCall = [
      {
        collection: "groups",
        doc: groupId,
        subcollections: [
          { collection: "invitations", orderBy: ["date", "desc"] },
        ],
        fromPopulates,
        storeAs: `group-${groupId}-invitations`,
      },
      {
        collection: "groups",
        doc: groupId,
        storeAs: `group-${groupId}`,
        populates,
      },
      {
        collection: "groups",
        doc: groupId,
        subcollections: [{ collection: "events", doc: eventId }],
        storeAs: `group-${groupId}-event`,
      },
    ];
  } else {
    firestoreConnectCall = [
      {
        collection: "groups",
        doc: groupId,
        subcollections: [
          { collection: "invitations", orderBy: ["date", "desc"] },
        ],
        fromPopulates,
        storeAs: `group-${groupId}-invitations`,
      },
      {
        collection: "groups",
        doc: groupId,
        storeAs: `group-${groupId}`,
        populates,
      },
    ];
  }

  useFirestoreConnect(firestoreConnectCall);

  const groupInvitationInfo = useSelector((state) =>
    populate(state.firestore, `group-${groupId}-invitations`, populates)
  );

  const group = useSelector((state) =>
    populate(state.firestore, `group-${groupId}`, populates)
  );

  const eventInfo = useSelector(
    (state) => state.firestore.data[`group-${groupId}-event`]
  );

  const personalEventDetails = useSelector(
    (state) => state.firestore.data[`personalEvent-${eventId}-${calendarId}`]
  );

  const [invitationData, setInvitationData] = useState(undefined);

  useEffect(() => {
    const fetchPEdetails = async () => {
      const { creatorId, responses } = personalEventDetails;
      const creatorDoc = await firestore
        .collection("users")
        .doc(creatorId)
        .get();

      if (!creatorDoc.exists) return;

      const creatorData = creatorDoc.data();

      const responseUids = [];
      for (let response of Object.keys(responses)) {
        if (responses[response] === "attending") responseUids.push(response);
      }

      const responsesData = await Promise.all(
        responseUids.map(async (uid) => {
          const userDoc = await firestore.collection("users").doc(uid).get();
          if (!userDoc.exists) return;
          return { ...userDoc.data(), rsvpResponse: responses[uid] };
        })
      );

      setInvitationData({
        from:
          creatorData.firstName +
          (creatorData.lastName ? " " + creatorData.lastName : ""),
        responses: responsesData,
        personalEventDetails,
      });
    };
    if (personalEventDetails) {
      fetchPEdetails();
    }
  }, [JSON.stringify(personalEventDetails)]);

  const [event, setEvent] = useState(!!eventInfo ? { ...eventInfo } : null);

  const firestore = useFirestore();

  useEffect(() => {
    const getEventData = async () => {
      if (isLoaded(eventInfo)) {
        let tempEvent = { ...eventInfo };
        if (tempEvent["isRecurring"]) {
          const rRule = rrulestr(
            `${tempEvent["recurrenceStart"]}\n${tempEvent["recurrenceRule"]}`
          );
          let date = rRule.after(new Date(), true);
          if (!date) return;
          let nextInstance = new Date(
            date.getUTCFullYear(),
            date.getUTCMonth(),
            date.getUTCDate(),
            date.getUTCHours(),
            date.getUTCMinutes()
          );

          tempEvent = {
            startDateTime: nextInstance,
            title: tempEvent.title,
            duration: tempEvent.duration,
            location: tempEvent.location,
          };
        } else {
          if (tempEvent.startDateTime) {
            tempEvent.startDateTime = tempEvent.startDateTime.toDate();
            tempEvent.duration = tempEvent.duration;
            tempEvent.location = tempEvent.location;
            tempEvent.title = tempEvent.title;
          } else if (
            tempEvent.isException &&
            !tempEvent.startDateTime &&
            tempEvent.parentStartDateTime
          ) {
            const parentEventId = tempEvent.parentEventId;
            tempEvent.startDateTime = tempEvent.parentStartDateTime.toDate();
            const parentEventDoc = await firestore
              .collection("groups")
              .doc(groupId)
              .collection("events")
              .doc(parentEventId)
              .get();
            if (parentEventDoc.exists) {
              const parentEventData = parentEventDoc.data();
              tempEvent.duration = parentEventData.duration;
              tempEvent.location = parentEventData.location;
              tempEvent.title = parentEventData.title;
              tempEvent.eventTheme = parentEventData.eventTheme;
            }
          }
        }
        setEvent(tempEvent);
      }
    };

    getEventData();
  }, [JSON.stringify(eventInfo)]);

  useEffect(() => {
    if (group?.isFastTrack) {
      setIsFastTrack(group?.isFastTrack);
    } else {
      setIsFastTrack(false);
    }
  }, [JSON.stringify(group)]);

  // commenting out useEffect block for now
  // useEffect(() => {
  //   // load in once
  //   // make sure to only load in once; useFirestoreConnect can't be called conditionally in useEffect so need to find a workaround here
  // }, [groupId, invitationId])

  // remove redirect

  // if(!group && (!groupId || !invitationId || groupId === 'GROUP_ID_PLACEHOLDER' || invitationId === 'INVITATION_ID_PLACEHOLDER') || groupId === null || invitationId === null) {
  //   setTimeout(() => {}, 3000); // to allow Firestore data to load
  //   window.open('http://getzeal.co',"_self"); // redirect to Wordpress site if invalid URL
  // }

  if (isPersonalEvent) {
    if (!isLoaded(personalEventDetails)) return <Loading />;
  } else {
    if (!isLoaded(group) || !isLoaded(groupInvitationInfo)) {
      return <Loading />;
    }
  }

  if (isPersonalEvent) {
    if (isEmpty(personalEventDetails)) return <InvitationError />;
  } else {
    if (isEmpty(group) || isEmpty(groupInvitationInfo)) {
      return <InvitationError />;
    }
  }

  const goBack = () => {
    if (prevPage) {
      navigate(
        `/join/${prevPage}?g=${groupId}&i=${invitationId}&${
          eventId ? `e=${eventId}` : ""
        }`
      );
      setPrevPage(null);
    } else {
      navigate(-1);
    }
  };

  const skipToNext = () => {
    const basePath = isPersonalEvent ? "join-event" : "join";
    navigate(
      `/${basePath}/${nextPage}?${groupId ? `g=${groupId}&` : ""}${
        calendarId ? `c=${calendarId}&` : ""
      }i=${invitationId}&${eventId ? `e=${eventId}` : ""}`
    );
    setNextPage(null);
    setPrevPage(null);
  };

  const setPrevPageCallback = (page) => {
    setPrevPage(page);
  };

  const setNextPageCallback = (page) => {
    setNextPage(page);
  };

  const continueCallback = (next, navData = {}, navLink = null) => {
    if (next === undefined) {
      next = nextPage;
    }
    if (navLink) {
      navigate(navLink, {
        state: navData,
      });
      setNextPage(null);
      setPrevPage(null);
      return;
    } else {
      navigate(
        `/join/${next}?g=${groupId}&i=${invitationId}&${
          eventId ? `e=${eventId}` : ""
        }`,
        {
          state: navData,
        }
      );
      setNextPage(null);
      setPrevPage(null);
    }
  };

  // console.log(hasSkip);

  return (
    <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}>
      <Box
        alignItems="center"
        justifyContent="center"
        className="backgroundGradientOF"
      >
        <Box
          className="innerBox"
          alignItems="center"
          justifyContent="flex-start"
          display="flex"
          flexDirection="column"
        >
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            {hasBack ? (
              <IconButton
                sx={{ width: "100px" }}
                src="https://firebasestorage.googleapis.com/v0/b/joy-prototype.appspot.com/o/arrow-left.png?alt=media&token=8fe6e708-bcee-4375-9fdb-40753d55036d"
                onClick={goBack}
              />
            ) : (
              <div style={{ width: "100px" }} />
            )}

            <img
              className={`logoImg`}
              src="https://firebasestorage.googleapis.com/v0/b/joy-prototype.appspot.com/o/zeal_logo_svg.svg?alt=media&token=d79373e9-bcd5-4ad0-a789-8e6147218176&_gl=1*oyg7am*_ga*MTE0NTc5MjMuMTY3MzkwMzEyNg..*_ga_CW55HF8NVT*MTY5Nzc2MDQwNy41ODcuMS4xNjk3NzYyNzYwLjQ3LjAuMA.."
              alt="zeal logo"
              style={{ height: 48, width: 48 }}
            />

            {nextPage !== null && nextPage !== ROUTES.SignUp && hasSkip ? (
              <Button
                sx={{
                  color: COLORS.PURPLE,
                  textTransform: "initial",
                  width: "100px",
                }}
                onClick={skipToNext}
              >
                Skip
              </Button>
            ) : (
              <div style={{ width: "100px" }} />
            )}
          </div>
          <div style={{ flex: 1, width: "100%" }}>
            <Outlet
              context={
                isPersonalEvent
                  ? {
                      // invitationId,
                      eventId,
                      personalEventDetails,
                      calendarId,
                      isPersonalEvent,
                      invitation: invitationData,
                      // invitation: groupInvitationInfo[invitationId],
                      setNextPageCallback,
                      setPrevPageCallback,
                      continueCallback,
                      setUserId,
                      userId,
                      setHasSkip,
                      rsvpStatus,
                      setRsvpStatus,
                      isFastTrack,
                      setIsFastTrack,
                      hasBack,
                      setHasBack,
                    }
                  : {
                      groupId,
                      invitationId,
                      eventId,
                      event,
                      group,
                      invitation: groupInvitationInfo[invitationId],
                      setNextPageCallback,
                      setPrevPageCallback,
                      continueCallback,
                      setUserId,
                      userId,
                      setHasSkip,
                      rsvpStatus,
                      setRsvpStatus,
                      isFastTrack,
                      setIsFastTrack,
                      hasBack,
                      setHasBack,
                    }
              }
            />
          </div>
          {(onLandingPage || isPersonalEvent) && (
            <>
              <h3
                style={{
                  marginTop: "13px",
                  fontWeight: "500",
                  fontSize: "14px",
                  textAlign: "center",
                  color: "rgba(18, 18, 31, 0.6)",
                }}
              >
                Activities with friends, magically easy.
              </h3>
            </>
          )}
        </Box>
      </Box>
    </GoogleOAuthProvider>
  );
}

const Loading = () => {
  return (
    <Box
      alignItems="center"
      justifyContent="center"
      className="backgroundGradientOF"
    >
      <Box
        className="innerBox"
        alignItems="center"
        justifyContent="flex-start"
        display="flex"
        flexDirection="column"
      >
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <img
            className="logoImg"
            src="https://firebasestorage.googleapis.com/v0/b/joy-prototype.appspot.com/o/zeal-logo-stacked.svg?alt=media&token=d5b70703-8a4d-4626-a75f-63ab7664f6f7"
            alt="zeal logo"
          />
        </div>
        <img
          className="groupThumbnail hideMobile"
          src="https://firebasestorage.googleapis.com/v0/b/joy-prototype.appspot.com/o/zeal_banner.svg?alt=media&token=9a574c6d-da7d-4557-958a-ddf83d38afca"
          alt="Zeal banner"
        />
        <p>Loading...</p>
      </Box>
    </Box>
  );
};

const InvitationError = () => {
  return (
    <Box
      alignItems="center"
      justifyContent="center"
      className="backgroundGradientOF"
    >
      <Box
        className="innerBox"
        alignItems="center"
        justifyContent="flex-start"
        display="flex"
        flexDirection="column"
      >
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <img
            className="logoImg"
            src="https://firebasestorage.googleapis.com/v0/b/joy-prototype.appspot.com/o/zeal-logo-stacked.svg?alt=media&token=d5b70703-8a4d-4626-a75f-63ab7664f6f7"
            alt="zeal logo"
          />
        </div>
        <img
          className="groupThumbnail hideMobile"
          src="https://firebasestorage.googleapis.com/v0/b/joy-prototype.appspot.com/o/zeal_banner.svg?alt=media&token=9a574c6d-da7d-4557-958a-ddf83d38afca"
          alt="Zeal banner"
        />
        <InvitationNotFound />
      </Box>
    </Box>
  );
};
