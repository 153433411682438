export const Zeal_Admin_ID =
  process.env.REACT_APP_ENVIRONMENT === "development"
    ? "da85TnTPqOd0UjXCVxBrIG4IC243"
    : "VztuLYDSqxgr59PvwrX7KQfg1Q12";

export const createAmplitudeEvent = async (userId, eventType, eventProps) => {
  // log amplitude event for group creation
  const amplitudeKey = process.env.AMPLITUDE_API_KEY;
  console.log("Amplitude key -", Zeal_Admin_ID, amplitudeKey);

  const url = "https://api.amplitude.com/2/httpapi";
  const headers = {
    "Content-Type": "application/json",
  };
  const data = {
    api_key: amplitudeKey,
    events: [
      {
        user_id: userId,
        event_type: eventType,
        event_properties: {
          userId: userId,
          ...eventProps,
        },
      },
    ],
  };

  console.log("DATA:: ", data);

  const response = await fetch(url, {
    method: "POST",
    headers: headers,
    body: JSON.stringify(data),
  });

  const result = await response.json();
  if (result.code !== 200) {
    console.error(
      "🔴🔴 Some error occurred while updating creation event to amplitude 🔴🔴 -",
      result
    );
    return;
  }
  console.log(
    "🟢🟢 Successfully updated group creation event to amplitude 🟢🟢 -",
    result
  );
};
