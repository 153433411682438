import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useFirestoreConnect } from 'react-redux-firebase';
import {CopyToClipboard} from 'react-copy-to-clipboard';
import { TextField, Paper, Button } from '@mui/material';

export default function FindInvitation() {
  const [expoPrefix, setExpoPrefix] = useState('');
  const [message, setMessage] = useState('');
  const [invitationId, setInvitationId] = useState('gkhl3CWyuzga1Q9E8IRr');
  const [groupId, setGroupId] = useState('4uBX2vpl35QX2hmEAJQ1');

  const populates = [
    { child: 'groupId', root: 'groups' }, // replace owner with user object
  ];

  useFirestoreConnect({
    collection: 'invitations', doc: invitationId, storeAs: 'currInvitation', populates,
  });

  const invitation = useSelector(
    ({ firestore: { data } }) => data.currInvitation,
  );

  return(
    <div className="App">
      <Paper>
      <h1>Create Invitation Link!</h1>
      <a href={`getzeal.com/invitations/${invitationId}?msg=${message}`}>{`getzeal.com/invitations/${invitationId}?msg=Some%20optional%20message`}</a>
      <p>{JSON.stringify(invitation)}</p>
      <Paper>
        <TextField id='outlined-required' value={expoPrefix} onChange={(e) => setExpoPrefix(e.target.value)} label={"Expo Prefix"}  />
        <TextField id='outlined-required' value={message} onChange={(e) => setMessage(e.target.value)} label={"Message"}  />
        <TextField id='outlined-required' value={invitationId} onChange={(e) => setInvitationId(e.target.value)} label={"Invitation ID"}  />
      </Paper>
      {expoPrefix ? (<Paper><h3>URL</h3><a href={`${expoPrefix}/--/onboarding/?group=${groupId}&invitation=${invitationId}&msg=${encodeURIComponent(message)}`}>{`${expoPrefix}/--/onboarding/?group=${groupId}&invitation=${invitationId}&msg=${encodeURIComponent(message)}`}</a>
      <h3>Bash Command</h3><CopyToClipboard text={`npx uri-scheme open "${expoPrefix}/--/onboarding/?group=${groupId}&invitation=${invitationId}&msg=${encodeURIComponent(message)}" --ios`}
          onCopy={() => console.log('copied')}>
          {/* <span>Copy to clipboard with span</span> */}
          <Button title='COPY TO CLIPBOARD' variant="contained">COPY TO CLIPBOARD</Button>
        </CopyToClipboard></Paper>)
      : ''
      }
      </Paper>
    </div>
)
}