import { Grid } from "@mui/material";
import React from "react";
import styles from "./styles.module.css";
import Emoji from "react-emojis";

const SelectInterestsComponent = ({
  interests,
  activityTypes,
  handleSelectInterest,
  selectInterests,
  setIsSelectMoreActivitiesVisible,
}) => {
  return (
    <Grid container>
      {interests?.map((item) => {
        return (
          <Grid
            container
            xs="auto"
            className={
              selectInterests.includes(item)
                ? styles.selectedEmojiContainer
                : styles.emojiContainer
            }
            onClick={() => {
              handleSelectInterest(item);
            }}
            gap={1}
          >
            <Emoji size="small" emoji={activityTypes?.[item]?.webEmoji} />
            <Grid className={styles.interestLabel}>
              {activityTypes?.[item]?.name}
            </Grid>
          </Grid>
        );
      })}
      <Grid
        xs="auto"
        className={styles.addMoreButtonContainer}
        onClick={() => {
          setIsSelectMoreActivitiesVisible(true);
        }}
      >
        <Grid className={styles.interestLabel} style={{ color: "#ffffff" }}>
          Add More +{" "}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default SelectInterestsComponent;
