export const ROUTES = {
  Home: "/",
  Root: "",
  Star: "*",
  LogIn: "sign-in",
  AdminLogIn: "admin-sign-in",
  Join: "join",
  JoinPersonalEvent: "join-event",
  SignUp: "signup",
  SignUpFast: "signup-fast",
  SignUpPE: "signup-pe",
  SignUpFastPE: "signup-fast-pe",
  Calendar: "calendar",
  ActivityDays: "activitydays",
  Availability: "availability",
  Dashboard: "dashboard",
  AboutGroup: "group-info",
  OF1Prefix: "join/",
  Invitation: "groups/:groupId/invitations/:invitationId",
  Group: "groups/:groupId",
  GroupPage: "groups",
  MobileRedirect: "app-link",
  PrivacyPolicy: "http://getzeal.co/privacy-policy/",
  TermsOfService: "http://getzeal.co/terms-of-service/",
  InviteFriends: "invite-friends",
  UserConsent: "consent",
  SelectInterests: "select-interests",
  SetLocation: "set-location",
  AppDownload: "app-download",
  ThankYou: "thank-you",
  AppStore:
    "https://apps.apple.com/us/app/zeal-activities-with-friends/id6446917140",
  PlayStore:
    "https://play.google.com/store/apps/details?id=com.getzeal.app&pli=1",
  User: "user",
  Admin: "admin",
  DiscoverScreen: "discover",
  EmailVerified: "email-verification",
  EmailUnsubscribe: "email-unsubscribe",
  GroupDetails: "group-staging",
  GroupChat: "group-chat",
  PollResultsList: "poll-results-list",
  CreatePoll: "create-poll",
};

export const COLORS = {
  PURPLE: "#6206BF",
};
