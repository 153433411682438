import React from 'react';
import { Box, Paper, Button } from '@mui/material';
import IconButton from '../IconButton';
import Icon from '../Icon';

const CalendarDetail = ({ provider, type, onClick, header, subtext, disabled }) => {

    // simple override for OAuth verification
    if(provider==='google' && type === 'add') {
        return <Button onClick={onClick}>
            <img src='https://firebasestorage.googleapis.com/v0/b/joy-prototype.appspot.com/o/google_web_signin_button.png?alt=media&token=78cc460b-9dc6-4a0f-b9dd-972281a8da0e' style={{maxWidth: '100%', maxHeight: '75px'}} alt='add to gcal' />
        </Button>
        // return(
        //     <Button onClick={onClick}>
        //     <Paper sx={{ flexGrow: 1 }} style={ disabled ? { display: 'inline-flex', width: '100%', margin: 14, alignItems: 'center', opacity: 0.5 } : { display: 'inline-flex', width: '100%', margin: 15, alignItems: 'center', justifyContent: 'space-between' }}>
        //     <Box><img src='https://firebasestorage.googleapis.com/v0/b/joy-prototype.appspot.com/o/google_web_signin_button.png?alt=media&token=78cc460b-9dc6-4a0f-b9dd-972281a8da0e' style={{maxWidth: '100%', maxHeight: '75px'}} alt='add to gcal' /></Box>
        //     <Box>
        //         <IconButton disabled={disabled} src={type === 'add' ? 'https://firebasestorage.googleapis.com/v0/b/joy-prototype.appspot.com/o/add_button.png?alt=media&token=44dd4dab-f292-4bb3-8180-510018f39a0c' : 'https://firebasestorage.googleapis.com/v0/b/joy-prototype.appspot.com/o/delete_button.png?alt=media&token=deae0f24-164a-49d5-9c4f-341ab3f83a07'} alt='button' onClick={onClick}></IconButton>
        //     </Box>
            
        // </Paper>
        // </Button>
        // )
    }
	return (
        <Paper sx={{ flexGrow: 1 }} style={ disabled ? { display: 'inline-flex', width: '100%', margin: 14, alignItems: 'center', opacity: 0.5 } : { display: 'inline-flex', width: '100%', margin: 15, alignItems: 'center' }}>
            <Box><Icon src={provider === 'Google' ?  'https://firebasestorage.googleapis.com/v0/b/joy-prototype.appspot.com/o/google-logo.png?alt=media&token=66ebfdf4-48d7-4340-9d22-d5963467a613' : 'https://firebasestorage.googleapis.com/v0/b/joy-prototype.appspot.com/o/icloud-logo-transparent.png?alt=media&token=68eb5cfb-8573-450d-a3a9-1b484d4aec09'} alt='icon'></Icon></Box>
            <Box sx={{ flexGrow: 1 }}><h3>{header}</h3><p>{subtext}</p></Box>
            <Box><IconButton disabled={disabled} src={type === 'add' ? 'https://firebasestorage.googleapis.com/v0/b/joy-prototype.appspot.com/o/add_button.png?alt=media&token=44dd4dab-f292-4bb3-8180-510018f39a0c' : 'https://firebasestorage.googleapis.com/v0/b/joy-prototype.appspot.com/o/delete_button.png?alt=media&token=deae0f24-164a-49d5-9c4f-341ab3f83a07'} alt='button' onClick={onClick}></IconButton></Box>
        </Paper>
	);
};

export default CalendarDetail;