export const dayValues = [
    { id: "Sunday", display: "S" },
    { id: "Monday", display: "M" },
    { id: "Tuesday", display: "T" },
    { id: "Wednesday", display: "W" },
    { id: "Thursday", display: "T" },
    { id: "Friday", display: "F" },
    { id: "Saturday", display: "S" },
  ];
  
export const weekdays = [
"Monday",
"Tuesday",
"Wednesday",
"Thursday",
"Friday",
];
export const weekends = ["Saturday", "Sunday"];

export const DAYS = {
Sunday: 0,
Monday: 1,
Tuesday: 2,
Wednesday: 3,
Thursday: 4,
Friday: 5,
Saturday: 6,
};

export const IdxToDAYS = {
0: 'Sunday',
1: 'Monday',
2: 'Tuesday',
3: 'Wednesday',
4: 'Thursday',
5: 'Friday',
6: 'Saturday',
};

export const ABBREVIATIONS = {
Sunday: "Sun",
Monday: "Mon",
Tuesday: "Tues",
Wednesday: "Wed",
Thursday: "Thurs",
Friday: "Fri",
Saturday: "Sat",
};

export const HOUR_TO_MIN = 60;

export const FREQUENCY_OPTIONS = ['Once', 'Daily', 'Weekly', 'Monthly'];
export const FREQUENCY_PARAMS = ['ONE TIME', 'DAILY', 'WEEKLY', 'MONTHLY'];
export const FREQ = {
    3: 'DAILY',
    2: 'WEEKLY',
    1: 'MONTHLY',
  };