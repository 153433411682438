import { CometChat } from "@cometchat-pro/chat";
import { GroupManager } from "../controllers/GroupManager";
import firebase from "../services/firebaseConfig";
import { AppTypes } from "./AppTypes";
import { recomposeMessage } from "../utils/messageUtils";

export const triggerTextCloudFunction = (message, uid, receiverId) => {
  console.log(
    "Cloud functions data is  ",
    message?.getConversationId,
    message?.getText(),
    uid,
    receiverId
  );
  console.log("Message to send text ", message);

  const data = {
    groupId: receiverId,
    authorId: uid,
    conversationId: message?.getConversationId(),
    messageId: message?.getId(),
    content: recomposeMessage(message),
  };
  const onNewGroupMessage = firebase
    .functions()
    .httpsCallable("onNewGroupMessage");
  onNewGroupMessage(data)
    .then((res) =>
      console.log("Cloud function called successfully for new group msg ", res)
    )
    .catch((err) =>
      console.log("Error while calling the cloud function ", err)
    );
};

export const triggerMultiMediaCloudFunction = (
  message,
  mediaType,
  uid,
  receiverId
) => {
  const data = {
    groupId: receiverId,
    authorId: uid,
    conversationID: message?.getConversationId(),
    groupName: message?.getReceiver()?.getName(),
    mediaType: mediaType,
    messageId: message?.getId(),
  };

  const onMultiMediaMessage = firebase
    .functions()
    .httpsCallable("onMultiMediaMessage");
  onMultiMediaMessage(data)
    .then((res) =>
      console.log("Cloud function called successfully for media message ", res)
    )
    .catch((err) =>
      console.log(
        "Error while calling the cloud function for media message ",
        err
      )
    );
};

export const triggerPollCloudFunction = (
  receiverID,
  conversationID,
  groupName,
  pollId
) => {
  const data = {
    groupId: receiverID,
    conversationID: conversationID,
    groupName: groupName,
    pollId: pollId,
  };

  const onNewPoll = firebase.functions().httpsCallable("onNewPoll");
  onNewPoll(data)
    .then((res) =>
      console.log("Cloud function called successfully for new poll ", res)
    )
    .catch((err) =>
      console.log("Error while calling the cloud function ", err)
    );
};

export const triggerUserVoteOnPollCloudFunction = (
  receiverID,
  conversationID,
  groupName,
  pollId,
  groupMembers,
  votedMembers,
  uid
) => {
  const data = {
    groupId: receiverID,
    conversationID: conversationID,
    groupName: groupName,
    messageId: pollId,
    groupMembers: groupMembers,
    votedMembers: votedMembers,
    uid: uid,
  };
  const onUserVoteOnPoll = firebase
    .functions()
    .httpsCallable("onUserVoteOnPoll");
  onUserVoteOnPoll(data)
    .then((res) =>
      console.log("Cloud function called successfully for new poll vote ", res)
    )
    .catch((err) =>
      console.log("Error while calling the cloud function ", err)
    );
};

export const triggerMarkPostNotificationAsRead = (
  userId,
  conversationId,
  messageId,
  markPrevNotificationsAsRead
) => {
  const data = {
    targetUserId: userId,
    conversationId: conversationId,
    messageId: messageId,
    markPrevNotificationsAsRead,
  };
  const markPostNotificationAsRead = firebase
    .functions()
    .httpsCallable("markPostNotificationAsRead");
  markPostNotificationAsRead(data)
    .then((res) =>
      console.log(
        "Cloud function called successfully for marking notification as read ",
        res
      )
    )
    .catch((err) =>
      console.log(
        "Error while calling the cloud function markPostNotificationAsRead: ",
        err
      )
    );
};

export const triggerDMCloudFunction = async (
  message,
  mediaType,
  uid,
  getMappedFirebaseUId,
  receiverId
) => {
  try {
    let id;
    if (message) id = message?.getReceiverId();
    else id = receiverId;

    const onDirectMessage = firebase
      .functions()
      .httpsCallable("onDirectMessage");

    let recipients = [];

    if (id.includes(AppTypes.SUB_GROUP)) {
      const groupMembers = await GroupManager.getGroupMembers(id);
      const filteredMembers = groupMembers
        .filter((member) => member.getUid() !== uid.toLowerCase())
        .map((user) => user.getUid());

      const recipientQueries = filteredMembers.map((member) =>
        getMappedFirebaseUId(member)
      );
      recipients = (await Promise.all(recipientQueries)).map((query) => {
        if (query.size) return query.docs[0].id;
      });
    } else {
      const idQuery = await getMappedFirebaseUId(id);
      if (idQuery.size) {
        recipients = [idQuery.docs[0].id];
      }
    }

    const data = {
      recipients,
      authorId: uid,
      content:
        message instanceof CometChat.TextMessage
          ? recomposeMessage(message)
          : "",
      mediaType: mediaType,
      conversationId: message?.getConversationId(),
      messageId: message?.getId(),
    };
    console.log("data to send to cloud function is ", data);

    await onDirectMessage(data);
  } catch (error) {
    console.log(error);
  }
};

export const triggerInvitesReminderCloudFunction = async (
  groupId,
  organizerId,
  uid
) => {
  if (organizerId !== uid) return;

  try {
    const onInviteMembers = firebase
      .functions()
      .httpsCallable("onInviteMembers");

    const data = {
      groupId: groupId,
    };

    console.log("data to send to cloud function is ", data);

    await onInviteMembers(data);

    console.log("Cloud function called successfully for new invite");
  } catch (error) {
    console.log(error);
  }
};
