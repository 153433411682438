import * as React from 'react';
import {useEffect, createContext, useContext, useState } from 'react';
import firebase from '../../services/firebaseConfig';


const FirebaseAuthContext = createContext({});

const FirebaseAuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [pending, setPending] = useState(true);
  const value = { user, pending };

  useEffect(() => {
    const unsubscribe = firebase.auth().onAuthStateChanged((user) => {
      setUser(user);
      setPending(false);
    });
    return unsubscribe;
  }, []);

  return (
    <FirebaseAuthContext.Provider value={value}>
      {children}
    </FirebaseAuthContext.Provider>
  );
};

function useFirebaseAuth() {
    const context = useContext(FirebaseAuthContext);
    if (context === undefined) {
      throw new Error(
        "useFirebaseAuth must be used within a FirebaseAuthProvider"
      );
    }
    if(!context.user) {
      return {'user': null};
    }
    return context.user.multiFactor;
}  

export { FirebaseAuthContext, FirebaseAuthProvider, useFirebaseAuth };