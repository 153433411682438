import React, { useContext } from 'react';
import { Navigate, Outlet, useOutletContext } from 'react-router-dom';
import { FirebaseAuthContext, useFirebaseAuth } from '../contexts/FirebaseAuthContext.js';

export const AuthenticatedRoute = () => {
    const { user, pending  } = useContext(FirebaseAuthContext);
    const context = useOutletContext();
    if (pending) return <></>;
    return user ? <Outlet context={context}/> : <Navigate to="/sign-in" />;
}

export const UnauthenticatedRoute = () => {
    const { user  } = useFirebaseAuth();
    return !user ? <Outlet /> : <Navigate to="/dashboard" />;
}