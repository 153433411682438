import { Box, LinearProgress, Typography } from "@mui/material";
import React from "react";

const CompressionProgressBar = ({ progress }) => {
  const [numerator, denominator] = progress.split("/").map(Number);
  const progressBar = (numerator / denominator).toFixed(2) * 100;

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        mb: 1,
        pt: 1,
      }}
    >
      <Box sx={{ width: "70%", mb: 1 }}>
        <LinearProgress
          variant="determinate"
          value={progressBar}
          sx={{
            // Change the color of the LinearProgress bar
            "& .MuiLinearProgress-bar": {
              backgroundColor: "#6206bf",
            },
            // Change the background strip color
            "& .MuiLinearProgress-background": {
              backgroundColor: "rgba(98, 6, 191, 0.2)",
            },
          }}
        />
      </Box>
      <Box>
        <Typography
          variant="body2"
          color="text.secondary"
        >{`Compressing ${progress}`}</Typography>
      </Box>
    </Box>
  );
};

export default CompressionProgressBar;
