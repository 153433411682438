import { Box } from "@mui/material";
import "./index.css";

const GroupHeader = ({groupName, groupActivity, groupSize, onPress}) => {
  return (
    <>
      <Box
        onClick={()=> {
          if (onPress) {
            onPress();
          }
        }}
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <h2 id="headerText">{groupName}</h2>
        <h4 className="subheader">{groupActivity} - {groupSize} members</h4>
      </Box>
      <div id="divider"></div>
    </>
  );
};

export default GroupHeader;
