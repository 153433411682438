import { Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import goBack from "../../static/img/groupDashboard/go-back.svg";
import groupLogo from "../../static/img/groupDashboard/group-logo1.svg";
import moreInfo from "../../static/img/groupDashboard/more-info.svg";
import "./index.css";

const GroupPrimaryHeader = ({ group, groupId }) => {
  const location = useLocation();
  const currentRoute = location.pathname.split("/").pop();
  const [headerData, setHeaderData] = useState({
    title: "",
    subTitle: "",
    showGroupInfo: false,
  });

  const handleClick = () => {
    navigate(`/groups/${groupId}/group-info`);
  };

  useEffect(() => {
    if (group) {
      const queryParameters = window.location.pathname.split("/").pop();

      if (queryParameters == "group-chat") {
        setHeaderData({
          title: "Group Chat",
          subTitle: group?.name,
          showGroupInfo: true,
        });
      } else {
        setHeaderData({
          title: group?.name,
          subTitle: `${group?.members.length} Members`,
          showGroupInfo: true,
        });
      }
    }
  }, [group]);

  function addEllipsisIfLong(str) {
    if (str.length > 25) {
      return str.slice(0, 25) + '...';
    }
    return str;
  }

  const navigate = useNavigate();
  return (
    <>
    <Grid container className="primary-header-container">
      <Grid item container xs="auto" alignItems="flex-start">
        <img
          onClick={() => {
            navigate(-1);
          }}
          className="go-back-icon"
          src={goBack}
          alt="group-logo"
          height="16px"
        />
      </Grid>
      {headerData.showGroupInfo && (
        <>
          <Grid item container xs style={{gap: "0.2rem"}}>
            <Grid item xs="auto" container>
              <img
                className="chat-icons"
                src={groupLogo}
                alt="group-logo"
                height="40px"
                style={{paddingTop: "3px"}}
              />
            </Grid>
            <Grid item xs container>
              <Grid container className="group-name">
                {addEllipsisIfLong(headerData?.title)}
              </Grid>
              <Grid container className="group-members">
                {headerData?.subTitle}
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs="auto" container alignSelf="center">
            {currentRoute !== "group-info" && (
              <img
                onClick={() => {
                  handleClick();
                }}
                className="info-icon"
                src={moreInfo}
                alt="group-logo"
              />
            )}
          </Grid>
        </>
      )}
    </Grid>
    </>
  );
};

export default GroupPrimaryHeader;
