import React from 'react';

const Icon = ({ src, alt, maxHeight }) => {
	if(maxHeight) {
		return (
			<img src={src} alt={alt} style={{maxHeight, padding: 10}}/>
		);
	}
	return (
        <img src={src} alt={alt} style={{height: '50px', width: '50px', padding: 10}}/>
	);
};

export default Icon;