import React from "react";
import { Box } from "@mui/system";
import "./index.css";

const Pill = ({ children, radius }) => {
  return (
    <div className="memberPill">
      <div className="memberPillInner">{children}</div>
    </div>
  );
};

const MemberPill = (member) => {
  if (!member || !member.firstName) {
    return (
      <div className="memberPill" key="error">
        <div className="memberPillInner">
          <span>??</span>
        </div>
      </div>
    ); // quick error checking!
  }
  return (
    <div className="memberPill" key={member.firstName + member.lastName}>
      <div className="memberPillInner">
        <span>
          {member.firstName.substring(0, 1).toUpperCase()}
          {member.lastName.substring(0, 1).toUpperCase()}
        </span>
      </div>
    </div>
  );
};

export const MembersDisplay = ({
  members,
  limit = 6,
  showMemberOverview = true,
  label = "members"
}) => {
  return (
    <Box
      alignItems="center"
      justifyContent="center"
      display="flex"
      flexDirection="row-reverse"
    >
      {members?.length > limit ? (
        <Pill radius="25px">
          <span>+{members?.length - limit}</span>
        </Pill>
      ) : (
        ""
      )}
      {members?.slice(0, limit).map((m) => MemberPill(m[1]))}
      {showMemberOverview && (
        <div className="memberOverviewPill" radius="25px">
          <div className="memberOverviewPillInner">
            <span style={{ padding: "0px", margin: "0px" }}>
              {members?.length} {label}
            </span>
          </div>
        </div>
      )}
    </Box>
  );
};
