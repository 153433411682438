import React from 'react';
import { Box } from '@mui/material';

const FormCard = ({ children, center}) => {
	return (
		<Box
          display='flex'
          justifyContent={center ? 'center' : 'space-between'}
          alignItems='center'
          flexDirection='column'
		  textAlign='center'
		  flex={1}
		  style={{margin: '15px', padding: '25px'}}
		  component="form"
		  sx={{
				'& .MuiTextField-root': { m: 1, width: '25ch' },
			}}
		  noValidate
		  onSubmit={(e)=>{
			e.preventDefault();
		}}
	   	  autoComplete="off"
        >
			{children}
		</Box>
	);
};

export default FormCard;