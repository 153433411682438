import * as React from "react";
import "./index.css";
import { Box, Button, CircularProgress } from "@mui/material";
import { useFirebaseAuth } from "../../utils/contexts/FirebaseAuthContext.js";
import firebase from "../../services/firebaseConfig";
import { useState, useEffect } from "react";

import CalendarDetail from "../../components/CalendarDetail";

import { useOutletContext } from "react-router-dom";

import GradientButton from "../../components/GradientButton";

import { ROUTES } from "../../constants";
import { useGoogleLogin } from "@react-oauth/google";
import { useLoadingContext } from "../../utils/hooks/useLoading";
import { useFirestore } from "react-redux-firebase";

export default function IntegrateCalendar() {
  const { loading, setLoading, } = useLoadingContext();

  const {
    group,
    groupId,
    event,
    setNextPageCallback,
    continueCallback,
    userId,
    setHasSkip,
    isFastTrack
  } = useOutletContext();


  const PROVIDER_INFO = {
    google: { providerName: "Google" },
  };

  const firestore = useFirestore();

  const [connectedCalendars, setConnectedCalendars] = useState([]);


  useEffect(() => {
    firestore.collection('groups').doc(groupId).get()
        .then((doc) => {
          const {hasSchedule, hasGroup, members, groupSize} = doc.data()
          const maxGroupSize = parseInt(groupSize.split('-')[1])
          if (connectedCalendars.length === 0) {
            setHasSkip(true);
            if (isFastTrack) {
              if (group?.plusOnes && parseInt(group?.plusOnes) > 0) {
                console.log("navigating", maxGroupSize - members.length)
                setNextPageCallback(ROUTES.InviteFriends);
              } else {
                setNextPageCallback(ROUTES.AppDownload);
              }
            } else {
              setNextPageCallback(ROUTES.ActivityDays);
            }
            
          } else {
            setHasSkip(false);
      
          
            if (isFastTrack) {
              if (group?.plusOnes && group?.plusOnes > 0) {
                setNextPageCallback(ROUTES.InviteFriends);
              } else {
                setNextPageCallback(ROUTES.AppDownload);
              }
            } else {
              if(!hasSchedule && !hasGroup && members.length < maxGroupSize){
                continueCallback(ROUTES.InviteFriends);
              }
              else{
                setNextPageCallback(ROUTES.AppDownload)
              }
            }
          }
    })
  }, [connectedCalendars, isFastTrack]);

  const getGoogleTokens = (codeResponse) => {
    const getGoogleTokens = firebase
      .functions()
      .httpsCallable("google-getGoogleTokens");
    getGoogleTokens({
      code: codeResponse.code,
      redirect_uri: "postmessage",
      platform: "web",
    })
      .then((result) => {
        const { data } = result;
        const { email } = data;
        const googleCalendar = {
          title: email,
          providerName: "Google",
          provider: "google",
        };

        // add this Google account to connected calendars if not already there
        if (!connectedCalendars.find((cal) => cal.title === email)) {
          setConnectedCalendars([...connectedCalendars, googleCalendar]);
        }
      })
      .catch((error) => console.log(error.message));
  };

  const signInWithGoogle = useGoogleLogin({
    onSuccess: getGoogleTokens,
    flow: "auth-code",
    scope:
      "https://www.googleapis.com/auth/calendar.readonly https://www.googleapis.com/auth/userinfo.email",
  });

  useEffect(() => {
    if (userId) {
      const getEmails = firebase
        .functions()
        .httpsCallable("scheduler-getUserCalendarEmails");
      setLoading(true);
      getEmails().then((res) => {
        const emails = res.data;
        const prevConnectedCalendars = emails.map((emailInfo) => {
          return {
            title: emailInfo.email,
            providerName: PROVIDER_INFO[emailInfo.provider].providerName,
            provider: emailInfo.provider,
          };
        });
        setConnectedCalendars(prevConnectedCalendars);
        setLoading(false);
      });
      const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
      firestore.collection("users").doc(userId).update({ timezone });
    }
  }, [userId]);

  // kept for testing purposes
  // const signOut = () => {firebase.auth().signOut()};

  const deleteCalendar = (removeIndex) => {
    const calendarToRemove = connectedCalendars[removeIndex];
    const revokeGoogleTokens = firebase
      .functions()
      .httpsCallable("scheduler-removeUserCalendar");
    revokeGoogleTokens({
      email: calendarToRemove.title,
      provider: calendarToRemove.provider,
    }).then(() =>
      setConnectedCalendars(
        connectedCalendars.filter((_, index) => index !== removeIndex)
      )
    );
  };

  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="center"
      textAlign="center"
      flexDirection="column"
    >
      {loading ? (
        <CircularProgress />
      ) : (
        <>
          <h1>
            {connectedCalendars.length > 0
              ? "Success! You connected your calendar"
              : isFastTrack ? "Help your organizer with scheduling"
              : "Let Zeal help with scheduling"}
          </h1>
          {connectedCalendars.length > 0 ? (
            <p>
              Connecting additional calendars, like your work calendar, will
              make planning even easier.{" "}
            </p>
          ) : (
            <>
              {!isFastTrack ? (
                <>
                  <p>
                    Coordinating ongoing group activities is now simple - no more
                    endless text and email threads!
                  </p>
                  <p>
                    Connect your calendar so Zeal can find times that work for the
                    group and send you calendar invitations and reminders.{" "}
                  </p>
                </>
              ):(
                <p>
                  Connect your calendar so Zeal can find times that work for this
                  event and send you calendar invitations and reminders.{" "}
                </p>
              )}
              
            </>
          )}
          {connectedCalendars.length > 0 ? <h3>Your linked calendar:</h3> : ""}
          {connectedCalendars.map((cal, index) => (
            <CalendarDetail
              key={cal.title}
              onClick={() => {
                deleteCalendar(index);
              }}
              provider={cal.providerName}
              type="delete"
              header={cal.title}
              subtext={cal.providerName}
            />
          ))}
          {connectedCalendars.length > 0 ? (
            <h3>Connect more calendars (optional)</h3>
          ) : (
            ""
          )}
          <CalendarDetail
            onClick={signInWithGoogle}
            provider="google"
            type="add"
            header="Google Calendar"
            subtext="Gmail, G Suite"
          />
          {/* iCloud Calendar is commented out because it won't be used for alpha <CalendarDetail onClick={() => console.log('disabled!')} disabled provider='iCloud' type='add' header='iCloud Calendar' subtext='Please download the app to sync your iCloud calendar.' /> */}
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            flexDirection="column"
          >
            {/* <Button id="signout_button" onClick={() => signOut()}>Sign Out</Button> */}
            <p>
              <strong>Don’t worry!</strong> Only Zeal can see your calendar and will keep calendar details private. Zeal will use your availability to find times when the group can meet.
            </p>
            <br />
            {connectedCalendars.length > 0 && <GradientButton
              maxWidth="343px"
              onClick={() => {
                setHasSkip(true);
                continueCallback();
              }}
            >
              Next
            </GradientButton>}
          </Box>
        </>
      )}
    </Box>
  );
}
