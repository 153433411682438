import { Alert, Collapse, Grid } from "@mui/material";
import React from "react";
import styles from "./notes.module.css";

const NotesComponent = ({ incomingData }) => {
  return (
    <Grid container>
      <Grid container className={styles.noteMessage}>
        I’m sharing a note for us to collaborate on
      </Grid>
      <Grid item xs={12}>
        <Collapse in={true}>
          <Alert
            icon={false}
            severity="error"
            style={{
              width: "fit-content",
              margin: "20px 0px",
              color: "#000000",
              backgroundColor: "#F4F4F8",
            }}
          >
            To view or add Notes please download the app from the app store.
          </Alert>
        </Collapse>
      </Grid>
    </Grid>
  );
};

export default NotesComponent;
