export const eventThemes = {
  coffee:
    "https://firebasestorage.googleapis.com/v0/b/zeal-production-378716.appspot.com/o/themes%2Fcoffee.png?alt=media&token=e42ec8e6-3ee4-4ca2-a60a-e9c12a464fdd",
  dinnerParty:
    "https://firebasestorage.googleapis.com/v0/b/zeal-production-378716.appspot.com/o/themes%2FdinnerParty.png?alt=media&token=573fc96a-0b73-4aaf-bc4a-9b20d2cb50eb",
  dinner:
    "https://firebasestorage.googleapis.com/v0/b/zeal-production-378716.appspot.com/o/themes%2Fdinner.png?alt=media&token=35fad934-bab0-434e-9230-712159b54513",
  mountain:
    "https://firebasestorage.googleapis.com/v0/b/zeal-production-378716.appspot.com/o/themes%2Fmountain.png?alt=media&token=14b2d54b-dc1a-4fe2-961c-592315f9cc8b",
  saveTheDate:
    "https://firebasestorage.googleapis.com/v0/b/zeal-production-378716.appspot.com/o/themes%2FsaveTheDate.png?alt=media&token=f500276a-258f-438b-bc79-9cde2dffa42c",
  walk: "https://firebasestorage.googleapis.com/v0/b/zeal-production-378716.appspot.com/o/themes%2Fwalk.png?alt=media&token=b5b60472-3753-4531-ad80-6c7ccab62811",
  holiday:
    "https://firebasestorage.googleapis.com/v0/b/zeal-production-378716.appspot.com/o/themes%2Fholiday.png?alt=media&token=37565225-e138-4a0b-be33-1c62752acf55",
  party:
    "https://firebasestorage.googleapis.com/v0/b/zeal-production-378716.appspot.com/o/themes%2Fparty.png?alt=media&token=833dbfa4-161d-4e11-8150-a66f10856ee7",
  celebrate:
    "https://firebasestorage.googleapis.com/v0/b/zeal-production-378716.appspot.com/o/themes%2Fcelebrate.png?alt=media&token=02bb0c4f-7ba7-40a5-afe5-d5fd9f4620b0",
};
