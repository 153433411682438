import {
  Box,
  Button,
  Card,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  Typography,
} from "@mui/material";
import React, { useState, useCallback } from "react";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import LocationOnOutlinedIcon from "@mui/icons-material/LocationOnOutlined";
import {
  FREQUENCY_OPTIONS,
  FREQUENCY_PARAMS,
  HOUR_TO_MIN,
} from "../../constants/timeConstants";
import { MembersDisplay } from "../MembersDisplay";
import CheckCircleOutlineOutlinedIcon from "@mui/icons-material/CheckCircleOutlineOutlined";
import { useSelector } from "react-redux";
import { isLoaded, useFirebase, useFirestore } from "react-redux-firebase";
import { useFirebaseAuth } from "../../utils/contexts/FirebaseAuthContext";

export const EventCard = ({
  startDateTimeString,
  duration,
  frequency,
  eventName,
  userResponse,
  attendees,
  groupSize,
  location,
  eventId,
  groupId,
  isFastTrack,
}) => {
  const getCardStyle = useCallback((variant) => {
    let label = "";
    let labelColor = "";
    const cardBackgroundColor =
      variant === "attending" ? "#6206BF" : "#FFFFFF";
    const cardTextColor =
      variant === "attending" ? "#FFFFFF" : "#6C6C70";
    const buttonColor =
      variant === "attending" ? "#FFFFFF" : "#12121F";

    switch (variant) {
      case "attending":
        label = "Confirmed";
        labelColor = "#6206BF";
        break;
      case "pending":
        label = isFastTrack ? "Pending" : "Planned";
        labelColor = "#FFA500";
        break;
      case "declined":
        label = "Declined";
        labelColor = "#6C6C70";
        break;
    }

    return {
      label,
      labelColor,
      cardTextColor,
      cardBackgroundColor,
      buttonColor,
    };
  }, []);

  const { label, labelColor, cardTextColor, cardBackgroundColor, buttonColor } =
  getCardStyle(userResponse);


  const styles = {
    statusBoxStyle: {
      backgroundColor: labelColor,
      maxWidth: "fit-content",
      color: "#FFFFFF",
      borderTopLeftRadius: 8,
      borderTopRightRadius: 8,
      borderBottomLeftRadius: 0,
      borderBottomRightRadius: 0,
      padding: "4.5px 6px 4.5px 6px",
      marginTop: "8px",
      marginBottom: "-1px",
    },
    eventCardStyle: {
      backgroundColor: cardBackgroundColor,
      color: cardTextColor,
      maxWidth: "100%",
      minWidth: "fit-content",
      borderRadius: "0px 16px 16px 16px",
      padding: "8px 12px 8px 12px",
      boxShadow: "2px 4px 10px rgba(0, 0, 0, 0.15)",
      marginTop: "-1px",
    },
    rowContainer: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
    },
    inlineBlock: {
      display: "inline-block",
    },
  };

  const firestore = useFirestore();
  const { user } = useFirebaseAuth();
  const uid = user.uid;
  const [openDeclineDialog, setDeclineDialog] = useState(false);
  const [openAcceptDialog, setAcceptDialog] = useState(false);
  const event = useSelector(
    (state) =>
      state.firestore.data[`events-${groupId}`] && state.firestore.data[`events-${groupId}`][eventId]
  );
  const events = useSelector((state) => state.firestore.data[`events-${groupId}`]);

  const handleDeclineClick = () => {
    if (event.isRecurring || event.isException) {
      setDeclineDialog(true);
    } else {
      firestore
        .collection("groups")
        .doc(groupId)
        .collection("events")
        .doc(eventId)
        .update({
          [`responses.${uid}`]: "declined",
        });
    }
  };

  const handleDeclineClose = () => {
    setDeclineDialog(false);
  };

  const handleAcceptClick = () => {
    if (event.isRecurring || event.isException) {
      setAcceptDialog(true);
    } else {
      firestore
        .collection("groups")
        .doc(groupId)
        .collection("events")
        .doc(eventId)
        .update({
          [`responses.${uid}`]: "attending",
        });
    }
  };

  const handleAcceptClose = () => {
    setAcceptDialog(false);
  };

  const renderDate = () => {
    return new Date(startDateTimeString).toLocaleString("en-us", {
      month: "long",
      day: "numeric",
      weekday: "long",
    });
  };

  const renderStartTime = () => {
    return new Date(startDateTimeString).toLocaleTimeString("en", {
      timeStyle: "short",
    });
  };

  const eventHasOpening = (event) => {
    return (
      !event?.participantLimit ||
      Object.keys(event.responses).filter(
        (el) => event.responses[el] === "attending"
      ).length < event?.participantLimit
    );
  };

  const renderEndTime = () => {
    const dateObj = new Date(startDateTimeString);
    return new Date(
      dateObj.getFullYear(),
      dateObj.getMonth(),
      dateObj.getDate(),
      dateObj.getHours(),
      dateObj.getMinutes() + duration * HOUR_TO_MIN
    ).toLocaleTimeString("en", { timeStyle: "short" });
  };

  const declineRecurring = (editThisInstance) => {
    if (!editThisInstance) {
      if (event.isException) {
        // batch write that removes user from exception event and parent event
        const batch = firestore.batch();

        const exceptionDocRef = firestore
          .collection("groups")
          .doc(groupId)
          .collection("events")
          .doc(eventId);

        // update exception
        batch.update(exceptionDocRef, {
          [`responses.${uid}`]: "declined",
        });

        const eventDocRef = firestore
          .collection("groups")
          .doc(groupId)
          .collection("events")
          .doc(event.parentEventId);
        // update event rule
        batch.update(eventDocRef, {
          [`responses.${uid}`]: "declined",
        })

        // commit the batch to Firestore and navigate upon success
        batch.commit().then(()=> {
          handleDeclineClose();
        });;;
      } else {
        firestore
          .collection("groups")
          .doc(groupId)
          .collection("events")
          .doc(eventId)
          .update({
            [`responses.${uid}`]: "declined",
          }).then(()=> {
            handleDeclineClose();
          });;
      }
    } else {
      if (event.isException) {
        firestore
          .collection("groups")
          .doc(groupId)
          .collection("events")
          .doc(eventId)
          .update({
            [`responses.${uid}`]: "declined",
          }).then(()=> {
            handleDeclineClose();
          });
      } else {
        const newResponses = {
          ...event.responses,
          [uid]: "declined",
        };
        firestore
          .collection("groups")
          .doc(groupId)
          .collection("events")
          .add({
            isScheduled: true,
            isCanceled: false,
            isException: true,
            isRecurring: false,
            parentEventId: eventId,
            parentStartDateTime: new Date(startDateTimeString),
            responses: newResponses,
            startDateTime: null,
            title: null,
            location: null,
            duration: null,
            isPrimaryActivity: false,
            recurrenceRule: null,
            recurrenceStart: null,
            creatorId: null,
            participantLimit: event.participantLimit,
          })
          .then(()=> {
            handleDeclineClose();
          });;
      }
    }
  };

  const acceptRecurring = (editThisInstance) => {
    if (!editThisInstance) {
      if (event.isException) {
        if (eventHasOpening(events[event.parentEventId])) {
          // batch write that adds user to exception event and parent event
          const batch = firestore.batch();

          const exceptionDocRef = firestore
            .collection("groups")
            .doc(groupId)
            .collection("events")
            .doc(eventId);

          // update exception
          batch.update(exceptionDocRef, {
            [`responses.${uid}`]: "attending",
          });

          const eventDocRef = firestore
            .collection("groups")
            .doc(groupId)
            .collection("events")
            .doc(event.parentEventId);

          // update event rule
          batch.update(eventDocRef, {
            [`responses.${uid}`]: "attending",
          });

          // commit the batch to Firestore and navigate upon success
          batch.commit().then(()=> {
            handleAcceptClose();
          });
        } else {
          firestore
            .collection("groups")
            .doc(groupId)
            .collection("events")
            .doc(eventId)
            .update({
              [`responses.${uid}`]: "attending",
            }).then(()=> {
              handleAcceptClose();
            });;
        }
      } else {
        firestore
          .collection("groups")
          .doc(groupId)
          .collection("events")
          .doc(eventId)
          .update({
            [`responses.${uid}`]: "attending",
          }).then(()=> {
            handleAcceptClose();
          });;
      }
    } else {
      if (event.isException) {
        firestore
          .collection("groups")
          .doc(groupId)
          .collection("events")
          .doc(eventId)
          .update({
            [`responses.${uid}`]: "attending",
          }).then(()=> {
            handleAcceptClose();
          });;
      } else {
        const newResponses = {
          ...event.responses,
          [uid]: "attending",
        };
        firestore
          .collection("groups")
          .doc(groupId)
          .collection("events")
          .add({
            isScheduled: true,
            isCanceled: false,
            isException: true,
            isRecurring: false,
            parentEventId: eventId,
            parentStartDateTime: new Date(startDateTimeString),
            responses: newResponses,
            startDateTime: null,
            title: null,
            location: null,
            duration: null,
            isPrimaryActivity: false,
            recurrenceRule: null,
            recurrenceStart: null,
            creatorId: null,
            participantLimit: event.participantLimit,
          }).then(()=> {
            handleAcceptClose();
          });;
      }
    }
  };

  return (
    isLoaded(events) &&
    isLoaded(event) && (
      <Box
        sx={{ marginLeft: "4px", display: "flex", justifyContent: "center" }}
      >
        <Box sx={{ width: "100%" }}>
          <Box sx={styles.statusBoxStyle}>
            <Typography variant={"subtitle2"}>
              {label}
            </Typography>
          </Box>
          <Card sx={styles.eventCardStyle}>
            <Box sx={styles.rowContainer}>
              <Box>
                <Typography variant={"subtitle2"}>
                  {eventName || "Event"}
                </Typography>
                <Typography variant={"body1"} sx={[{ fontWeight: "600" }, userResponse === 'declined' ? {textDecoration: 'line-through'} : {}]}>
                  {renderDate()}
                </Typography>
                <Typography
                  variant={"body1"}
                  sx={[styles.inlineBlock, { fontWeight: "300" }, userResponse === 'declined' ? {textDecoration: 'line-through'} : {}]}
                >
                  {`${renderStartTime()} → ${renderEndTime()}`}
                </Typography>
                &nbsp;
                <Typography
                  variant={"body1"}
                  sx={[styles.inlineBlock, { fontWeight: "bold" }, userResponse === 'declined' ? {textDecoration: 'line-through'} : {}]}
                >
                  {`- ${
                    FREQUENCY_OPTIONS[FREQUENCY_PARAMS.indexOf(frequency)]
                  } on ${new Date(startDateTimeString).toLocaleDateString(
                    "en-US",
                    {
                      weekday: "short",
                    }
                  )}`}
                </Typography>
              </Box>
              <Box sx={{ display: "flex", alignItems: "flex-start" }}>
                {userResponse !== "attending" && (
                  <IconButton onClick={handleAcceptClick} sx={{color: buttonColor, padding: 0 }}>
                    <CheckCircleOutlineOutlinedIcon fontSize="large" />
                  </IconButton>
                )}
                {userResponse !== "declined" && (
                  <IconButton onClick={handleDeclineClick} sx={{ color: buttonColor, padding: 0 }}>
                    <CancelOutlinedIcon fontSize="large" />
                  </IconButton>
                )}
              </Box>
            </Box>
            <Box
              sx={[
                styles.rowContainer,
                { marginTop: "7px", alignItems: "center" },
              ]}
            >
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <LocationOnOutlinedIcon />
                <Typography variant={"body2"}>{`${location} ·`}</Typography>
                &nbsp;
                <Typography variant={"body2"}>
                  {" "}
                  {`${attendees.length} / ${groupSize} going`}
                </Typography>
              </Box>
              <Box sx={{ color: "black" }}>
                <MembersDisplay
                  members={attendees}
                  showMemberOverview={false}
                  limit={2}
                />
              </Box>
            </Box>
          </Card>
        </Box>
        <Dialog open={openDeclineDialog} onClose={handleDeclineClose}>
          <DialogTitle>RSVP</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Please specify which occurrences you would like to decline.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => declineRecurring(true)}>
              Just this occurrence
            </Button>
            <Button onClick={() => declineRecurring(false)} autofocus>
              This and all recurring
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog open={openAcceptDialog} onClose={handleAcceptClose}>
          <DialogTitle>RSVP</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Please specify which occurrences you would like to accept.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => acceptRecurring(true)}>
              Just this occurrence
            </Button>
            <Button onClick={() => acceptRecurring(false)} autofocus>
              This and all recurring
            </Button>
          </DialogActions>
        </Dialog>
      </Box>
    )
  );
};
