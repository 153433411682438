import React from 'react';
import { useSelector } from 'react-redux';
import { useFirestoreConnect } from 'react-redux-firebase';
import { Form, useParams } from 'react-router-dom';
import { Box, Paper, Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { populate } from 'react-redux-firebase';
import FormCard from '../../components/FormCard';
import './index.css'
import { useQuery } from '../../utils/hooks/useQuery';
import { useFirestore } from 'react-redux-firebase';

export default function Invitation() {
  let {invitationId, groupId} = useParams();

  let query = useQuery();

  const firestore = useFirestore();

  if(!invitationId || !groupId) {
    invitationId = query.get('i');
    groupId = query.get('g');
  }

  const navigate = useNavigate();

  const populates = [
    { child: 'from', root: 'users' } // replace owner with user object
  ]

  const membersPopulates = [
    { child: 'members', root: 'users' } // replace owner with user object
  ]

  useFirestoreConnect([
    {
      collection: 'groups',
      doc: groupId,
      subcollections: [{ collection: 'invitations', orderBy: ['date', 'desc'] }],
      populates,
      storeAs: `group-${groupId}-invitations`
    },
    {
      collection: 'groups',
      doc: groupId,
      storeAs: `group-${groupId}`,
      // membersPopulates,
    }
  ])

  const groupInvitationInfo = useSelector(state => populate(state.firestore, `group-${groupId}-invitations`, populates));

  const group = useSelector(state => populate(state.firestore, `group-${groupId}`, membersPopulates ));

  if(!group || !groupInvitationInfo) {
    return <h1>Loading....</h1>
  }

  if(groupInvitationInfo) {
    const exp = new Date(groupInvitationInfo[invitationId].expirationDate);
    const now = new Date();
    if(now.getTime() > exp.getTime()) {

      firestore.collection('groups').doc(groupId).collection('invitations').doc(invitationId).update({
        status: 'expired'});

      return(
        <FormCard center>
          <Box>
          <img src={group.photoURL} alt='main'/>
          <img src={group.avatarPhotoURL} alt='avatar'/>
          <h1>Sorry, your invitation has expired!</h1>
          <p>Please ask the group owner to resend your invitation.</p>
          <Button variant="contained" onClick={() => navigate('/signup')}>Make Your Own Group</Button>
        </Box>
        </FormCard>
      )
    }
    else {
      console.log('just in time');
    }
  }

  return(<FormCard center>
    {(group.members && group.members.length !== group.maxSize) ? (
        <Box style={{display: 'flex', flex: 1, alignItems: 'center', justifyContent: 'center', flexDirection: 'column'}}>
          <img src={group.photoUrl} alt='main'/>
          <img src={groupInvitationInfo.from ? (groupInvitationInfo.from.avatarUrl) : (groupInvitationInfo[invitationId].from.avatarUrl)} alt='avatar'/>
          <h1>{groupInvitationInfo.from ? (groupInvitationInfo.from.firstName) : (groupInvitationInfo[invitationId].from.firstName)} has invited you to join {group.name ? group.name : group[groupId].name}!</h1>
          <p>We've saved a spot for you!</p>
          <p style={{color: 'red'}}>{group.maxSize - Object.keys(group.members).length} spots left</p>
          <Box style={{display: 'flex', flex: 1, alignItems: 'center', justifyContent: 'center', flexDirection: 'column'}}>
            <Button style={{width: '100%', margin: 5}} className='optionButton' variant="contained" onClick={() => navigate('/toapplinK!!!!')}>Continue in App</Button>
            <Button style={{width: '100%', margin: 5}} className='optionButton' variant="outlined" onClick={() => navigate(`/signup?group=${groupId}&invitation=${invitationId}`)}>Join via Web</Button>
            <Button style={{width: '100%', margin: 5}} className='optionButton' variant="outlined" onClick={() => navigate('/nothanks')}>No thanks, this isn't for me</Button>
          </Box>
          <p>Zeal makes experiencing group activities magically effortless.</p>
        </Box>
    ) : (
      <Box>
          <img src={group.photoURL} alt='main'/>
          <img src={group.avatarPhotoURL} alt='avatar'/>
          <h1>Sorry, all spots are taken!</h1>
          <Button variant="contained" onClick={() => navigate('/signup')}>Make Your Own Group</Button>
        </Box>
    )}
    </FormCard>
  )
}