import { CometChat } from "@cometchat-pro/chat";

export class MessageController {
  static getMessageRequestInstance = (groupId, limit) => {
    let categories = [CometChat.CATEGORY_MESSAGE, CometChat.CATEGORY_CUSTOM];
    return new CometChat.MessagesRequestBuilder()
      .setCategories(categories)
      .hideDeletedMessages(true)
      .hideReplies(true)
      .setGUID(groupId)
      .setLimit(limit)
      .build();
  };

  static attachMessageListeners = (listenerID, onNewMessage, onEditMessage) => {
    console.log("Attaching Listeners...");
    return CometChat.addMessageListener(
      listenerID,
      new CometChat.MessageListener({
        onTextMessageReceived: (textMessage) => {
          console.log("Text message received successfully", textMessage);
          onNewMessage(textMessage);
        },
        onMediaMessageReceived: (mediaMessage) => {
          console.log("Media message received successfully", mediaMessage);
          onNewMessage(mediaMessage);
        },
        onCustomMessageReceived: (customMessage) => {
          console.log("Custom message received successfully", customMessage);
          onNewMessage(customMessage);
        },
        onMessageEdited: (message) => {
          console.log("Edited Message recieved successfully", message);
          onEditMessage(message);
        },
      })
    );
  };

  static createTextMessage = (receiverId, receiverType, message) => {
    return new CometChat.TextMessage(receiverId, message, receiverType);
  };

  static createMediaMessage = (
    receiverId,
    files,
    receiverType,
    messageType
  ) => {
    return new CometChat.MediaMessage(
      receiverId,
      files,
      messageType,
      receiverType
    );
  };

  static sendTextMessage = (textMessage) => {
    return CometChat.sendMessage(textMessage);
  };

  static sendMediaMessage = (mediaMessage) => {
    return CometChat.sendMediaMessage(mediaMessage);
  };
}
