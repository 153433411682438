import React from 'react';

const IconButton = ({ src, alt, onClick, disabled, sx, maxSize }) => {
	return (
        <button onClick={onClick} style={{ ...sx,
            background: 'none',
            color: 'inherit',
            border: 'none',
            padding: 10,
            font: 'inherit',
            cursor: 'pointer',
            outline: 'inherit'}} disabled={disabled}>
            <img src={src} alt={alt} style={{height: '50px', width: '50px', maxWidth: maxSize, maxHeight: maxSize}}/>
        </button>
	);
};

export default IconButton;