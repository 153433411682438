import * as React from "react";
import { TextField } from "@mui/material";
import { alpha, styled } from "@mui/material/styles";
import { COLORS } from "../../constants";

const ZealInputStyled = styled(TextField)(({ width = null, style }) => {
  return {
    "& label.Mui-focused": {
      color: "#6206bf",
    },
    "& .MuiFormLabel-root": {
      color: "#6206bf",
    },
    "& .MuiInputBase-root": {
      "& fieldset": {
        borderColor: "#6206bf",
      },
      "&:hover fieldset": {
        borderColor: "#6206bf",
      },
    },
    ...(width && { width: `${width} !important` }),
    margin: "0 !important",
    ...style,
  };
});

export default function ZealInput({ children, width = null, ...props }) {
  return (
    <ZealInputStyled width={width} {...props}>
      {children}
    </ZealInputStyled>
  );
}
