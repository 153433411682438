import * as React from "react";
import "./index.css";
import { Box, TextField, Button } from "@mui/material";
import { useState, useEffect, useRef } from "react";
import firebase from "../../services/firebaseConfig";
import { useFirestore } from "react-redux-firebase";
import { useNavigate } from "react-router-dom";
import validator from "validator";

import FormCard from "../../components/FormCard";

import { useOutletContext } from "react-router-dom";

import GradientButton from "../../components/GradientButton";

import { COLORS, ROUTES } from "../../constants";

import { isMobile } from "react-device-detect";

import "react-phone-number-input/style.css";
import Input from "react-phone-number-input/input";
import PhoneInput, {
  getCountryCallingCode,
  isPossiblePhoneNumber,
  isValidPhoneNumber,
} from "react-phone-number-input";
import { CometChat } from "@cometchat-pro/chat";
import { parsePhoneNumber } from "react-phone-number-input";
import ZealInput from "../../components/ZealInput";
import {
  INVITATION_STATUS,
  addInvitationToUser,
  claimOrDeclineInvitations,
} from "../../utils/helpers/invitations";

export default function SignUpFast() {
  const {
    group,
    groupId,
    invitationId,
    invitation,
    setPrevPageCallback,
    continueCallback,
    setUserId,
    setHasSkip,
    rsvpStatus,
    isFastTrack,
    eventId,
  } = useOutletContext();

  const [didSendCode, setDidSendCode] = useState(false);

  const recaptchaWrapper = useRef();

  const firestore = useFirestore();
  const navigate = useNavigate();

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [confirmEmail, setConfirmEmail] = useState("");
  const [isValidEmail, setIsValidEmail] = useState(true); // true initially so when user submits, it will check for validity
  const [isEmailMatch, setIsEmailMatch] = useState(true); // true initially so when user submits, it will check for matching
  const [phoneCountryCode, setPhoneCountryCode] = useState("+1");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [otp, setOTP] = useState("");
  const [final, setFinal] = useState("");
  const [verify, setVerify] = useState(null);
  const [backUpVerify, setBackupVerify] = useState(null);
  const [signingIn, setSigningIn] = useState(false);
  const [dots, setDots] = useState("");

  useEffect(() => {
    if (signingIn) {
      const start = new Date();
      const interval = setInterval(() => {
        setDots(
          Array(1 + (Math.round((new Date() - start) / 700) % 4)).join(".")
        );
      }, 700);
      return () => clearInterval(interval);
    }
  }, [signingIn]);

  const rsvpUserToFastEvent = async ({ result }) => {
    if (!rsvpStatus || rsvpStatus === "pending" || rsvpStatus === "declined")
      return;
    if (!eventId) return;
    await firestore.runTransaction(async (transaction) => {
      const eventDocRef = await firestore
        .collection("groups")
        .doc(groupId)
        .collection("events")
        .doc(eventId);

      const eventDoc = await transaction.get(eventDocRef);

      if (!eventDoc.exists) return;
      const eventData = eventDoc.data();
      if (eventData?.isCanceled) return;

      const responses = eventData.responses;

      const newResponses = { ...responses, [result.user.uid]: rsvpStatus };
      transaction.set(
        eventDocRef,
        { responses: newResponses },
        { merge: true }
      );
    });
  };

  useEffect(() => {
    if (email.length > 0) {
      setIsValidEmail(validator.isEmail(email));
    }
  }, [email]);

  useEffect(() => {
    if (email.length > 0 && confirmEmail.length > 0) {
      setIsEmailMatch(email === confirmEmail);
    }
  }, [email, confirmEmail]);

  const addUserToFirestoreGroup = async (uid) => {
    await firestore
      .collection("groups")
      .doc(groupId)
      .update({
        members: firebase.firestore.FieldValue.arrayUnion(uid),
      });
  };
  const rsvpToInvitation = async (uid, groupId, invitationId) => {
    if (isFastTrack) {
      if (rsvpStatus === "attending") {
        await addUserToCometChatGroupAndClaimInvite(uid);
      } else if (rsvpStatus === "pending") {
        await claimOrDeclineInvitations(
          firestore,
          uid,
          groupId,
          INVITATION_STATUS.tentative,
          invitationId
        );
        await addInvitationToUser(
          firestore,
          uid,
          groupId,
          invitation.from.id,
          invitation.from.firstName,
          INVITATION_STATUS.tentative,
          eventId
        );
      } else {
        await claimOrDeclineInvitations(
          firestore,
          uid,
          groupId,
          INVITATION_STATUS.declined,
          invitationId
        );
      }
    } else {
      await addUserToCometChatGroupAndClaimInvite(uid);
    }
  };

  const createUserDoc = async (result, phoneNumber) => {
    // create user doc in Firestore if there isn't one already
    const doc = await firestore.collection("users").doc(result.user.uid).get();
    const uid = result.user.uid;
    if (!doc.exists) {
      const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
      await firestore
        .collection("users")
        .doc(result.user.uid)
        .set({
          demographics: {
            age: "",
            displayAge: false,
            displayGender: false,
            gender: "",
          },
          firstName,
          lastName,
          email: email,
          interests: ["yZxGeaV2RG5COUKBq86p", "xJ85cxwFGaoTu5Krs13e"],
          locations: [],
          profilePicture: "",
          status: 0,
          platform: "web",
          phoneNumber: parsePhoneNumber(phoneNumber).nationalNumber,
          phoneCountryCode,
          notificationPreferences: {
            silenceAll: false,
            allowSmsAlerts: true,
            allowEmailAlerts: true,
            allowEmailAnnouncements: true,
            allowCalendarReminders: true,
            allowPollReminders: true,
            isGroupAllowed: {},
            silencedUsers: [],
          },
          expoPushTokens: [],
          timezone,
        });

      if (!isFastTrack) {
        // add user as member of group
        await addUserToFirestoreGroup(uid);
      } else {
        if (rsvpStatus === "attending") {
          await addUserToFirestoreGroup(uid);
          await rsvpUserToFastEvent(uid);
        }
      }

      const name = `${firstName} ${lastName}`;

      const cometChatCreateUser = firebase
        .functions()
        .httpsCallable("createCometChatUser");

      cometChatCreateUser({ name })
        .then((res) => {
          const authToken = res.data;

          CometChat.login(authToken).then(
            (user) => {
              rsvpToInvitation(uid, groupId, invitationId);
              console.log("CometChat Login Successful:", { user });
            },
            (error) => {
              CometChat.login(authToken).then(
                (user) => {
                  rsvpToInvitation(uid, groupId, invitationId);
                  console.log("CometChat Login Successful:", { user });
                },
                (error) => {
                  console.log("CometChat Login failed with exception:", {
                    error,
                  });
                }
              );
            }
          );
        })
        .catch((error) => {
          if (error.code === "functions/already-exists") {
            // User will have been logged out via CometChat.logout so create a new auth token
            const createAuthToken = firebase
              .functions()
              .httpsCallable("createCometChatAuthToken");
            createAuthToken().then((res) => {
              const authToken = res.data;
              CometChat.login(authToken).then(
                (user) => {
                  rsvpToInvitation(uid, groupId, invitationId);
                  console.log("CometChat Login Successful:", {
                    user,
                  });
                },
                (error) => {
                  console.log("CometChat Login failed with exception:", {
                    error,
                  });
                }
              );
            });
          } else {
            console.log(error);
          }
        });

      const onNewWebUser = firebase.functions().httpsCallable("onNewWebUser");
      await onNewWebUser({
        userId: result.user.uid,
        groupId: groupId,
      });
      console.log("User doc created and set up for onboarding");
      continueCallback(ROUTES.SelectInterests);
    } else {
      if (!isFastTrack) {
        // add user as member of group
        await addUserToFirestoreGroup(uid);
      } else {
        if (rsvpStatus === "attending") {
          await addUserToFirestoreGroup(uid);
          await rsvpUserToFastEvent(uid);
        }
      }
      const createAuthToken = firebase
        .functions()
        .httpsCallable("createCometChatAuthToken");
      createAuthToken().then((res) => {
        const authToken = res.data;
        CometChat.login(authToken).then(
          (user) => {
            rsvpToInvitation(uid, groupId, invitationId);
            console.log("CometChat Login Successful:", {
              user,
            });
            if (isFastTrack) {
              if (rsvpStatus === "declined") {
                continueCallback(ROUTES.AppDownload);
              } else {
                if (!group?.hasSchedule) {
                  continueCallback(ROUTES.Calendar);
                } else if (group?.plusOnes && parseInt(group?.plusOnes) > 0) {
                  continueCallback(ROUTES.InviteFriends);
                } else {
                  continueCallback(ROUTES.AppDownload);
                }
              }
            } else {
              navigate("/user");
            }
          },
          (error) => {
            console.log("CometChat Login failed with exception:", {
              error,
            });
          }
        );
      });
    }
  };

  const sendVerification = async () => {
    setSigningIn(true);
    // set default to US if country code not specified
    if (!phoneNumber.includes("+")) {
      setPhoneNumber(`+1${phoneNumber}`);
    }

    if (!isPossiblePhoneNumber(phoneNumber)) {
      console.log("invalid phone number");
      // TO DO: if invalid phone number, display appropriately on frontend
      return;
    }

    await firebase
      .auth()
      .signInWithPhoneNumber(phoneNumber, verify)
      .then((result) => {
        setFinal(result);
        console.log(result);
        setSigningIn(false);
        setDidSendCode(true);
      })
      .catch((err) => {
        firebase
          .auth()
          .signInWithPhoneNumber(phoneNumber, backUpVerify)
          .then((result) => {
            setFinal(result);
            console.log(result);
            setDidSendCode(true);
          })
          .catch((err) => {
            alert(err);
            console.log(err);
          });
        verify.reset();
      });
  };

  const addUserToCometChatGroupAndClaimInvite = async (uid) => {
    let groupType = CometChat.GROUP_TYPE.PUBLIC;

    if (rsvpStatus !== "declined") {
      CometChat.joinGroup(groupId, groupType, "").then(
        (group) => {
          console.log("Group joined successfully:", group);
        },
        (error) => {
          console.log("Group joining failed with exception:", error);
        }
      );
    }

    await claimOrDeclineInvitations(
      firestore,
      uid,
      groupId,
      INVITATION_STATUS.claimed,
      invitationId
    );
  };

  const validateOTP = async () => {
    if (otp === null || final === null) return;
    else if (otp === "") return;

    setSigningIn(true);
    await final
      .confirm(otp)
      .then((result) => {
        console.log("confirmed otp!");
        // create new user document in users collection or update existing user if one already exists

        // log in to CometChat
        const { uid } = result.user;
        createUserDoc(result, phoneNumber);
        setUserId(result.user.uid);
      })
      .catch((err) => {
        console.log(err);
        setSigningIn(false);
        alert("Wrong code. Please enter again.");
      });
  };

  const resendVerification = () => {
    if (isMobile && backUpVerify) {
      backUpVerify.clear();
    }

    if (verify && recaptchaWrapper) {
      verify.clear();
      recaptchaWrapper.current.innerHTML = `<div id="recaptcha-container" className="justify-center flex"></div>`;
    }

    setVerify(
      new firebase.auth.RecaptchaVerifier("recaptcha-container", {
        size: "invisible",
      })
    );

    if (isMobile) {
      setBackupVerify(
        new firebase.auth.RecaptchaVerifier("recaptcha-container")
      );
    }
  };

  useEffect(() => {
    if (isMobile) {
      setVerify(
        new firebase.auth.RecaptchaVerifier("recaptcha-container", {
          size: "invisible",
        })
      );
      // setVerify(new firebase.auth.RecaptchaVerifier('recaptcha-container'));
      setBackupVerify(
        new firebase.auth.RecaptchaVerifier("recaptcha-container")
      );
    } else {
      setVerify(
        new firebase.auth.RecaptchaVerifier("recaptcha-container", {
          size: "invisible",
        })
      );
    }
    setPrevPageCallback("");
    setHasSkip(false);
  }, []);

  useEffect(() => {
    if (didSendCode) {
      sendVerification();
    }
  }, [verify]);

  return (
    <Box alignItems="center" justifyContent="center" width="100%">
      {
        <FormCard>
          <h1>
            {isFastTrack
              ? rsvpStatus === "declined" && invitation?.from?.firstName
                ? `Let ${invitation.from.firstName} know you can't make it`
                : "Lock in your RSVP"
              : "Nice to meet you!"}
          </h1>
          <Box className="formContainer">
            <h3>We'll stop sending you reminders.</h3>
            <Box
              style={{
                display: "flex",
                justifyContent: "space-between",
                marginBottom: 11,
              }}
            >
              <ZealInput
                required
                id="outlined-required"
                label={"First Name"}
                value={firstName}
                placeholder="Taylor"
                onChange={(e) => setFirstName(e.target.value)}
                style={{ marginRight: "9px !important", flex: 1 }}
              />
              <ZealInput
                required
                id="outlined-required"
                label={"Last Name"}
                value={lastName}
                placeholder="Taylor"
                onChange={(e) => setLastName(e.target.value)}
                style={{ flex: 1 }}
              />
            </Box>
            <Box
              style={{
                width: "100%",
                flexDirection: "column",
                display: "flex",
              }}
            >
              <ZealInput
                required
                id="outlined-required"
                label="Email Address"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                placeholder="name@email.com"
                width={"100%"}
              />
              {!isValidEmail ? (
                <span
                  style={{
                    fontWeight: "bold",
                    color: "red",
                    marginLeft: "10px",
                    marginBottom: "15px",
                    marginTop: "8px",
                    textAlign: "left",
                  }}
                >
                  Please enter a valid email address.
                </span>
              ) : (
                <span
                  style={{
                    marginBottom: "10px",
                    textAlign: "left",
                    marginLeft: "10px",
                    marginTop: "8px",
                    fontSize: "12pt",
                    color: "rgba(18, 18, 31, 0.4)",
                  }}
                >
                  We will never share or sell your email address.
                </span>
              )}
            </Box>
            <Box
              style={{
                flexDirection: "column",
                display: "flex",
                width: "100%",
              }}
            >
              {isValidEmail && email.length > 0 && (
                <ZealInput
                  required
                  id="outlined-required"
                  label={"Confirm Email Address"}
                  value={confirmEmail}
                  placeholder="name@email.com"
                  onChange={(e) => setConfirmEmail(e.target.value)}
                  width={"100%"}
                />
              )}
              {!isEmailMatch && isValidEmail && (
                <span
                  style={{
                    fontWeight: "bold",
                    color: "red",
                    marginLeft: "10px",
                    textAlign: "left",
                    marginTop: "8px",
                  }}
                >
                  Email addresses must match. Please try again.
                </span>
              )}
            </Box>

            <Box className="phoneNumberContainer" width="100%">
              <PhoneInput
                placeholder="Your phone number. We'll send you a code."
                value={phoneNumber}
                withCountryCallingCode
                defaultCountry="US"
                onChange={setPhoneNumber}
                onCountryChange={(country) => {
                  setPhoneCountryCode(`+${getCountryCallingCode(country)}`);
                }}
                numberInputProps={{ className: "phoneNumberInput" }}
                initialValueFormat="national"
                style={{ height: "100%", margin: "2px 2px 2px 16px" }}
              />
            </Box>
            {didSendCode && (
              <>
                <ZealInput
                  value={otp}
                  onChange={(e) => setOTP(e.target.value)}
                  width={"100%"}
                  placeholder="6-digit code"
                />
              </>
            )}
            {didSendCode && (
              <p>
                Code sent to {phoneNumber}.{" "}
                <button
                  type="button"
                  onClick={resendVerification}
                  style={{
                    background: "none",
                    color: COLORS.PURPLE,
                    border: "none",
                    padding: "0",
                    font: "inherit",
                    cursor: "pointer",
                    outline: "inherit",
                  }}
                >
                  I did not receive a code
                </button>
              </p>
            )}
          </Box>
          <div id="recaptcha-container" className="justify-center flex"></div>
          {!didSendCode ? (
            !signingIn ? (
              <GradientButton
                disabled={
                  !phoneNumber ||
                  !email ||
                  !confirmEmail ||
                  !isEmailMatch ||
                  !firstName ||
                  !lastName
                }
                onClick={sendVerification}
                maxWidth="343px"
              >
                Continue
              </GradientButton>
            ) : (
              <GradientButton disabled={signingIn} maxWidth="343px">
                <em>Preparing your code{dots}</em>
              </GradientButton>
            )
          ) : !signingIn ? (
            <GradientButton
              disabled={!otp}
              onClick={validateOTP}
              maxWidth="343px"
            >
              I AGREE
            </GradientButton>
          ) : (
            <GradientButton disabled={signingIn} maxWidth="343px">
              <em>Signing you up{dots}</em>
            </GradientButton>
          )}

          {!didSendCode ? (
            <Box style={{ padding: "20px" }}>
              <Button
                className="link"
                onClick={() => continueCallback(ROUTES.LogIn)}
                style={{
                  color: "rgba(98, 6, 191, 1)",
                  fontSize: "14px",
                }}
              >
                <span>
                  Already have an account? &nbsp;
                  <strong>
                    {isFastTrack
                      ? "Sign in to confirm your RSVP."
                      : "Sign in to join the group."}
                  </strong>
                </span>
              </Button>
              <p>
                We will send a text with a verification code. Message and data
                rates may apply. By tapping continue, you are agreeing to our{" "}
                <a href={ROUTES.TermsOfService}>Terms of Service</a> and{" "}
                <a href={ROUTES.PrivacyPolicy}>Privacy Policy</a>.
              </p>
            </Box>
          ) : (
            <Box style={{ padding: "20px" }}>
              <p>
                By clicking I AGREE you agree to our{" "}
                <a href={ROUTES.TermsOfService}>Terms of Service</a> and{" "}
                <a href={ROUTES.PrivacyPolicy}>Privacy Policy</a> and consent to
                receive text notifications from us and hosts. Message and data
                rates apply. Text STOP to cancel at any time. <br />
                <a href={ROUTES.TermsOfService}>Terms of Service</a> and{" "}
                <a href={ROUTES.PrivacyPolicy}>Privacy Policy</a>
              </p>
            </Box>
          )}
        </FormCard>
      }
      <div ref={recaptchaWrapper}>
        <div id="recaptcha-container" className="justify-center flex"></div>
      </div>
    </Box>
  );
}
