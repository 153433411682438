import React from 'react';
import { Box } from '@mui/material';
import { dayValues, weekends, weekdays, ABBREVIATIONS, DAYS } from "../../constants/timeConstants"
import './index.css';

const DayPicker = ({ selectedDays, setSelectedDays, label }) => {

    const hasSelectedDays = () => {
        return selectedDays.length > 0 
    }

    const hasSelectedWeekends = () => {
        return (selectedDays.length === weekends.length && weekends.every((elem) => selectedDays.includes(elem)));
    };
    
    const hasSelectedWeekdays = () => {
        return (selectedDays.length === weekdays.length && weekdays.every((elem) => selectedDays.includes(elem)));
    };
    
    const hasSelectedDaily = () => {
        return (selectedDays.length == (weekdays.length + weekends.length));
      };

    const selectDay = (id) => {
        const dayIndex = selectedDays.indexOf(id);
        if (dayIndex === -1) {
            setSelectedDays([...selectedDays, id]);
        } else {
            setSelectedDays(selectedDays.filter((_, index) => index != dayIndex));
        }
    };

    const sortedSelectedDays = [...selectedDays];
    sortedSelectedDays.sort((a, b) => DAYS[a] - DAYS[b]);

	return (
        <Box sx={{ textAlign: 'left', margin: '8px', flexDirection: 'column' }}>
            {label && (<h4 style={{margin: 0}}>{label}</h4>)}
            <Box style={{ display: 'inline-flex', width: '80%', alignItems: 'center', margin: '14px 0px' }}>
                {dayValues.map((item, idx) => {
                    return (
                        <button key={`touchable-${idx}`} onClick={() => selectDay(item.id)} className={selectedDays.includes(item.id) ? 'pill active' : 'pill inactive'}><span style={{ textSize: '15px', fontWeight: 600 }}>{item.display}</span></button>
                    )
                })}
            </Box>
            <br />
            {label && (<span>{hasSelectedDays() ? "Available" : "No days selected"} { (hasSelectedWeekdays() && 'Weekdays') || (hasSelectedWeekends() && 'Weekends') || (hasSelectedDaily() && 'Daily') || sortedSelectedDays.map((day) => ABBREVIATIONS[day]).join(', ')}</span>)}
        </Box>
	);
};

export default DayPicker;