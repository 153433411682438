import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import { Box, Button, Container, Grid, Paper } from "@mui/material";
import React, { useEffect, useState } from "react";
import { isAndroid } from "react-device-detect";
import { useFirestore } from "react-redux-firebase";
import { useOutletContext } from "react-router-dom";
import images from "../../assets/images.js";
import GradientButton from "../../components/GradientButton";
import { MembersDisplay } from "../../components/MembersDisplay";
import OutlineButton from "../../components/OutlinedButton";
import { ROUTES } from "../../constants";
import { useFirebaseAuth } from "../../utils/contexts/FirebaseAuthContext.js";
import { eventThemes } from "../../utils/event/eventThemes";
import { MessageController } from "../../controllers/MessageController.js";
import MessageCard from "../../components/MessageCard/index.jsx";
import { constructMessage } from "../../utils/groupChatUtils/index.js";
import ChatBubbleOutlineIcon from "@mui/icons-material/ChatBubbleOutline";
import { GroupManager } from "../../controllers/GroupManager.js";
import { CometChat } from "@cometchat-pro/chat";
import { Zeal_Admin_ID, createAmplitudeEvent } from "../../utils/amplitude.js";

export default function PersonalEventLanding() {
  const firestore = useFirestore();
  const {
    eventId,
    calendarId,
    invitation,
    continueCallback,
    setHasSkip,
    setHasBack,
    setRsvpStatus,
  } = useOutletContext();

  const [messageList, setMessageList] = useState([]);

  const { user } = useFirebaseAuth();
  const uid = user?.uid;

  useEffect(() => {
    createAmplitudeEvent(Zeal_Admin_ID, "Web Event", {
      screenName: "personal_event_landing",
      calendarId: calendarId,
      eventId: eventId,
    });
  }, []);

  const userHasRSVP =
    uid &&
    invitation?.personalEventDetails?.responses &&
    Object.keys(invitation.personalEventDetails.responses).includes(uid);

  const isUserAttending =
    uid &&
    invitation?.personalEventDetails?.responses &&
    invitation.personalEventDetails.responses[uid] === "attending";

  const isUserDeclined =
    uid &&
    invitation?.personalEventDetails?.responses &&
    invitation.personalEventDetails.responses[uid] === "declined";

  const hasEventChat =
    invitation?.personalEventDetails?.cometChatGroupId &&
    invitation?.personalEventDetails?.conversationId;

  const cometChatGroupId = invitation?.personalEventDetails?.cometChatGroupId;

  const showEventChat = isUserAttending && hasEventChat;

  let dateString = "";
  let timeString = "";
  if (invitation && invitation.personalEventDetails) {
    const eventDate = new Date(
      invitation.personalEventDetails.startDateTime.seconds * 1000 +
        invitation.personalEventDetails.startDateTime.nanoseconds / 1000000
    );

    dateString = eventDate.toLocaleDateString("en-us", {
      weekday: "long",
      year: "numeric",
      month: "short",
      day: "numeric",
    });

    const endTS = new Date(
      eventDate.getTime() +
        invitation.personalEventDetails.duration * 60 * 60 * 1000
    );
    timeString = `${eventDate.toLocaleString("en-US", {
      hour: "numeric",
      minute: "numeric",
      hour12: true,
    })} - ${endTS.toLocaleString("en-US", {
      hour: "numeric",
      minute: "numeric",
      hour12: true,
    })}`;
  }

  useEffect(() => {
    setHasSkip(false);
    setHasBack(false);

    // Temporary stop gap until we how children routes can handle having a skip/back button optionally

    return () => {
      setHasBack(true);
    };
  }, []);

  const rsvpUserToPersonalEvent = async (rsvpStatus, uid) => {
    if (!rsvpStatus || rsvpStatus === "pending") return;
    if (!eventId) return;

    await firestore.runTransaction(async (transaction) => {
      const eventDocRef = await firestore
        .collection("zealCalendars")
        .doc(calendarId)
        .collection("calendarEvents")
        .doc(eventId);

      const eventDoc = await transaction.get(eventDocRef);

      if (!eventDoc.exists) return;
      const eventData = eventDoc.data();

      if (eventData?.isCanceled) return;

      const responses = eventData.responses;

      const newResponses = { ...responses, [uid]: rsvpStatus };
      transaction.set(
        eventDocRef,
        { responses: newResponses },
        { merge: true }
      );
    });
  };

  /** Fetches messages for given CometChat group and adds user if necessary to the group.
   *
   * It is assumed that the user should have already been in this personal event chat
   */

  const fetchMessages = async (cometChatGroupId, retryCount = 0) => {
    let limit = 20;
    const messageRequestInstance = MessageController.getMessageRequestInstance(
      cometChatGroupId,
      limit
    );

    try {
      const messages = await messageRequestInstance.fetchPrevious();
      setMessageList(messages);
    } catch (error) {
      if (error.code === "ERR_GROUP_NOT_JOINED" && retryCount < 3) {
        await GroupManager.joinGroup(
          cometChatGroupId,
          CometChat.GROUP_TYPE.PUBLIC,
          ""
        );
        await fetchMessages(cometChatGroupId, retryCount + 1);
      }
    }
  };

  useEffect(() => {
    if (cometChatGroupId && isUserAttending) {
      fetchMessages(cometChatGroupId);
    }
  }, [cometChatGroupId, isUserAttending]);

  return (
    invitation && (
      <Paper
        sx={{
          width: "100%",
          backgroundColor: "inherit",
          borderWidth: 0,
          justifyContent: "center",
          display: "flex",
          alignItems: "center",
          flexDirection: "column",
        }}
        square
        variant="outlined"
      >
        <Container maxWidth="lg">
          <Box
            sx={{
              flexGrow: 1,
              justifyContent: "center",
              display: "flex",
              alignItems: "center",
              mb: 2,
              flexDirection: "column",
              textAlign: "center",
            }}
          >
            {invitation.personalEventDetails &&
              invitation.personalEventDetails.eventTheme && (
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    marginTop: "16px",
                  }}
                >
                  <img
                    src={
                      eventThemes[invitation.personalEventDetails.eventTheme] ||
                      invitation.personalEventDetails.eventTheme
                    }
                    style={{
                      borderRadius: 10,
                      height: 260,
                      width: 260,
                    }}
                  />
                </Box>
              )}
            <h2
              style={{
                fontWeight: 700,
                fontSize: "18px",
                marginBottom: 0,
                marginTop: 16,
              }}
            >
              {invitation.from.split(" ")[0]} is inviting you to:
            </h2>

            {invitation && (
              <>
                <h2 style={{ fontWeight: 700, fontSize: "24px" }}>
                  {invitation.personalEventDetails.title
                    ? invitation.personalEventDetails.title
                    : "Main Activity"}
                </h2>
                <h2 style={{ fontWeight: 700, fontSize: "18px", margin: 0 }}>
                  {dateString}
                </h2>
                <h2 style={{ fontWeight: 700, fontSize: "18px", margin: 0 }}>
                  {timeString}
                </h2>
                <br></br>
                <h3 style={{ fontWeight: 600, fontSize: "24px" }}>
                  {invitation.personalEventDetails.location}
                </h3>
                {/* <h4 style={{ fontWeight: 500, fontSize: "18px" }}>
                  We're saving you a spot!
                </h4> */}
              </>
            )}

            <MembersDisplay
              members={Object.entries(invitation.responses)}
              label={"going"}
            />

            {userHasRSVP ? (
              <Box sx={{ marginBottom: "24px" }}>
                <Box
                  sx={{
                    justifyContent: "center",
                    display: "flex",
                    alignItems: "center",
                    flexDirection: "row",
                    marginTop: "32px",
                  }}
                >
                  {isUserAttending ? (
                    <>
                      <Box
                        sx={{
                          display: "flex",
                          padding: "10px",
                          justifyContent: "center",
                          alignItems: "center",
                          borderRadius: "100px",
                          color: "#FFFFFF",
                          background:
                            "linear-gradient(96.15deg, #973DF2 35.32%, #38A3A5 114.8%)",
                        }}
                      >
                        <CheckIcon size="small" />
                      </Box>
                      <h2
                        style={{
                          marginLeft: "5px",
                          fontSize: "18px",
                        }}
                      >
                        You are attending!
                      </h2>
                    </>
                  ) : (
                    isUserDeclined && (
                      <>
                        <Box
                          sx={{
                            display: "flex",
                            padding: "10px",
                            justifyContent: "center",
                            alignItems: "center",
                            borderRadius: "100px",
                            borderWidth: "1px",
                            borderColor: "#973DF2",
                          }}
                        >
                          <CloseIcon size="small" />
                        </Box>
                        <h2
                          style={{
                            marginLeft: "5px",
                            fontSize: "18px",
                          }}
                        >
                          Sorry you can't make it
                        </h2>
                      </>
                    )
                  )}
                </Box>
                {showEventChat && messageList.length !== 0 && (
                  <Box sx={{ position: "relative", pointerEvents: "none" }}>
                    <Box
                      sx={{
                        background:
                          "linear-gradient(180deg, rgba(255, 255, 255, 1), rgba(255, 255, 255, 0))",
                        position: "absolute",
                        top: -5,
                        left: 0,
                        right: 0,
                        height: 75, // Adjust the height of the gradient as needed
                        zIndex: 1,
                      }}
                    />
                    <Box
                      sx={{
                        marginTop: "26px",
                        maxHeight: 350,
                        display: "flex",
                        flexDirection: "column-reverse",
                        overflowY: "hidden",
                      }}
                    >
                      {messageList.reverse().map((item, index) => {
                        return (
                          <MessageCard
                            emojiOpen={false}
                            preview={true}
                            messageData={constructMessage(item)}
                            key={index}
                          />
                        );
                      })}
                    </Box>
                    <Grid container direction="row-reverse">
                      <Button
                        onClick={() => {}}
                        style={{
                          backgroundColor: "#F3F0F6",
                          fontSize: "16px",
                          fontWeight: 600,
                          borderRadius: "100px",
                          color: "#605D64",
                          textTransform: "none",
                          padding: "10px 16px 10px 16px",
                        }}
                        startIcon={<ChatBubbleOutlineIcon size="small" />}
                        disabled
                      >
                        Chat via the App
                      </Button>
                    </Grid>
                  </Box>
                )}
                {!showEventChat ? (
                  <h2
                    style={{
                      fontSize: "18px",
                      marginTop: "48px",
                    }}
                  >
                    See the full guest list or change your RSVP:
                  </h2>
                ) : (
                  <Box
                    sx={{
                      backgroundColor: "#ECE3F7",
                      borderRadius: "10px",
                      flexDirection: "row",
                      justifyContent: "center",
                      alignItems: "center",
                      padding: "21px 24px 21px 24px",
                      marginTop: "18px",
                    }}
                  >
                    <h4
                      style={{
                        fontSize: "16px",
                        fontWeight: 500,
                      }}
                    >
                      Download the App to chat, view the full guest list and
                      manage your RSVP
                    </h4>
                  </Box>
                )}

                <GradientButton
                  style={{ marginTop: "23px", alignSelf: "center" }}
                  onClick={() => {
                    window.location.href = isAndroid
                      ? ROUTES.PlayStore
                      : ROUTES.AppStore;
                  }}
                >
                  Download the App
                </GradientButton>
              </Box>
            ) : (
              <>
                <h2
                  style={{
                    fontWeight: 600,
                    fontSize: "18px",
                    marginBottom: 0,
                    marginTop: 32,
                  }}
                >
                  Will you be attending?
                </h2>
                <br />
                <Box
                  sx={{
                    justifyContent: "center",
                    display: "flex",
                    alignItems: "center",
                    mb: 2,
                    flexDirection: "row",
                  }}
                >
                  <OutlineButton
                    style={{
                      flex: 1,
                      margin: 5,
                      fontSize: "large",
                      minWidth: "150px",
                      fontWeight: 600,
                    }}
                    maxWidth="350px"
                    variant="outlined"
                    onClick={() => {
                      setRsvpStatus("declined");

                      if (uid) {
                        rsvpUserToPersonalEvent("declined", uid);
                      } else {
                        continueCallback(
                          ROUTES.SignUpFastPE,
                          {},
                          `/join-event/${ROUTES.SignUpFastPE}?c=${calendarId}&${
                            eventId ? `e=${eventId}` : ""
                          }`
                        );
                      }
                    }}
                  >
                    <CloseIcon size="small" />
                    Can't make it
                  </OutlineButton>
                  <GradientButton
                    style={{
                      flex: 1,
                      margin: 5,
                      fontSize: "large",
                      minWidth: "150px",
                      fontWeight: 600,
                      flexDirection: "row",
                    }}
                    maxWidth="350px"
                    onClick={() => {
                      setRsvpStatus("attending");

                      if (uid) {
                        rsvpUserToPersonalEvent("attending", uid);
                      } else {
                        continueCallback(
                          ROUTES.SignUpPE,
                          {},
                          `/join-event/${ROUTES.SignUpPE}?c=${calendarId}&${
                            eventId ? `e=${eventId}` : ""
                          }`
                        );
                      }
                    }}
                  >
                    <CheckIcon size="small" />
                    I'm going!
                  </GradientButton>
                </Box>
              </>
            )}

            {/* {isFastTrack && rsvp ? (
            <>
              
              <Button
                className="link"
                onClick={() => {
                  setRsvpStatus("pending");
                  if (userHasAccount) {
                    continueCallback(ROUTES.LogIn);
                  } else {
                    continueCallback(ROUTES.SignUp);
                  }
                }}
                style={{
                  color: "rgba(98, 6, 191, 1)",
                  fontSize: "14px",
                  fontWeight: 600,
                }}
              >
                Maybe
              </Button>
            </>
          ) : (
            <>
              {isMobile && (
                <GradientButton
                  style={{ flex: 1, margin: 5 }}
                  maxWidth="350px"
                  variant="outlined"
                  onClick={() => {
                    setOpeningApp(true);
                  }}
                >
                  {isFastTrack
                    ? "RSVP via the iOS app"
                    : "Join via the iOS app"}
                </GradientButton>
              )}
              {isMobile ? (
                <Button
                  className="link"
                  onClick={() => {
                    if (isFastTrack) setRsvp(true);
                    else continueCallback();
                  }}
                  style={{
                    color: "rgba(98, 6, 191, 1)",
                    fontSize: "14px",
                  }}
                >
                  {isFastTrack
                    ? "On Android? RSVP via web"
                    : "On Android? Join via web"}
                </Button>
              ) : (
                <OutlineButton
                  style={{ flex: 1, margin: 5, fontSize: "large" }}
                  maxWidth="350px"
                  variant="outlined"
                  onClick={() => {
                    if (isFastTrack) setRsvp(true);
                    else continueCallback();
                  }}
                >
                  {isFastTrack ? "RSVP via Web" : "Join via Web"}
                </OutlineButton>
              )}
            </>
          )} */}

            {/* DON'T SHOW FOR MVP!<br />
                  <a href='/nothanks'>No thanks, not for me</a> */}
            {/* <Button style={{width: '100%', margin: 5}} className='optionButton' variant="contained" onClick={() => navigate(`/test?group=${groupId}&invitation=${invitationId}`)}>Continue in App</Button>*/}
          </Box>
        </Container>
      </Paper>
    )
  );
}
