import { Box, Grid } from "@mui/material";
import React, { useEffect, useLayoutEffect, useState } from "react";
import styles from "./PollCard.module.css";
import checkbox from "../../../static/img/groupDashboard/polls/checkbox.svg";
import { useSelector } from "react-redux";
import { useNavigate, useOutletContext } from "react-router-dom";
import { useFirestore } from "react-redux-firebase";
import VotePoll from "../VotePolls";
import MembersDisplay from "../MemberDisplay/MemberDisplay";

const PollCard = ({ incomingData }) => {
  const navigate = useNavigate();
  const firestore = useFirestore();
  const pollMessage = incomingData?.pollObj?.question;
  const pollObj = incomingData?.pollObj;
  const [votedMembers, setVotedMembers] = useState([]);
  const [pollResults, setPollResults] = useState(null);
  const totalVote = incomingData?.pollObj?.results?.total;
  const [options, setOptions] = useState([]);
  const [memberSnipptById, setMemberSnipptById] = useState({});
  const [userVoted, setUserVoted] = useState(false);

  const pollId = incomingData?.pollObj?.id;
  const { uid } = useSelector((state) => state.firebase.auth);
  const { groupId } = useOutletContext();

  const [memberList, setMemberListId] = useState(null);

  const memberSnippets = useSelector(
    (state) => state.firestore.ordered[`memberSnippets-${groupId}`]
  );

  // Function to convert the memberSnippets array to an object keyed by id
  function convertArrayToObject(array) {
    return array.reduce((acc, member) => {
      acc[member.id.toLowerCase()] = member;
      return acc;
    }, {});
  }

  useEffect(() => {
    if (!memberSnippets) return;
    const memberSnippetsById = convertArrayToObject(memberSnippets);
    setMemberSnipptById(memberSnippetsById);
  }, [memberSnippets]);

  function extractUniqueIds(obj) {
    const uniqueIds = new Set(); // Using a Set to store unique IDs

    // Iterate over each key-value pair in the object
    for (const key in obj) {
      const value = obj[key];

      // Check if the value is an array
      if (Array.isArray(value)) {
        // Iterate over each element in the array
        for (const id of value) {
          uniqueIds.add(id); // Add the ID to the Set
        }
      }
    }

    // Convert the Set back to an array and return it
    setMemberListId(Array.from(uniqueIds));
  }

  useLayoutEffect(() => {
    firestore
      .collection("conversations")
      .doc(groupId)
      .collection("polls")
      .doc(pollObj?.id)
      .get()
      .then((res) => {
        if (res.exists) {
          // Retrieve the data from the Firestore document
          const data = res.data();
          // Update the state or perform any necessary actions
          // For example, if you have a state variable called "pollData":
          setPollResults(data);
        }
      })
      .catch((err) =>
        console.log("Error in document creation on firebase", err)
      );
  }, [incomingData]);

  useEffect(() => {
    pollResults && extractUniqueIds(pollResults?.pollOptions);
  }, [pollResults]);

  const fetchPollResultsFromFirebase = (pollResultsDocData) => {
    const tempArr = [];
    const tempVoted = [];
    const options = pollObj?.results?.options;
    let totalCount = 0;

    if (pollResultsDocData?.pollOptions) {
      Object?.values(pollResultsDocData?.pollOptions).map((item) => {
        return (totalCount += item.length);
      });

      Object.keys(options).map((key) => {
        const option = options[key];
        const formattedOption = {
          optionText: option?.text,
          votePercent: Math.round(
            ((pollResultsDocData?.pollOptions?.[key]?.length || 0) /
              totalCount) *
              100
          ),
          isUserVoted: pollResultsDocData?.pollOptions?.[key]?.includes(
            uid.toLowerCase()
          )
            ? true
            : false,
          userIds: pollResultsDocData?.pollOptions?.[key] || [],
        };
        tempArr.push(formattedOption);
        tempVoted.push(...formattedOption.userIds);
      });
    }
    setOptions([...tempArr]);
    setVotedMembers([...tempVoted]);
  };

  useEffect(() => {
    votedMembers.includes(uid.toLowerCase()) && setUserVoted(true);
  }, [votedMembers]);

  useEffect(() => {
    if (pollResults) {
      fetchPollResultsFromFirebase(pollResults);
    }
  }, [pollResults]);

  const handleClick = () => {
    const dataPayload = {
      voteOptions: options,
      incomingData: incomingData,
      memberSnipptById: memberSnipptById,
      votedMembers: votedMembers,
    };
    navigate(`/groups/${groupId}/poll-results-list`, { state: dataPayload });
  };



  return userVoted ? (
    <Grid>
      <Grid
        container
        className={styles.poll_votes}
        onClick={() => {
          handleClick();
        }}
      >
        {`Poll - ${totalVote} member${totalVote > 1 ? "s" : ""} voted`}
      </Grid>
      <p style={{ margin: "5px 0px" }}>{pollMessage}</p>
      {pollResults &&
        memberSnippets &&
        options?.map((option, index) => {
          return (
            <>
              <Grid key={index} container className={styles.poll_wrapper}>
                <Grid container>
                  <Grid item xs="auto" container style={{ minWidth: "50px" }}>
                    <p className={styles.poll_percentage}>
                      {option?.votePercent}%
                    </p>
                  </Grid>
                  <Grid item xs>
                    <p className={styles.poll_Option_Message}>
                      {option?.optionText}
                    </p>
                  </Grid>
                </Grid>
                <Grid container alignItems="center" spacing={2}>
                  <Grid item xs="auto" style={{ minWidth: "50px" }}>
                    {option?.isUserVoted && (
                      <div className={styles.checkbox}>
                        <img src={checkbox} width="7px" height="7px" />
                      </div>
                    )}
                  </Grid>
                  <Grid item xs>
                    <div className={styles.progressBarContainer}>
                      <div
                        style={{
                          width: option?.votePercent * 2,
                        }}
                        className={styles.progress_bar}
                      ></div>
                    </div>
                    <Grid container>
                      <MembersDisplay
                        size={32}
                        limit={9}
                        members={option.userIds.map((el) => {
                          return {
                            profilePicture:
                              memberSnipptById[el]?.profilePicture,
                            firstName: memberSnipptById[el]?.firstName,
                            lastName: memberSnipptById[el]?.lastName,
                          };
                        })}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </>
          );
        })}
      <Grid container flexDirection="column" style={{ paddingLeft: 80 }}>
        <span className={styles.poll_Option_Message}>Pending</span>
        <Box>
          <MembersDisplay
            size={32}
            limit={9}
            members={Object.keys(memberSnipptById)
              .filter((key) => !votedMembers.includes(key))
              .map((el) => ({
                profilePicture: memberSnipptById[el]?.profilePicture,
                firstName: memberSnipptById[el]?.firstName,
                lastName: memberSnipptById[el]?.lastName,
              }))}
          />
        </Box>
      </Grid>
    </Grid>
  ) : (
    options && (
      <VotePoll
        voteOptions={options}
        incomingData={incomingData}
        memberSnipptById={memberSnipptById}
        votedMembers={votedMembers}
        memberSnippets={memberSnippets}
      />
    )
  );
};

export default React.memo(PollCard);
