import { Box, Paper } from "@mui/material";
import React, { useEffect, useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import { MessageTypes } from "../../../utils/groupChatUtils/MessageTypes";
import "./index.css";

const Preview = ({
  previewList,
  setPreviewList,
  messageType,
  setIsSendBtnEnabled,
}) => {
  const [list, setList] = useState([]);

  useEffect(() => {
    getLocalPaths(previewList);
  }, [previewList]);

  const getLocalPaths = (previewList) => {
    if (!previewList?.length) {
      setList([]);
      return;
    }

    const previews = [];
    for (let i = 0; i < previewList?.length; i++) {
      const file = previewList[i];
      const reader = new FileReader();

      reader.onloadend = () => {
        previews.push({ name: file.name, url: reader.result });

        if (previews.length === previewList.length) {
          setList(previews);
        }
      };

      reader.readAsDataURL(file);
    }
  };

  const handleRemoveItem = (fileName) => {
    const updatedList = previewList.filter((file) => file?.name !== fileName);
    setPreviewList(updatedList);
    if (updatedList?.length == 0) setIsSendBtnEnabled(false);
  };

  return (
    <Box wrap="nowrap" className="preview-container">
      {list?.map((item, index) => {
        return (
          <div key={index + item} style={{ position: "relative" }}>
            <Paper elevation={3} className="preview-paper-container">
              {messageType === MessageTypes.IMAGE && (
                <img
                  key={index}
                  className="preview-media"
                  src={item?.url}
                  alt="group-logo"
                />
              )}
              {messageType === MessageTypes.VIDEO && (
                <video className="preview-media" preload="metadata">
                  <source src={item} type="video/mp4" />
                </video>
              )}
            </Paper>
            <div
              className="remove-btn-container"
              onClick={() => handleRemoveItem(item.name)}
            >
              <CloseIcon style={{ color: "white" }} />
            </div>
          </div>
        );
      })}
    </Box>
  );
};

export default Preview;
