import {
  Box,
  Button,
  Chip,
  CircularProgress,
  Divider,
  Grid,
  Skeleton,
  TextField,
} from "@mui/material";
import * as React from "react";
import { useState } from "react";
import { useSelector } from "react-redux";
import {
  isLoaded,
  useFirebase,
  useFirestore,
  useFirestoreConnect,
} from "react-redux-firebase";

import InfiniteScroll from "react-infinite-scroll-component";
import { useNavigate } from "react-router-dom";
import "react-virtualized/styles.css"; // only needs to be imported once
import FeedCard from "../../components/FeedCard";
import { ROUTES } from "../../constants";
import { useFirebaseAuth } from "../../utils/contexts/FirebaseAuthContext.js";
import Header from "./Header";
import PostFeedComposer from "./PostFeedComposer";
import SplashScreen from "./SplashScreen";
import styles from "./styles.module.css";

export default function DiscoverScreen({
  fetchSavedPost = false,
  selectInterest = false,
}) {
  const navigate = useNavigate();
  const { user } = useFirebaseAuth();
  const firestore = useFirestore();
  const firebase = useFirebase();
  const [sendMessageLoader, setSendMessageLoader] = useState(false);
  const interests = useSelector((state) => state.firebase.profile.interests); // grab current user info
  const [feedsPost, setFeedsPost] = useState([]);
  const [feedLoading, setFeedLoading] = useState(true);
  const [hasMorePosts, setHasMorePosts] = useState(true);
  const [editPostData, setEditPostData] = useState(null);
  const [sidebarActivePostType, setSidebarActivePostType] = useState("preview");
  const [zipCode, setZipCode] = useState("");
  const [enableFilterChip, setEnableFilterChip] = useState(false);

  const [lastSnapshot, setLastSnapshot] = useState(null);

  const [postFeedComposer, setPostFeedComposer] = useState(false);

  const uid = user.uid;

  const userInfo = useSelector((state) => state.firestore.data.userInfo);

  useFirestoreConnect([
    {
      collection: "users",
      doc: uid,
      storeAs: "userInfo",
    },
  ]);

  let pageSize = 10;

  const loadMorePosts = () => {
    if (sidebarActivePostType === "preview") {
      fetchAllPreviewPosts();
    } else if (sidebarActivePostType === "live") {
      fetchMyPostIds();
    } else if (sidebarActivePostType === "all") {
      fetchAllPostIds();
    }
  };

  const fetchPosts = async (postIds, resetLastSnapshot = false) => {
    const postCollection = firestore.collection("posts");
    try {
      const sortedPosts = await Promise.all(
        postIds.map(async (postIdObj) => {
          const postId = postIdObj?.postId; // This can be a normal post id or the reposted post id

          // Initialize repost variables
          let isRepost = false;
          let rePostTitle = null;
          let originalPostId = ""; // Id of the original post that user reposted
          let repostCreatedAt = {};
          let repostAuthorData = {};
          let repostReactions = {};
          let isReacted = postIdObj?.isReacted;
          let isSaved = postIdObj?.isSaved;
          try {
            if (postIdObj?.isRepost) {
              // if post is reposted
              isRepost = postIdObj?.isRepost;
              originalPostId = postIdObj?.originalPostId;
              repostCreatedAt = postIdObj?.createdAt;
              const postDoc = await postCollection.doc(postId).get();

              if (postDoc.exists) {
                const postData = postDoc.data();
                const authorId = postData.authorId;
                const repostAuthorDoc = await firestore
                  .collection("users")
                  .doc(authorId)
                  .get();
                if (repostAuthorDoc.exists) {
                  const authorData = repostAuthorDoc.data();
                  const { profilePicture, firstName, lastName } = authorData;
                  repostAuthorData = {
                    profilePicture,
                    firstName,
                    lastName,
                    authorId,
                  };
                  repostReactions = postData?.reactions;
                  rePostTitle = postData?.rePostTitle;
                }
              }
            }

            const postDoc = await postCollection
              .doc(isRepost ? originalPostId : postId)
              .get();
            if (postDoc.exists) {
              const postData = postDoc.data();
              // Fetch author's data using authorId
              const authorId = postData.authorId;
              const authorDoc = await firestore
                .collection("users")
                .doc(authorId)
                .get();
              if (authorDoc.exists) {
                const authorData = authorDoc.data();

                // Extract only the required fields from the author's data
                const { profilePicture, firstName, lastName, isAdmin } =
                  authorData;

                // Combine post data and required author data into a single object
                const combinedData = {
                  ...postData,
                  authorData: {
                    profilePicture,
                    firstName,
                    lastName,
                    isAdmin,
                  },
                  originalPostId,
                  isRepost,
                  rePostTitle,
                  postId,
                  repostReactions,
                  repostAuthorData,
                  repostCreatedAt,
                  isReacted,
                  isSaved,
                };

                return combinedData;
              }
            } else if (isRepost && !postDoc.exists) {
              const combinedData = {
                originalPostDoesNotExist: true,
                originalPostId,
                isRepost,
                rePostTitle,
                postId,
                repostReactions,
                repostAuthorData,
                repostCreatedAt,
                isReacted,
              };

              return combinedData;
            }
          } catch (error) {
            console.error(`Error fetching post ${postId}:`, error);
            return null; // Return null for posts that encountered an error
          }
        })
      );

      const validPosts = sortedPosts.filter((post) => !!post); // Remove posts with errors
      if (lastSnapshot && !resetLastSnapshot) {
        setFeedsPost((prev) => [...prev, ...validPosts]);
      } else setFeedsPost(validPosts);
      setFeedLoading(false);
    } catch (error) {
      console.error("Error fetching posts:", error);
    }
  };

  const fetchAllPreviewPosts = async (
    resetLastSnapshot = false,
    filterZipCode
  ) => {
    if (resetLastSnapshot) {
      setFeedLoading(true);
    }
    const previewPostsCollection = firestore.collection("previewPosts");
    try {
      let query;
      query = previewPostsCollection
        .orderBy("createdAt", "desc")
        .limit(pageSize);
      if (filterZipCode) {
        query = previewPostsCollection
          .orderBy("createdAt", "desc")
          .where("metadata.event.zipCode", "==", zipCode)
          .limit(pageSize);
      }

      if (lastSnapshot && !resetLastSnapshot) {
        query = query.startAfter(lastSnapshot);
      }

      const querySnapshot = await query.get();
      const postDocs = querySnapshot.docs;

      const sortedPosts = await Promise.all(
        postDocs.map(async (doc) => {
          const postData = doc.data();
          const postId = doc.id;

          // Initialize repost variables
          let isRepost = postData.isRepost || false;
          let rePostTitle = null;
          let originalPostId = postData.originalPostId || "";
          let repostCreatedAt = {};
          let repostAuthorData = {};
          let repostReactions = {};
          let isReacted = postData.isReacted || false;
          let isSaved = postData.isSaved || false;

          try {
            if (isRepost) {
              repostCreatedAt = postData.createdAt;
              const repostDoc = await previewPostsCollection
                .doc(originalPostId)
                .get();

              if (repostDoc.exists) {
                const repostData = repostDoc.data();
                const authorId = repostData.authorId;
                const repostAuthorDoc = await firestore
                  .collection("users")
                  .doc(authorId)
                  .get();

                if (repostAuthorDoc.exists) {
                  const authorData = repostAuthorDoc.data();
                  const { profilePicture, firstName, lastName } = authorData;
                  repostAuthorData = {
                    profilePicture,
                    firstName,
                    lastName,
                    authorId,
                  };
                  repostReactions = repostData.reactions;
                  rePostTitle = repostData.rePostTitle;
                }
              }
            }

            const postDoc = await previewPostsCollection
              .doc(isRepost ? originalPostId : postId)
              .get();
            if (postDoc.exists) {
              const postData = postDoc.data();
              const authorId = postData.authorId;
              const authorDoc = await firestore
                .collection("users")
                .doc(authorId)
                .get();

              if (authorDoc.exists) {
                const authorData = authorDoc.data();
                const { profilePicture, firstName, lastName, isAdmin } =
                  authorData;

                const combinedData = {
                  ...postData,
                  authorData: {
                    profilePicture,
                    firstName,
                    lastName,
                    isAdmin,
                  },
                  originalPostId,
                  isRepost,
                  rePostTitle,
                  postId,
                  repostReactions,
                  repostAuthorData,
                  repostCreatedAt,
                  isReacted,
                  isSaved,
                };

                return combinedData;
              }
            } else if (isRepost && !postDoc.exists) {
              const combinedData = {
                originalPostDoesNotExist: true,
                originalPostId,
                isRepost,
                rePostTitle,
                postId,
                repostReactions,
                repostAuthorData,
                repostCreatedAt,
                isReacted,
              };

              return combinedData;
            }
          } catch (error) {
            console.error(`Error fetching post ${postId}:`, error);
            return null; // Return null for posts that encountered an error
          }
        })
      );

      const validPosts = sortedPosts.filter((post) => !!post); // Remove posts with errors
      if (lastSnapshot && !resetLastSnapshot) {
        setFeedsPost((prev) => [...prev, ...validPosts]);
      } else setFeedsPost(validPosts);
      setFeedLoading(false);
    } catch (error) {
      console.error("Error fetching preview posts:", error);
    }
  };

  // Fetch post IDs from the user's collection
  const fetchMyPostIds = async (resetLastSnapshot = false) => {
    if (resetLastSnapshot) {
      setFeedLoading(true);
    }
    // contextDispatch(setIsNewFeedMsg(false));

    let userPostsIdRef = null;
    let subCollection = fetchSavedPost ? "savedPosts" : "feedItems";

    if (resetLastSnapshot || !lastSnapshot) {
      // Not using interests filtering currently
      // if (selectInterest) {
      //   userPostsIdRef = firestore
      //     .collection(`userPosts/${uid}/${subCollection}`)
      //     .where("interestId", "array-contains", interests)
      //     .where("authorId", "==", uid)
      //     .orderBy("createdAt", "desc")
      //     .limit(pageSize);
      // } else {
      // Query the first page of docs
      userPostsIdRef = firestore
        .collection(`userPosts/${uid}/${subCollection}`)
        .where("authorId", "==", uid)
        .orderBy("createdAt", "desc")
        .limit(pageSize);
      // }
    } else if (!resetLastSnapshot && lastSnapshot) {
      if (selectInterest) {
        userPostsIdRef = firestore
          .collection(`userPosts/${uid}/${subCollection}`)
          .where("interestId", "array-contains", selectInterest)
          .where("authorId", "==", uid)
          .orderBy("createdAt", "desc")
          .startAfter(lastSnapshot)
          .limit(10);
      } else {
        userPostsIdRef = firestore
          .collection(`userPosts/${uid}/${subCollection}`)
          .where("authorId", "==", uid)
          .orderBy("createdAt", "desc")
          .startAfter(lastSnapshot)
          .limit(10);
      }
    }

    try {
      const querySnapshot = await userPostsIdRef.get();
      const postIds = querySnapshot.docs.map((doc) => doc.data());
      // markNewPostFalse();
      if (querySnapshot.docs.length > 0) {
        const lastDoc = querySnapshot.docs[querySnapshot.docs.length - 1];
        setLastSnapshot(() => lastDoc);
        fetchPosts(postIds, resetLastSnapshot);
      } else if (querySnapshot.docs.length === 0 && !lastSnapshot) {
        if (resetLastSnapshot) {
          setFeedsPost([]);
        }
        setFeedLoading(false);
        setHasMorePosts(false);
      } else if (querySnapshot.docs.length === 0 && resetLastSnapshot) {
        setFeedsPost([]);
        setFeedLoading(false);
      } else {
        setHasMorePosts(false);
      }
    } catch (error) {
      console.error("Error fetching post IDs:", error);
    }
  };

  const fetchAllPostIds = async (resetLastSnapshot = false) => {
    if (resetLastSnapshot) {
      setFeedLoading(true);
    }
    // contextDispatch(setIsNewFeedMsg(false));

    let userPostsIdRef = null;
    let subCollection = fetchSavedPost ? "savedPosts" : "feedItems";
    if (!lastSnapshot || resetLastSnapshot) {
      // Not using interestr filtering currently
      // if (selectInterest) {
      //   userPostsIdRef = firestore
      //     .collection(`userPosts/${uid}/${subCollection}`)
      //     .where("interestId", "array-contains", interests)
      //     .orderBy("createdAt", "desc")
      //     .limit(pageSize);
      // } else {
      // Query the first page of docs
      userPostsIdRef = firestore
        .collection(`userPosts/${uid}/${subCollection}`)
        .orderBy("createdAt", "desc")
        .limit(pageSize);
    } else if (!resetLastSnapshot && lastSnapshot) {
      if (selectInterest) {
        userPostsIdRef = firestore
          .collection(`userPosts/${uid}/${subCollection}`)
          .where("interestId", "array-contains", selectInterest)
          .orderBy("createdAt", "desc")
          .startAfter(lastSnapshot)
          .limit(10);
      } else {
        userPostsIdRef = firestore
          .collection(`userPosts/${uid}/${subCollection}`)
          .orderBy("createdAt", "desc")
          .startAfter(lastSnapshot)
          .limit(10);
      }
    }

    try {
      const querySnapshot = await userPostsIdRef.get();
      const postIds = querySnapshot.docs.map((doc) => doc.data());
      // markNewPostFalse();
      if (querySnapshot.docs.length > 0) {
        const lastDoc = querySnapshot.docs[querySnapshot.docs.length - 1];
        setLastSnapshot(() => lastDoc);
        fetchPosts(postIds, resetLastSnapshot);
      } else if (querySnapshot.docs.length === 0 && !lastSnapshot) {
        if (resetLastSnapshot) {
          setFeedsPost([]);
        }
        setFeedLoading(false);
        setHasMorePosts(false);
      } else if (querySnapshot.docs.length === 0 && resetLastSnapshot) {
        setFeedsPost([]);
        setFeedLoading(false);
      } else {
        setHasMorePosts(false);
      }
    } catch (error) {
      console.error("Error fetching post IDs:", error);
    }
  };

  React.useEffect(() => {
    if (
      ![
        "da85TnTPqOd0UjXCVxBrIG4IC243",
        "VztuLYDSqxgr59PvwrX7KQfg1Q12",
      ].includes(uid)
    ) {
      navigate(`/${ROUTES.AdminLogIn}`);
    } else {
      if (sidebarActivePostType === "preview") {
        fetchAllPreviewPosts(true);
      } else if (sidebarActivePostType === "live") {
        fetchMyPostIds(true);
      } else if (sidebarActivePostType === "all") {
        fetchAllPostIds(true);
      }
    }
  }, [uid, sidebarActivePostType]);

  const [showFlashScreen, setShowSplashScreen] = useState(true);

  if (showFlashScreen) {
    return <SplashScreen setShowSplashScreen={setShowSplashScreen} />;
  }

  const SideBar = () => {
    return (
      <>
        <Grid
          onClick={() => setSidebarActivePostType("all")}
          container
          className={[
            styles.sideBarButton,
            sidebarActivePostType === "all" && styles.sideBarButtonActive,
          ]}
        >
          Live Posts (All)
        </Grid>
        <Grid container style={{ margin: "0.5rem 0px" }}>
          <Divider className={styles.divider} />
        </Grid>
        <Grid
          onClick={() => setSidebarActivePostType("live")}
          container
          className={[
            styles.sideBarButton,
            sidebarActivePostType === "live" && styles.sideBarButtonActive,
          ]}
        >
          Live Posts (Admin Only)
        </Grid>
        <Grid container style={{ margin: "0.5rem 0px" }}>
          <Divider className={styles.divider} />
        </Grid>
        <Grid
          onClick={() => setSidebarActivePostType("preview")}
          container
          className={[
            styles.sideBarButton,
            sidebarActivePostType === "preview" && styles.sideBarButtonActive,
          ]}
        >
          Preview Posts
        </Grid>
      </>
    );
  };

  const handleApplyFilter = (event) => {
    setEnableFilterChip(true);
    fetchAllPreviewPosts(true, true);
  };

  const handleCancelFilter = () => {
    setZipCode("");
    setEnableFilterChip(false);
    fetchAllPreviewPosts(true, false);
  };

  const handleInputChange = (event) => {
    setZipCode(event.target.value);
  };

  return (
    isLoaded(userInfo) && (
      <Box
        alignItems="center"
        justifyContent="center"
        display="flex"
        flexDirection="column"
        // paddingTop="24px"
        width="100%"
        style={{ backgroundColor: "#fff" }}
      >
        <Header />
        <>
          <Grid
            container
            justifyContent="center"
            style={{ backgroundColor: "#ffffff", minHeight: "100vh" }}
          >
            <Grid item xs={2} className={styles.sideBar}>
              <SideBar />
            </Grid>
            <Grid item xs={2}></Grid>
            <Grid item xs={4} justifyContent="center">
              {sidebarActivePostType === "preview" && (
                <Grid container>
                  {enableFilterChip ? (
                    <Grid container className={styles.filterContainer}>
                      <Chip
                        label={`Zip code:  ${zipCode}`}
                        variant="outlined"
                        onDelete={handleCancelFilter}
                      />
                    </Grid>
                  ) : (
                    <Grid container className={styles.filterContainer}>
                      <Grid
                        container
                        style={{ marginBottom: "1rem", fontWeight: "bold" }}
                      >
                        Showing Results For
                      </Grid>
                      <Grid
                        container
                        justifyContent="space-between"
                        alignItems="center"
                      >
                        <Grid item xs={3}>
                          <TextField
                            label="Zip Code"
                            variant="outlined"
                            type="number"
                            value={zipCode}
                            fullWidth
                            onChange={handleInputChange}
                          />
                        </Grid>
                        <Grid item xs="auto">
                          {" "}
                          <Button
                            variant="contained"
                            color="primary"
                            onClick={handleApplyFilter}
                          >
                            Apply
                          </Button>
                        </Grid>
                      </Grid>
                    </Grid>
                  )}
                </Grid>
              )}

              {feedLoading ? (
                [1, 2, 3, 4, 5, 6].map(() => {
                  return (
                    <Grid
                      container
                      style={{
                        height: "200px",
                        width: "100%",
                        margin: "2rem 0px",
                      }}
                    >
                      <Skeleton
                        style={{
                          height: "20px",
                          width: "100%",
                          margin: "1rem 0px",
                        }}
                      />
                      <Skeleton
                        style={{
                          height: "20px",
                          width: "100%",
                          margin: "1rem 0px",
                        }}
                      />
                      <Skeleton
                        style={{
                          height: "20px",
                          width: "100%",
                          margin: "1rem 0px",
                        }}
                      />
                      <Skeleton
                        style={{
                          height: "20px",
                          width: "100%",
                          margin: "1rem 0px",
                        }}
                      />
                    </Grid>
                  );
                })
              ) : feedsPost?.length ? (
                <InfiniteScroll
                  dataLength={feedsPost?.length}
                  next={loadMorePosts}
                  hasMore={hasMorePosts}
                  loader={<CircularProgress color="secondary" />}
                  scrollableTarget="scrollableDiv"
                  endMessage={
                    <p style={{ textAlign: "center" }}>
                      <b>Yay! You have seen it all</b>
                    </p>
                  }
                >
                  {feedsPost?.length &&
                    feedsPost.map((item, index) => {
                      return (
                        <React.Fragment key={index}>
                          <FeedCard
                            item={item}
                            setEditPostData={setEditPostData}
                            sidebarActivePostType={sidebarActivePostType}
                          />
                        </React.Fragment>
                      );
                    })}
                </InfiniteScroll>
              ) : (
                <Grid
                  container
                  justifyContent="center"
                  style={{
                    height: "100vh",
                    paddingTop: "2rem",
                    fontSize: "2rem",
                  }}
                >
                  No Post Found
                </Grid>
              )}
            </Grid>
            <Grid item xs={4}></Grid>
            <Grid
              container
              justifyContent="center"
              item
              xs={4}
              style={{
                position: "fixed",
                height: "100vh",
                zIndex: 200,
                right: 0,
                borderLeft: "1px solid gray",
                overflowY: "scroll",
                paddingBottom: "10rem",
              }}
            >
              <PostFeedComposer
                postFeedComposer={postFeedComposer}
                editPostData={editPostData}
                setFeedsPost={setFeedsPost}
                setEditPostData={setEditPostData}
                setPostFeedComposer={setPostFeedComposer}
                sidebarActivePostType={sidebarActivePostType}
              />
            </Grid>
          </Grid>
        </>
      </Box>
    )
  );
}
