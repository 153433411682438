import * as React from "react";
import "./index.css";
import { Box, TextField } from "@mui/material";
import { useState, useEffect, useRef } from "react";
import firebase from "../../services/firebaseConfig";
import { useFirestore } from "react-redux-firebase";

import FormCard from "../../components/FormCard";

import { useOutletContext } from "react-router-dom";

import GradientButton from "../../components/GradientButton";

import { ROUTES } from "../../constants";

import { COLORS } from "../../constants";

import { useNavigate } from "react-router-dom";

import "react-phone-number-input/style.css";
import PhoneInput, { parsePhoneNumber } from "react-phone-number-input";
import { CometChat } from "@cometchat-pro/chat";
import {
  INVITATION_STATUS,
  addInvitationToUser,
  claimOrDeclineInvitations,
} from "../../utils/helpers/invitations";
import ZealInput from "../../components/ZealInput";

export default function OF3LogIn() {
  const {
    groupId,
    group,
    invitationId,
    invitation,
    userId,
    rsvpStatus,
    setUserId,
    continueCallback,
    isFastTrack,
    eventId,
  } = useOutletContext();

  const [didSendCode, setDidSendCode] = useState(false);

  const firestore = useFirestore();

  const recaptchaWrapper = useRef();

  const [phoneNumber, setPhoneNumber] = useState("");
  const [phoneNumberStatic, setPhoneNumberStatic] = useState("");
  const [showErrorMessage, setShowErrorMessage] = useState(false);

  const [otp, setOTP] = useState("");
  const [final, setFinal] = useState("");
  const [verify, setVerify] = useState(null);
  const [signingIn, setSigningIn] = useState(false);
  const [dots, setDots] = useState("");

  const navigate = useNavigate();

  useEffect(() => {
    if (signingIn) {
      const start = new Date();
      const interval = setInterval(() => {
        setDots(
          Array(1 + (Math.round((new Date() - start) / 700) % 4)).join(".")
        );
      }, 700);
      return () => clearInterval(interval);
    }
  }, [signingIn]);

  const sendVerification = async () => {
    setSigningIn(true);
    let returnError = false;
    setPhoneNumberStatic(parsePhoneNumber(phoneNumber).nationalNumber);
    await firestore
      .collection("users")
      .where("phoneNumber", "==", parsePhoneNumber(phoneNumber).nationalNumber)
      .get()
      .then((querySnapshot) => {
        //check if this number exists in our database
        if (querySnapshot.docs.length > 0) {
          setShowErrorMessage(false);
        } else {
          setShowErrorMessage(true);
          setSigningIn(false);
          returnError = true;
        }
      })
      .catch((error) => {
        console.error("Failed to query user with exception:", error);
      });
    if (
      phoneNumber === "" ||
      parsePhoneNumber(phoneNumber).nationalNumber.length < 10 ||
      returnError
    )
      return;
    firebase
      .auth()
      .signInWithPhoneNumber(phoneNumber, verify)
      .then((result) => {
        setFinal(result);
        setSigningIn(false);
        setDidSendCode(true);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const rsvpUserToFastEvent = async ({ result }) => {
    if (!rsvpStatus || rsvpStatus === "pending" || rsvpStatus === "declined")
      return;
    if (!eventId) return;
    await firestore.runTransaction(async (transaction) => {
      const eventDocRef = await firestore
        .collection("groups")
        .doc(groupId)
        .collection("events")
        .doc(eventId);

      const eventDoc = await transaction.get(eventDocRef);

      if (!eventDoc.exists) return;
      const eventData = eventDoc.data();
      if (eventData?.isCanceled) return;
      const responses = eventData.responses;

      const newResponses = { ...responses, [result.user.uid]: rsvpStatus };
      transaction.set(
        eventDocRef,
        { responses: newResponses },
        { merge: true }
      );
    });
  };

  const addUserToCometChatGroupAndClaimInvite = async (uid) => {
    let groupType = CometChat.GROUP_TYPE.PUBLIC;

    CometChat.joinGroup(groupId, groupType, "").then(
      (group) => {
        console.log("Group joined successfully:", group);
      },
      (error) => {
        console.log("Group joining failed with exception:", error);
      }
    );

    await claimOrDeclineInvitations(
      firestore,
      uid,
      groupId,
      INVITATION_STATUS.claimed,
      invitationId
    );
  };

  const rsvpToInvitation = async (uid, groupId, invitationId) => {
    if (isFastTrack) {
      if (rsvpStatus === "attending") {
        await addUserToCometChatGroupAndClaimInvite(uid);
      } else if (rsvpStatus === "pending") {
        await claimOrDeclineInvitations(
          firestore,
          uid,
          groupId,
          INVITATION_STATUS.tentative,
          invitationId
        );
        await addInvitationToUser(
          firestore,
          uid,
          groupId,
          invitation.from.id,
          invitation.from.firstName,
          INVITATION_STATUS.tentative,
          eventId
        );
      } else {
        await claimOrDeclineInvitations(
          firestore,
          uid,
          groupId,
          INVITATION_STATUS.declined,
          invitationId
        );
      }
    } else {
      await addUserToCometChatGroupAndClaimInvite(uid);
    }
  };

  const validateOTP = () => {
    if (otp === null || final === null) {
      verify.reset();
      return;
    } else if (otp === "") {
      return;
    }
    setSigningIn(true);
    final
      .confirm(otp)
      .then(async (result) => {
        setUserId(result.user.uid);

        const uid = result.user.uid;

        // check if user exists?
        // add user as member of group

        const createAuthToken = firebase
          .functions()
          .httpsCallable("createCometChatAuthToken");

        let groupType = CometChat.GROUP_TYPE.PUBLIC;

        if (!isFastTrack) {
          // add user as member of group
          await firestore
            .collection("groups")
            .doc(groupId)
            .update({
              members: firebase.firestore.FieldValue.arrayUnion(uid),
            });
        } else {
          if (rsvpStatus === "attending") {
            await firestore
              .collection("groups")
              .doc(groupId)
              .update({
                members: firebase.firestore.FieldValue.arrayUnion(uid),
              });
            rsvpUserToFastEvent(uid);
          }
        }

        createAuthToken().then((res) => {
          const authToken = res.data;
          CometChat.login(authToken).then(
            (user) => {
              console.log("CometChat Login Successful:", { user });
              rsvpToInvitation(uid, groupId, invitationId);
            },
            (error) => {
              CometChat.login(authToken).then(
                (user) => {
                  console.log("CometChat Login Successful:", { user });

                  rsvpToInvitation(uid, groupId, invitationId);
                },
                (error) => {
                  console.log("CometChat Login failed with exception:", {
                    error,
                  });
                }
              );
            }
          );
        });

        setUserId(result.user.uid);

        // update invitation status
        if (!isFastTrack) {
          firestore
            .collection("groups")
            .doc(groupId)
            .collection("invitations")
            .doc(invitationId)
            .update({
              status: "claimed",
            });
        }

        await firestore
          .collection("groups")
          .doc(groupId)
          .get()
          .then((doc) => {
            const { hasSchedule, hasGroup, members, groupSize, plusOnes } =
              doc.data();
            const maxGroupSize = parseInt(groupSize.split("-")[1]);
            if (isFastTrack) {
              if (rsvpStatus === "declined") {
                continueCallback(ROUTES.AppDownload);
              } else {
                if (!hasSchedule) {
                  continueCallback(ROUTES.Calendar);
                } else if (plusOnes && parseInt(plusOnes) > 0) {
                  continueCallback(ROUTES.InviteFriends);
                } else {
                  continueCallback(ROUTES.AppDownload);
                }
              }
            } else if (
              !hasSchedule &&
              !hasGroup &&
              members.length < maxGroupSize
            ) {
              continueCallback(ROUTES.InviteFriends);
            } else {
              setTimeout(() => {
                navigate(`/user`);
              }, "1000");
            }
          });
      })
      .catch((err) => {
        console.log(err);
        setSigningIn(false);
        alert("Wrong code. Please enter again.");
      });
  };

  const resendVerification = () => {
    if (verify && recaptchaWrapper) {
      verify.clear();
      recaptchaWrapper.current.innerHTML = `<div id="recaptcha-container" className="justify-center flex"></div>`;
    }

    setVerify(
      new firebase.auth.RecaptchaVerifier("recaptcha-container", {
        size: "invisible",
      })
    );
  };

  useEffect(() => {
    setVerify(
      new firebase.auth.RecaptchaVerifier("recaptcha-container", {
        size: "invisible",
      })
    );
  }, []);

  useEffect(() => {
    if (didSendCode) {
      sendVerification();
    }
  }, [verify]);

  return (
    <Box alignItems="center" justifyContent="center">
      <Box alignItems="center" justifyContent="center">
        <FormCard onSubmit={sendVerification}>
          <h1>
            {isFastTrack && invitation?.from?.firstName
              ? rsvpStatus === "attending"
                ? `Let ${invitation.from.firstName} know you're going`
                : rsvpStatus === "pending"
                ? `Let ${invitation.from.firstName} know you're a "Maybe"`
                : rsvpStatus === "declined"
                ? `Let ${invitation.from.firstName} know you can't make it`
                : "Lock in your RSVP"
              : "Welcome Back!"}
          </h1>
          <h3>Sign in to Zeal with your phone number.</h3>
          {/* <Box style={{ padding: "10px" }}>
              <PhoneInput
                placeholder="(111)123-4567"
                value={phoneNumber}
                withCountryCallingCode
                defaultCountry="US"
                onChange={setPhoneNumber}
              />
            </Box> */}
          <Box className="formContainer">
            {" "}
            <Box className="phoneNumberContainer" width="100%">
              <PhoneInput
                placeholder="Your phone number. We'll send you a code."
                value={phoneNumber}
                withCountryCallingCode
                defaultCountry="US"
                onChange={setPhoneNumber}
                numberInputProps={{ className: "phoneNumberInput" }}
                initialValueFormat="national"
                style={{ height: "100%", margin: "2px 2px 2px 16px" }}
              />
            </Box>
            {didSendCode && (
              <>
                <ZealInput
                  value={otp}
                  onChange={(e) => setOTP(e.target.value)}
                  width={"100%"}
                  placeholder="6-digit code"
                />
              </>
            )}
            {didSendCode && (
              <p>
                Code sent to {phoneNumber}.{" "}
                <button
                  type="button"
                  onClick={resendVerification}
                  style={{
                    background: "none",
                    color: COLORS.PURPLE,
                    border: "none",
                    padding: "0",
                    font: "inherit",
                    cursor: "pointer",
                    outline: "inherit",
                  }}
                >
                  I did not receive a code
                </button>
              </p>
            )}
            {showErrorMessage && (
              <span style={{ marginBottom: "1rem" }} className="text-warning">
                We didn’t recognize {`${phoneNumber}`}. Please try again or
                &nbsp;
                <a onClick={() => continueCallback(ROUTES.SignUp)}>Sign Up</a>
                &nbsp;for Zeal.
              </span>
            )}
          </Box>

          {!didSendCode ? (
            !signingIn ? (
              <GradientButton
                disabled={!phoneNumber}
                onClick={sendVerification}
                maxWidth="343px"
              >
                Next
              </GradientButton>
            ) : (
              <GradientButton disabled={signingIn} maxWidth="343px">
                <em>Preparing your code{dots}</em>
              </GradientButton>
            )
          ) : !signingIn ? (
            <GradientButton
              disabled={!otp}
              onClick={validateOTP}
              maxWidth="343px"
            >
              Confirm
            </GradientButton>
          ) : (
            <GradientButton disabled={signingIn} maxWidth="343px">
              <em>Signing you up{dots}</em>
            </GradientButton>
          )}

          {!didSendCode ? (
            <Box style={{ padding: "20px" }}>
              <p>
                We will send a text with a verification code. Message and data
                rates may apply. By tapping continue, you are agreeing to our{" "}
                <a href={ROUTES.TermsOfService}>Terms of Service</a> and{" "}
                <a href={ROUTES.PrivacyPolicy}>Privacy Policy</a>.
              </p>
            </Box>
          ) : (
            ""
          )}
        </FormCard>
      </Box>

      <div ref={recaptchaWrapper}>
        <div id="recaptcha-container" className="justify-center flex"></div>
      </div>
    </Box>
  );
}
