import styled from "@emotion/styled";
import GroupsIcon from "@mui/icons-material/Groups";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import SendIcon from "@mui/icons-material/Send";
import {
  Button,
  FormControlLabel,
  Grid,
  IconButton,
  InputBase,
  Menu,
  MenuItem,
  Switch,
} from "@mui/material";
import React from "react";
import images from "../../../assets/images";
import styles from "./styles.module.css";

const PostComposer = ({
  messageTitle,
  setMessageTitle,
  setCharCount,
  fetchLinkPreview,
  charCount,
  setSelectedMedia,
  setToggleEventSwitch,
  toggleEventSwitch,
  editPostData = false,
  uploadImageInputRef
}) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleImageChange = (event) => {
    const file = event.target.files[0];
    // Check if the selected file is an image
    if (file) {
      setSelectedMedia((prev) => [file]);
    }
  };

  const IOSSwitch = styled((props) => (
    <Switch
      focusVisibleClassName=".Mui-focusVisible"
      disableRipple
      {...props}
    />
  ))(({ theme }) => ({
    width: 30, // Adjusted width
    height: 18, // Adjusted height
    padding: 0,
    marginRight: 10,
    "& .MuiSwitch-switchBase": {
      padding: 0,
      margin: 1, // Adjusted margin
      transitionDuration: "300ms",
      "&.Mui-checked": {
        transform: "translateX(12px)", // Adjusted transform
        color: "#fff",
        "& + .MuiSwitch-track": {
          backgroundColor:
            theme.palette.mode === "dark" ? "#6206bf" : "#6206bf",
          opacity: 1,
          border: 0,
        },
        "&.Mui-disabled + .MuiSwitch-track": {
          opacity: 0.5,
        },
      },
      "&.Mui-focusVisible .MuiSwitch-thumb": {
        color: "#6206bf",
        border: "4px solid #fff", // Adjusted border
      },
      "&.Mui-disabled .MuiSwitch-thumb": {
        color:
          theme.palette.mode === "light"
            ? theme.palette.grey[100]
            : theme.palette.grey[600],
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
      },
    },
    "& .MuiSwitch-thumb": {
      boxSizing: "border-box",
      width: 16, // Adjusted width
      height: 16, // Adjusted height
    },
    "& .MuiSwitch-track": {
      borderRadius: 20 / 2, // Adjusted borderRadius
      backgroundColor: theme.palette.mode === "light" ? "#E9E9EA" : "#39393D",
      opacity: 1,
      transition: theme.transitions.create(["background-color"], {
        duration: 500,
      }),
    },
  }));

  return (
    <Grid container className={styles.postComposerContainer}>
      <Grid container justifyContent="space-between">
        <Grid item xs>
          <h2>{editPostData ? "Update Post" : "Create Post"} </h2>
        </Grid>
        <Grid item xs="auto">
          <Grid container justifySelf="flex-end">
            <Button
              id="basic-button"
              aria-controls={open ? "basic-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={open ? "true" : undefined}
              onClick={handleClick}
              variant="text"
              sx={styles.menuButton}
              style={{ color: "#000000", fontSize: "0.8rem" }}
              startIcon={<GroupsIcon />}
              endIcon={<KeyboardArrowDownIcon />}
            >
              Friends of Friends
            </Button>
            <Menu
              id="basic-menu"
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              MenuListProps={{
                "aria-labelledby": "basic-button",
              }}
            >
              <MenuItem onClick={handleClose}>Friends</MenuItem>
              <MenuItem onClick={handleClose}>Friends of Friends</MenuItem>
            </Menu>
          </Grid>
        </Grid>
      </Grid>

      <Grid container className={styles.postComposerInnerContainer}>
        <Grid item xs="auto" style={{ padding: "0.7rem" }}>
          <img
            src={images.zealProfile}
            alt="group-logo"
            className={styles.zealProfile}
          />
        </Grid>
        <Grid item xs>
          <InputBase
            multiline
            rows={2}
            maxRows={5}
            focused
            value={messageTitle} // Use the state for input value
            label="Type a message"
            onChange={(e) => {
              const value = e.target.value;
              setMessageTitle(value);
              setCharCount(value.length);

              // Call the fetchLinkPreview function
              fetchLinkPreview(value);
            }}
            style={{ width: "100%" }}
            placeholder="Share your zeal for this activity interest and inspire others to get active!"
          />
          <Grid
            container
            justifyContent="flex-end"
            className={styles.charCount}
          >
            {charCount} / 1000
          </Grid>
        </Grid>
      </Grid>

      <Grid
        container
        justifyContent="space-between"
        alignItems="center"
        style={{ paddingTop: "0.5rem" }}
      >
        <Grid container item xs gap={3}>
          <Grid item>
            <input ref={uploadImageInputRef} type="file" onChange={(e) => handleImageChange(e)} />
            {/* <Grid container gap={0.5} alignItems="center">
                <Grid item xs="auto">
                  <ImageIcon style={{ width: "1rem" }} />
                </Grid>
                <Grid xs className={styles.optionName}>
                  Image
                </Grid>
              </Grid>
            </input> */}
          </Grid>
          <Grid item>
            <FormControlLabel
              checked={toggleEventSwitch}
              onChange={() => setToggleEventSwitch((prev) => !prev)}
              control={<IOSSwitch defaultChecked />}
              label="Event"
            />
          </Grid>
        </Grid>

        {/* <Grid item xs="auto">
          <IconButton variant="contained">
            <SendIcon
              color="primary"
              sx={{
                color: "#6206bf",
              }}
            />
          </IconButton>
        </Grid> */}
      </Grid>
    </Grid>
  );
};

export default PostComposer;
