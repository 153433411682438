import { Box, Grid, IconButton, Skeleton } from "@mui/material";
import * as React from "react";
import { useState } from "react";
import { useSelector } from "react-redux";
import {
  useFirebase,
  useFirestore,
  useFirestoreConnect,
} from "react-redux-firebase";

import EditIcon from "@mui/icons-material/Edit";
import moment from "moment";
import { useEffect } from "react";
import { ReactImageCarouselViewer } from "react-image-carousel-viewer";
import Carousel from "react-multi-carousel";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import images from "../../../assets/images";
import ActivitySelector from "../../../components/ActivitySelector";
import GradientButton from "../../../components/GradientButton";
import ZealButton from "../../../components/ZealButton";
import ZealInput from "../../../components/ZealInput";
import { PrivacyConstants } from "../../../constants/PrivacyConstants";
import {
  extractDomain,
  extractUrlsFromString,
  getCurrentTimezone,
  timeZones,
} from "../../../utils/commonUtils";
import { useFirebaseAuth } from "../../../utils/contexts/FirebaseAuthContext.js";
import ShareAsEvent from "../ShareAsEvent";
import SelectInterestsComponent from "../selectInterests";
import style from "../styles.module.css";
import PostComposer from "./PostComposer";

const PostFeedComposer = ({
  postFeedComposer,
  editPostData = false,
  setPostFeedComposer,
  setFeedsPost,
  setEditPostData,
  sidebarActivePostType,
}) => {
  const navigate = useNavigate();
  const { user } = useFirebaseAuth();
  const firestore = useFirestore();
  const firebase = useFirebase();
  const storage = firebase.storage;
  const [sendMessageLoader, setSendMessageLoader] = useState(false);
  const userinterests = useSelector(
    (state) => state.firebase.profile.interests
  ); // grab current user info
  const myProfile = useSelector((state) => state.firebase.profile);

  // const [feedsPost, setFeedsPost] = useState([]);
  const [feedLoading, setFeedLoading] = useState(true);
  const [hasMorePosts, setHasMorePosts] = useState(true);
  const [loading, setLoading] = useState(false);
  const [isSelectMoreActivitiesVisible, setIsSelectMoreActivitiesVisible] =
    useState(false);

  const [eventLocation, setEventLocation] = useState("");
  const [zipCode, setZipCode] = useState("");
  const [selectTimeZone, setSelectTimeZone] = useState({
    label: "Pacific Time (PT)",
    value: "America/Los_Angeles",
    short: "PT",
  });
  const [interests, setInterests] = useState(userinterests || []);

  const [eventDate, setEventDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());

  const [noSpecificTime, setNoSpecificTime] = useState(false);

  const [eventName, setEventName] = useState("");

  const [isThumbnailVisible, setIsThumbnailVisible] = useState(true);

  useFirestoreConnect(["activityTypes"]);
  useFirestoreConnect(["interests"]);
  const activityTypes = useSelector(
    (state) => state.firestore.data.activityTypes
  );

  useEffect(() => {
    if (editPostData && editPostData?.metadata) {
      setIsThumbnailVisible(editPostData?.metadata?.isThumbnailVisible);
    }
    fetchLinkPreview(messageTitle);
  }, [editPostData]);

  useEffect(() => {
    const currentTimeZone = timeZones.filter(
      (item) => item.value === getCurrentTimezone()
    );
    if (currentTimeZone[0] && !editPostData) {
      setSelectTimeZone(currentTimeZone[0]);
    }
  }, []);

  const [lastSnapshot, setLastSnapshot] = useState(null);

  //   const [postFeedComposer, setPostFeedComposer] = useState(false);
  const [messageTitle, setMessageTitle] = useState(""); // New state for input value
  const [messageCaption, setMessageCaption] = useState(""); // New state for input value
  const [privacyLevel, setPrivacyLevel] = useState(
    PrivacyConstants.FriendsOfFriends
  );
  const [toggleEventSwitch, setToggleEventSwitch] = useState(false);
  const [toggleEndDate, setToggleEndDate] = useState(false);
  const [toggleScheduleSwitch, setToggleScheduleSwitch] = useState(false);
  const [selectedPrivacyLevel, setSelectedPrivacyLevel] = useState("");
  const [selectInterests, setSelectInterests] = useState([]);
  const uploadImageInputRef = React.useRef(null);
  const [selectedMedia, setSelectedMedia] = useState([]);

  useEffect(() => {
    handleReset();
  }, [sidebarActivePostType]);

  useEffect(() => {
    if (editPostData) {
      if (editPostData?.mediaUrls?.length) {
        setPostFeedComposer("image");
      }
      setMessageTitle(editPostData?.content);
      setPrivacyLevel(editPostData?.privacyLevel);
      setToggleEventSwitch(!!editPostData.metadata?.event?.timeZone);
      setToggleEndDate(!!editPostData.metadata?.event?.endDate);
      setToggleScheduleSwitch(false);
      setSelectedPrivacyLevel(
        editPostData.privacyLevel === PrivacyConstants.Friends
          ? "Friends"
          : "Friends of Friends"
      );
      setSelectInterests(editPostData.interestId || []);
      setEventLocation(editPostData?.metadata?.event?.location || "");
      setZipCode(editPostData?.metadata?.event?.zipCode || "");
      editPostData?.metadata?.event?.timeZone &&
        setSelectTimeZone(editPostData?.metadata?.event?.timeZone);
      setEventDate(editPostData?.metadata?.event?.date?.toDate() || new Date());
      setEndDate(
        editPostData?.metadata?.event?.endDate?.toDate() || new Date()
      );
      setLinkPreview({
        linkUrl: editPostData?.metadata?.event?.linkUrl || "",
        thumbnailUrl: editPostData?.metadata?.event?.thumbnailUrl,
        title: editPostData?.metadata?.event?.title || "",
      });
      setNoSpecificTime(
        !editPostData?.metadata?.event?.date ||
          editPostData?.metadata?.event?.date === null
      );
      setEventName(editPostData?.metadata?.event?.eventName || "");
    }
  }, [editPostData]);

  const handleReset = () => {
    // Reset input tag
    uploadImageInputRef.current.value = "";
    setMessageTitle("");
    setPrivacyLevel(PrivacyConstants.FriendsOfFriends);
    setToggleEventSwitch(false);
    setToggleEndDate(false);
    setToggleScheduleSwitch(false);
    setSelectedPrivacyLevel("");
    setSelectInterests([]);
    setEventLocation("");
    setZipCode("");
    setSelectTimeZone({
      label: "Pacific Time (PT)",
      value: "America/Los_Angeles",
      short: "PT",
    });
    setEventDate(new Date());
    setEndDate(new Date());
    setNoSpecificTime(null);
    setEventName("");
    setSelectedMedia([]);
    setLinkPreview({
      linkUrl: "",
      thumbnailUrl: "",
      title: "",
    });
  };

  const [charCount, setCharCount] = useState(0); // New state for character count

  const [enableEditHeadline, setEnableEditHeadline] = useState(false);

  const [selectedActivities, setSelectedActivities] = useState(interests || []);

  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 1,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 1,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 1,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };

  const [linkPreview, setLinkPreview] = useState({
    linkUrl: "",
    thumbnailUrl: "",
    title: "",
  });

  useEffect(() => {
    if (selectedMedia?.length) {
      setPostFeedComposer("image");
    }
  }, [selectedMedia]);

  const uid = user.uid;
  const userInfo = useSelector((state) => state.firestore.data.userInfo);

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  useFirestoreConnect([
    {
      collection: "users",
      doc: uid,
      storeAs: "userInfo",
    },
  ]);

  const handleSelectInterest = (item) => {
    if (selectInterests.includes(item)) {
      setSelectInterests(
        selectInterests.filter((interest) => interest !== item)
      );
    } else if (selectInterests?.length <= 6) {
      setSelectInterests((prev) => [...prev, item]);
    }
  };

  const [isOpen, setIsOpen] = useState(false);
  const [index, setIndex] = useState(0);

  const uploadImages = async (uris) => {
    setSendMessageLoader(true);
    const urlArray = selectedMedia?.map((image) => image);
    let downloadURLs = [];
    try {
      const uploadPromises = urlArray?.map(async (image) => {
        // return
        const response = await fetch(image.uri);
        // const blob = await response.blob();

        const imagePath = `posts/media/${uid}`;
        const fileName = image?.lastModified + `name=${image.uri}.png`;

        const uploadResult = await firebase.uploadFile(imagePath, image, null, {
          name: fileName,
        });
        const downloadURL = await storage()
          .ref()
          .child(`${imagePath}/${fileName}`)
          .getDownloadURL();

        return {
          assetId: image?.lastModified,
          name: fileName,
          size: image?.size,
          type: image?.type,
          uri: downloadURL,
        };
      });
      downloadURLs = await Promise.all(uploadPromises);
      const data = {
        type: "image",
        content: messageTitle,
        caption: messageCaption,
        authorId: uid,
        interestId: selectInterests,
        privacyLevel: privacyLevel, // PrivacyConstants.Friends,
        mediaUrls: downloadURLs || editPostData?.mediaUrls,
        mediaMetaData: true,
      };
      const res = await createPost(data);
      if (res) {
        setFeedsPost((prev) => {
          if (!!editPostData && !!setEditPostData) {
            const updatedPosts = prev.map((post) =>
              post.postId === editPostData?.postId ? res : post
            );
            return updatedPosts;
          } else {
            // If postId doesn't exist, add it to the beginning of the array
            return [res, ...prev];
          }
        });
        toast(
          !!editPostData
            ? `${
                sidebarActivePostType === "preview"
                  ? "Post published successfully"
                  : "Post updated successfully"
              }. `
            : `Post created successfully.`,
          {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
            icon: () => <img src={images.zealProfile} />,
          }
        );
        handleReset();

        setSendMessageLoader(false);
      } else {
        setSendMessageLoader(false);
      }
    } catch (error) {
      setSendMessageLoader(false);
      console.error("Error uploading images:", error);
      throw error;
    }
  };

  const options = {
    month: "numeric",
    day: "numeric",
    year: "numeric",
    hour: "numeric",
    minute: "numeric",
    second: "numeric",
    hour12: true,
  };

  const createPost = async (data, imageUrl = null) => {
    try {
      const {
        type,
        authorId,
        content,
        caption,
        interestId,
        privacyLevel,
        mediaUrls,
        mediaMetaData,
      } = data;

      const createdAt =
        sidebarActivePostType !== "preview" && !!editPostData
          ? editPostData?.createdAt
          : firestore.FieldValue.serverTimestamp();

      const formatData = {
        authorId,
        ...(content && { content }),
        ...(caption && { caption }),
        type,
        interestId,
        privacyLevel,
        createdAt,
        updatedAt: createdAt,
        mediaUrls,
        isRepost: false,
        mentionUsers: [],
        mediaMetaData: mediaMetaData || false,
        metadata: {},
        metadata: {
          ...(!toggleEventSwitch &&
            linkPreview.title &&
            linkPreview.linkUrl && {
              link: {
                linkUrl: linkPreview?.linkUrl,
                thumbnailUrl: imageUrl ? imageUrl : linkPreview?.thumbnailUrl,
                title: linkPreview?.title,
                scheduleLink: toggleScheduleSwitch,
              },
            }),
          ...(toggleEventSwitch && {
            event: {
              eventName: eventName.trim() || "",
              linkUrl: linkPreview?.linkUrl || "",
              thumbnailUrl: imageUrl
                ? imageUrl
                : linkPreview?.thumbnailUrl || "",
              title: linkPreview?.title || "",
              location: eventLocation,
              zipCode: zipCode,
              date: noSpecificTime ? null : new Date(eventDate),
              endDate:
                noSpecificTime || !toggleEndDate ? null : new Date(endDate),
              dateTimeString: noSpecificTime
                ? null
                : moment(new Date(eventDate)).format("M/D/YYYY, h:mm:ss A"),
              endDateTimeString:
                noSpecificTime || !toggleEndDate
                  ? null
                  : moment(new Date(endDate)).format("M/D/YYYY, h:mm:ss A"),
              timeZone: selectTimeZone,
              noSpecificTime: noSpecificTime,
            },
          }),
          isThumbnailVisible: isThumbnailVisible ? isThumbnailVisible : false,
        },
        reactions: editPostData?.reactions || {
          heart: [],
          grinning: [],
          rolling_on_the_floor_laughing: [],
          amazing: [],
          greatJob: [],
          thankYou: [],
        },
      };

      if (!!editPostData && sidebarActivePostType !== "preview") {
        return updatePost(editPostData?.postId, formatData);
      } else {
        const postRef = await firestore.collection("posts").add(formatData);

        const addedPost = await postRef.get();
        const addedPostData = addedPost.data();
        console.log(
          "Post created successfully ",
          JSON.stringify(addedPostData)
        );
        return {
          ...addedPostData,
          postId: addedPost.id,
        };
      }
    } catch (error) {
      console.log(error);
      return false;
    }
  };

  const updatePost = async (postId, newData) => {
    try {
      const postRef = firestore.collection("posts").doc(postId);
      const updatedData = {
        updatedAt: firestore.FieldValue.serverTimestamp(),
        ...newData, // Include fields you want to update
      };
      await postRef.update(updatedData);
      const updatedPost = await postRef.get();
      const updatedPostData = updatedPost.data();
      setEditPostData(null);
      handleReset();
      console.log(
        "Post updated successfully ",
        JSON.stringify(updatedPostData)
      );
      return {
        ...updatedPostData,
        authorData: {
          profilePicture: myProfile.profilePicture,
          firstName: myProfile.firstName,
          lastName: myProfile.lastName,
        },
        postId: postId,
      };
    } catch (error) {
      console.error("Error updating post:", error);
      return false;
    }
  };

  const sendMessage = async () => {
    setSendMessageLoader(true);

    let downloadURL = null;

    const data = {
      type: toggleEventSwitch || linkPreview?.linkUrl ? "link" : "text",
      content: messageTitle,
      caption: messageCaption,
      authorId: uid,
      interestId: selectInterests,
      privacyLevel: privacyLevel, //PrivacyConstants.Friends,
      mediaUrls: [],
    };
    const res = await createPost(data, downloadURL);
    if (res) {
      if (sidebarActivePostType !== "preview") {
        setFeedsPost((prev) => {
          if (!!editPostData && !!setEditPostData) {
            const updatedPosts = prev.map((post) =>
              post.postId === editPostData?.postId ? res : post
            );
            return updatedPosts;
          } else {
            // If postId doesn't exist, add it to the beginning of the array
            return [res, ...prev];
          }
        });
      }

      toast(
        !!editPostData
          ? `${
              sidebarActivePostType === "preview"
                ? "Post published successfully"
                : "Post updated successfully"
            }.`
          : `Post created successfully.`,
        {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          icon: () => <img src={images.zealProfile} />,
        }
      );
      handleReset();

      setSendMessageLoader(false);
    } else {
      toast.error(
        !!editPostData ? `Error updating post. ` : `Error creating post.`,
        {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          icon: () => <img src={images.zealProfile} />,
        }
      );
      setSendMessageLoader(false);
    }
  };

  const setActivityPreferences = () => {
    firestore
      .collection("users")
      .doc(uid)
      .update({ interests })
      .then(() => {
        setIsSelectMoreActivitiesVisible(false);
      });
  };

  // Function to fetch link preview data
  const fetchLinkPreview = async (text) => {
    let url = extractUrlsFromString(text);
    if (!url?.length || linkPreview?.title || linkPreview?.thumbnailUrl) return;
    else {
      setLoading(true);
      const apiKey = "pk_7edbdf2629e21a57e6d7dac8d87fbee5d966f00f";

      const apiUrl = `https://jsonlink.io/api/extract?url=${url}&api_key=${apiKey}`;

      // Make a GET request using the Fetch API
      fetch(apiUrl)
        .then((response) => {
          if (!response.ok) {
            throw new Error(
              `Error: ${response.status} - ${response.statusText}`
            );
          }
          return response.json();
        })
        .then((data) => {
          setLoading(false);

          console.log(data); // Process the JSON response

          setLinkPreview({
            linkUrl: data?.url,
            thumbnailUrl:
              data?.images && data?.images?.length > 0
                ? data.images[0]
                : data?.favicons && data?.favicons?.length > 0
                ? data?.favicons[0]
                : "",
            title:
              editPostData?.metadata?.event?.title ||
              editPostData?.metadata?.link?.title ||
              data?.title,
          });
        })
        .catch((error) => {
          setLoading(false);
          console.error("An error occurred:", error);
        });
    }
  };

  return (
    <>
      {isSelectMoreActivitiesVisible ? (
        <Grid container style={{ padding: "3rem", position: "relative" }}>
          <ActivitySelector
            activityLimit={null}
            selectedActivities={interests}
            setSelectedActivities={setInterests}
          />
          <Box
            style={{
              width: "50%",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              marginTop: "24px",
              display: "flex",
              alignSelf: "center",
              position: "sticky",
              left: 0,
              bottom: 0,
              right: 0,
            }}
          >
            <GradientButton
              maxWidth="343px"
              disabled={selectedActivities?.length === 0}
              onClick={() => {
                setActivityPreferences();
              }}
            >
              Update
            </GradientButton>
          </Box>
        </Grid>
      ) : (
        <Grid container lg={10}>
          <PostComposer
            messageTitle={messageTitle}
            setMessageTitle={setMessageTitle}
            fetchLinkPreview={fetchLinkPreview}
            setCharCount={setCharCount}
            charCount={charCount}
            setPostFeedComposer={setPostFeedComposer}
            setSelectedMedia={setSelectedMedia}
            setToggleEventSwitch={setToggleEventSwitch}
            toggleEventSwitch={toggleEventSwitch}
            editPostData={!!editPostData}
            uploadImageInputRef={uploadImageInputRef}
          />

          {linkPreview?.thumbnailUrl && (
            <Grid
              container
              justifyContent="center"
              alignItems="center"
              style={{
                margin: "10px 0px 40px 0px",
                fontSize: "14px",
              }}
            >
              <Grid container justifyContent="center">
                <img
                  src={linkPreview?.thumbnailUrl}
                  className={style.linkPreviewThumbnail}
                />
              </Grid>

              <Grid container justifyContent="center">
                <Grid
                  item
                  xs
                  className={style.domainText}
                  justifyContent="center"
                >
                  {extractDomain(linkPreview.linkUrl)}
                  <h1>{linkPreview.title}</h1>
                </Grid>
                <Grid item xs="auto">
                  <IconButton
                    aria-label="edit"
                    onClick={() => {
                      setEnableEditHeadline((prev) => !prev);
                    }}
                  >
                    <EditIcon
                      style={
                        enableEditHeadline
                          ? styles.editIcon
                          : styles.activeEditIcon
                      }
                    />
                  </IconButton>
                </Grid>
              </Grid>
              {enableEditHeadline && (
                <Grid container style={{ margin: "20px 0px" }}>
                  <ZealInput
                    focused
                    fullWidth
                    maxLength={1000}
                    label={"Link Headline"}
                    value={linkPreview?.title}
                    placeholder="What's the headline of this link?"
                    onChange={(e) => {
                      setLinkPreview((prev) => ({
                        ...prev,
                        title: e.target.value,
                      }));
                    }}
                  />
                </Grid>
              )}
            </Grid>
          )}
          {loading && (
            <Grid
              container
              justifyContent="center"
              sx={{ padding: "10px 0px", position: "relative" }}
            >
              <Skeleton
                variant="rectangular"
                width={"100%"}
                height={118}
              ></Skeleton>
              <Grid style={styles.loadingText}>Loading Link Preview</Grid>
            </Grid>
          )}
          {postFeedComposer === "image" && (
            <div style={{ width: "100%", paddingBottom: "30px" }}>
              <Carousel style={{ height: "200px" }} responsive={responsive}>
                {selectedMedia?.map((image, index) => (
                  <img
                    style={{ height: "250px", width: "100%" }}
                    src={URL.createObjectURL(image)}
                    alt=""
                    key={index}
                    onClick={() => {
                      setIndex(index);
                      setIsOpen(true);
                    }}
                  />
                ))}
                {!selectedMedia?.length &&
                  editPostData?.mediaUrls?.map((image, index) => (
                    <img
                      style={{ height: "250px", width: "100%" }}
                      src={image?.uri}
                      alt=""
                      key={index}
                      onClick={() => {
                        setIndex(index);
                        setIsOpen(true);
                      }}
                    />
                  ))}
              </Carousel>
              {!!selectedMedia?.length && (
                <Grid style={{ margin: "20px 0px" }}>
                  <ZealInput
                    fullWidth
                    maxLength={1000}
                    scrollEnabled
                    label={"Caption"}
                    value={messageCaption}
                    placeholder="Add a caption"
                    onChangeText={setMessageCaption}
                    onChange={(e) => {
                      const value = e.target.value;
                      setMessageCaption(value);
                      setCharCount(value?.length);
                    }}
                  />
                </Grid>
              )}

              <ReactImageCarouselViewer
                open={isOpen}
                onClose={() => setIsOpen(false)}
                images={[...selectedMedia]}
                startIndex={index}
              />
            </div>
          )}
          <ShareAsEvent
            toggleEventSwitch={toggleEventSwitch}
            setToggleEventSwitch={setToggleEventSwitch}
            noSpecificTime={noSpecificTime}
            setNoSpecificTime={setNoSpecificTime}
            endDate={endDate}
            toggleEndDate={toggleEndDate}
            setToggleEndDate={setToggleEndDate}
            setEndDate={setEndDate}
            startDate={eventDate}
            setStartDate={setEventDate}
            eventLocation={eventLocation}
            setEventLocation={setEventLocation}
            zipCode={zipCode}
            setZipCode={setZipCode}
            selectTimeZone={selectTimeZone}
            setSelectTimeZone={setSelectTimeZone}
            eventName={eventName}
            setEventName={setEventName}
            loading={loading}
          />
          <Grid container justifyContent="center" alignItems="center">
            <Grid item xs={12} style={{ marginTop: "2rem" }}>
              <Grid style={styles.menuLabelText}>What's your Zeal about?</Grid>
              <Grid>
                <SelectInterestsComponent
                  selectInterests={selectInterests}
                  interests={interests}
                  activityTypes={activityTypes}
                  handleSelectInterest={handleSelectInterest}
                  setIsSelectMoreActivitiesVisible={
                    setIsSelectMoreActivitiesVisible
                  }
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid justifyContent="flex-end">
            {editPostData && (
              <ZealButton
                loading={false}
                onClick={() => {
                  setEditPostData(null);
                  handleReset();
                }}
                disabled={sendMessageLoader}
                disabledButtonStyle
                text={"Cancel"}
                buttonStyle={{
                  // width: "400px",
                  height: "35px",
                  borderRadius: "20px",
                  marginTop: 30,
                  marginRight: 10,
                  backgroundColor: "##EAEAEC",
                }}
              ></ZealButton>
            )}

            <ZealButton
              loading={false}
              onClick={() =>
                postFeedComposer === "image" ? uploadImages() : sendMessage()
              }
              disabled={
                sendMessageLoader ||
                !selectInterests?.length ||
                !messageTitle?.length
              }
              text={
                sendMessageLoader
                  ? "loading"
                  : editPostData
                  ? `${
                      sidebarActivePostType === "preview"
                        ? "Update & Publish"
                        : "Update"
                    }`
                  : `${
                      sidebarActivePostType === "preview" ? "Publish" : "Post"
                    }`
              }
              buttonStyle={{
                height: "35px",
                borderRadius: "20px",
                marginTop: 30,
              }}
            ></ZealButton>
          </Grid>
        </Grid>
      )}
    </>
  );
};

export default PostFeedComposer;

const styles = {
  menuButton: {
    color: "#12121F",
    fontSize: "12.2px",
    fontStyle: "normal",
    fontWeight: 600,
    lineHeight: "20px",
    fontFamily: '"Causten Regular", sans-serif',
    borderRadius: "8px",
    background: "#EAEAEC",
  },
  menuItem: {
    color: "#12121F",
    fontSize: "0.8rem",
    fontStyle: "normal",
    fontWeight: 600,
    lineHeight: "20px",
    fontFamily: '"Causten Regular", sans-serif',
    borderRadius: "8px",
  },
  menuLabelText: {
    fontFamily: '"Causten Regular", sans-serif',
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: 600,
    lineHeight: "20px",
  },
  charCount: {
    color: "#12121F",
    fontSize: "12px",
    fontStyle: "normal",
    fontWeight: 600,
    lineHeight: "16px",
    letterSpacing: "0.4px",
  },
  loadingContainer: {
    padding: "50px 0px",
  },
  loadingText: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    color: "#6206bf",
    fontWeight: 600,
  },
  editIconContainer: {
    position: "absolute",
    right: 0,
    // transform: "translate(-50%, -50%)",
    // color: "#6206bf",
    // fontWeight: 600,
  },
  editIcon: {
    color: "#000000",
  },
  activeEditIcon: {
    color: "#6206bf",
  },
};
