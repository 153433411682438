import React, { useState, useEffect, useRef } from 'react';
import SelectedTime from '../../components/SelectedTime';
import DayPicker from '../../components/DayPicker';
import { mergeOverlappingTimes } from '../../utils/dateManipulation';
import { Box, Button, Avatar } from '@mui/material';
import { useFirebaseAuth } from '../../utils/contexts/FirebaseAuthContext.js';
import 'reactjs-popup/dist/index.css';

import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';

import { COLORS } from '../../constants';

import { Modal } from '@mui/material';

import { useLocation } from 'react-router-dom';

import moment from 'moment';

import { ROUTES } from '../../constants';

import { useOutletContext } from 'react-router-dom';

import { IconButton, Switch } from '@mui/material';
import RemoveIcon from '@mui/icons-material/Remove';
import CloseIcon from '@mui/icons-material/Close';
import AddIcon from '@mui/icons-material/Add';
import CheckCircleOutlineOutlinedIcon from "@mui/icons-material/CheckCircleOutlineOutlined";
import AvailableTimeslot from '../../components/AvailableTimeslot';
import GradientButton from '../../components/GradientButton';
import GradientContainer from '../../components/GradientContainer';
import { useFirestore } from 'react-redux-firebase';
import { weekends, weekdays, ABBREVIATIONS, DAYS, IdxToDAYS } from '../../constants/timeConstants';
import './index.css'
  //FIX  UPDATE TIMES TO UPDATE ONLY WHEN PRESSING BUTTON

export default function PreferredTimes() {
  const { user } = useFirebaseAuth();

  const { groupId, invitationId, setNextPageCallback, continueCallback, setHasSkip } = useOutletContext();
  const { selectedDaysData } = useLocation().state;

  const firestore = useFirestore();

  const MODES = {
    add: 0,
    edit: 1,
  };

  const handleContinue = async () => {
    await firestore.collection('groups').doc(groupId).get()
      .then((doc) => {
        const {hasSchedule, hasGroup, members, groupSize} = doc.data()
        const maxGroupSize = parseInt(groupSize.split('-')[1])
        if(!hasSchedule && !hasGroup && members.length < maxGroupSize){
          continueCallback(ROUTES.InviteFriends);
        }
        else{
          continueCallback(ROUTES.AppDownload)
        }
    })
  }

  const [mode, setMode] = useState(MODES.add);
  const [modalOpen, setModalOpen] = useState(false);

  const [timesAvailable, setTimesAvailable] = useState(
    Object.entries(DAYS).reduce((obj, [day, index]) => {
      obj[index] = selectedDaysData.includes(day) ? [{
        endTime: moment('5:00 PM', 'hh:mm A'),
        startTime: moment('8:00 AM', 'hh:mm A'),
      }] : []
      return obj
    }, {})
  );

  const [startEndTimes, setStartEndTimes] = useState([]);
  const [modalOpenDay, setModalOpenDay] = useState()
  const [selectedDays, setSelectedDays] = useState(selectedDaysData);
  const [openStartTimePicker, setOpenStartTimePicker] = useState(false)
  const [openEndTimePicker, setOpenEndTimePicker] = useState(false)
  const [shouldCopyDays, setShouldCopyDays] = useState(false);
  const [copyDays, setCopyDays] = useState([])

  useEffect(() => {
    setHasSkip(false)
  }, []);

  const closeModal = () => {
    setModalOpen(false); 
    setOpenStartTimePicker(false); 
    setOpenEndTimePicker(false);
  }

  const hasAvailableTimes = () => {
    return Object.values(timesAvailable).reduce((acc, day) => acc + day.length, 0) > 0
  }

  const transformDateIntoTimeString = (date) => {
    const hour = date.getHours();
    const minutes = date.getMinutes();
    let hourString = hour.toString();
    let minString = minutes.toString();
    if (hour < 10) {
      hourString = `0${hour}`;
    }

    if (minutes < 10) {
      minString = `0${minutes}`;
    }

    return `${hourString}:${minString}`;
  };

  // TO IMPLEMENT: save time preferences to Firestore
  const saveTimes = () => {
    const generalAvailability = {
      0: [],
      1: [],
      2: [],
      3: [],
      4: [],
      5: [],
      6: [],
    };
    let overflowTimes = [];
    Object.entries(timesAvailable).forEach(([k, v]) => {
      generalAvailability[k] = [
        ...generalAvailability[k],
        ...v.map(({startTime, endTime}) => {
          let endTimeStr = transformDateIntoTimeString(endTime.toDate());
          if (endTime.toDate() <= startTime.toDate()){
            endTimeStr = '23:59';
            overflowTimes.push({ startTime: '00:00', endTime: transformDateIntoTimeString(endTime.toDate())});
          }
          return {
            startTime: transformDateIntoTimeString(startTime.toDate()),
            endTime: endTimeStr
          }
        })
      ]
      if(overflowTimes.length > 0){
        generalAvailability[(parseInt(k) + 1) % 7] = [...generalAvailability[(parseInt(k) + 1) % 7], ...overflowTimes]
        overflowTimes = []
      }
    })
    mergeOverlappingTimes(generalAvailability);
    firestore
      .collection('users')
      .doc(user.uid)
      .update({ generalAvailability, timezone: Intl.DateTimeFormat().resolvedOptions().timeZone })
    handleContinue();
  };

  const addMoreDays = () => {
    setMode(MODES.add);
    setModalOpen(true);
  };

  const editActivity = (idx) => {
    setMode(MODES.edit);
    setStartEndTimes(timesAvailable[idx]);
    setModalOpenDay(idx);
    setModalOpen(true);
  };

  const deleteTime = (idx) => {
    // setTimesAvailable(timesAvailable.filter((el, index) => index !== activityIndex));
    let startEndTimesCopy = [...startEndTimes]
    startEndTimesCopy.splice(idx, 1)
    setStartEndTimes(startEndTimesCopy)
  };

  const addTimeslot = () => {
    let startEndTimesCopy = [...startEndTimes]
    startEndTimesCopy.push({
      endTime: moment('5:00 PM', 'hh:mm A'),
      startTime: moment('8:00 AM', 'hh:mm A'),
    })
    setStartEndTimes(startEndTimesCopy)
  }

  const updateTimes = () => {
    let timesAvailableCopy = {...timesAvailable}
    timesAvailableCopy[modalOpenDay] = startEndTimes

    if(shouldCopyDays) {
      copyDays.forEach((day) => {
        timesAvailableCopy[DAYS[day]] = startEndTimes
      })
    }

    if(startEndTimes.length <= 0){
      let selectedDaysCopy = [...selectedDays];
      let deleteIdx = selectedDaysCopy.indexOf(IdxToDAYS[modalOpenDay]);
      selectedDaysCopy.splice(deleteIdx, 1);

      if(shouldCopyDays) {
        copyDays.forEach((day) => {
          deleteIdx = selectedDaysCopy.indexOf(IdxToDAYS[day])
          selectedDaysCopy.splice(deleteIdx, 1);
        })
      }
      setSelectedDays(selectedDaysCopy);
    }
    
    setTimesAvailable(timesAvailableCopy)
    setCopyDays([])
    setShouldCopyDays(false)
    closeModal()
  }

  const saveActivity = () => {
    closeModal()
    let timesAvailableCopy = {...timesAvailable}
    selectedDays.forEach((day, index) => {
      if(timesAvailable[DAYS[day]].length > 0) return;
      timesAvailableCopy[DAYS[day]] = [{
        endTime: moment('5:00 PM', 'hh:mm A'),
        startTime: moment('8:00 AM', 'hh:mm A'),
      }];
    })
    const selectedIndices = selectedDays.map(entry => DAYS[entry])
    Object.entries(timesAvailableCopy).forEach(([k,v]) => {
      if(!selectedIndices.includes(parseInt(k))) timesAvailableCopy[k] = []
    })
    setTimesAvailable(timesAvailableCopy)
  };

  const onTimePairEdit = (val, index, key) => {
    let startEndTimesCopy = [...startEndTimes]
    startEndTimesCopy[index][key] = val
    setStartEndTimes(startEndTimesCopy)
  }

  return (
    <Box id='preferred-times-container'>
      <Box id='preferred-times-body'>
        <Box sx={{display: 'flex', justifyContent: 'center', flexDirection: 'column', textAlign: 'center', alignItems: 'center'}}>
          <h1>What times are you generally available on these days?</h1>
          <h4 style={{margin: 0}}>Customize the time slots below. Modify, remove, or add new time slots that work for your personal schedule.</h4>
          <Box sx={{display: 'flex', flex: 1, justifyContent: 'center', alignItems: 'center', maxWidth: '400px', flexDirection: 'column', marginTop: '24px'}}>
            <Box sx={{display: 'flex', flex: 1, alignItems: 'center', justifyContent: 'center', flexDirection: 'column', textAlign: 'left', width: '100%'}}>
              {hasAvailableTimes()
                ? (
                  Object.values(timesAvailable).map((value, index) => {
                    return value.length > 0 && (
                      <GradientContainer widthOverride='100%' key={`selected-time-${index}`}>
                        <SelectedTime dayName={IdxToDAYS[index]} startEndTimes={value}
                          onPress={() => editActivity(index)} key={`selected-time${index}`}
                        />
                      </GradientContainer>
                    )
                  })
                )
                : ''}
            </Box>
              <GradientContainer widthOverride='100%'>
                <Button 
                  sx={{backgroundColor: '#fff', color: '#000', width: '100%', justifyContent: 'flex-start', padding: '20px', textTransform: 'initial', '&:hover': {backgroundColor: 'rgb(240, 240, 240)'}}} 
                  startIcon={<Avatar src={'https://firebasestorage.googleapis.com/v0/b/joy-prototype.appspot.com/o/add-icon.png?alt=media&token=74fce021-3284-470c-a922-1a551d277499'} style={{width: '20px', height: '20px'}} />} 
                  onClick={addMoreDays}>Add or remove days
                </Button>
              </GradientContainer>
              <GradientButton disabled={!hasAvailableTimes()} onClick={saveTimes}>Save Availability</GradientButton>
          </Box>
        </Box>
      </Box>
      <Modal
        open={modalOpen}
        onClose={closeModal}
        aria-labelledby='modal-modal-title'
        aria-describedby='modal-modal-description'
      >
        <Box sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: 400,
          bgcolor: 'background.paper',
          borderRadius: '15px',
          boxShadow: 24,
          padding: '40px',
          display: 'flex',
          justifyContent: 'center',
          flexDirection: 'column',
          alignItems: 'center',
          maxHeight: '60vh',
          overflow: 'scroll'
        }}>

          <IconButton size='small' sx={{position: 'absolute', top: '0', right: '0', margin: '5px', color: '#000'}} onClick={closeModal}>
            <CloseIcon />
          </IconButton>
          {mode === MODES.add ? (
            <>
              <h1>Add or remove days</h1>
              <Box className='repeatRow'>
                <DayPicker selectedDays={selectedDays} setSelectedDays={setSelectedDays} label="Select all the days of the week that work for you." />
              </Box>
              <br />
              <GradientButton props={{minWidth: '100%'}} onClick={saveActivity}>Update Days</GradientButton>
            </>
          ) : (
            <>
              <span style={{fontSize: '40px', fontWeight: '600', marginBottom: '24px'}}>Edit Availability</span>
              <Box style={{width: '100%', marginBottom: '100px'}}>
                <div style={{padding: '0px 32px' }}>
                <p>{`Time slots for ${IdxToDAYS[modalOpenDay]}`}</p>
                <LocalizationProvider dateAdapter={AdapterMoment}>
                {startEndTimes.map((timePair, index) =>  (
                  <div key={`time-pair-${index}`} style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginTop: '16px'}}>
                    <AvailableTimeslot timePair={timePair} index={index} onTimePairEdit={onTimePairEdit} />
                    <div style={{marginLeft: '10px'}}>
                      <IconButton sx= {{color: '#6206BF'}}size='small' onClick={() => deleteTime(index)}>
                        <RemoveIcon fontSize="medium"/>
                      </IconButton>
                    </div>
                  </div>
                ))}
              </LocalizationProvider>
              <IconButton size='small' sx={{borderRadius: '50px', color:'#6206BF', marginTop: '16px'}} onClick={addTimeslot}>
                <AddIcon fontSize="medium" />
                <span style={{fontFamily: 'Causten Regular', color:'#6206BF', fontSize: '16px', paddingRight: '8px'}}>Add time slot</span>
              </IconButton>
              <div style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%'}}>
                <p style={{fontSize: '14px', fontWeight: '600'}}>Copy time slots to other days of the week</p>
                 <Switch 
                  checked={shouldCopyDays}
                  onChange={() => setShouldCopyDays(!shouldCopyDays)} 
                  sx={{
                    "&.MuiSwitch-root .Mui-checked": {
                      color: "#6206BF"
                    },
                    "&.MuiSwitch-root .MuiSwitch-track": {
                      backgroundColor: "#6206BF !important"
                    }
                  }}
                />
              </div>
              </div>
              {shouldCopyDays && (
                <div style={{width: '100%', display: 'flex', justifyContent: 'center'}}>
                  <DayPicker selectedDays={copyDays} setSelectedDays={setCopyDays} />
                </div>
              )}
            </Box>
            <GradientButton style={{minWidth: '100%', marginTop: '5px'}} onClick={updateTimes}>Update Times</GradientButton>
            </>
          )}
        </Box>
      </Modal>
    </Box>
  );
}
