import { Alert, Collapse, Grid } from "@mui/material";
import { useRef } from "react";
import defaultAvatar from "../../static/img/groupDashboard/avatar.png";
import media1 from "../../static/img/groupDashboard/media/1.png";
import media2 from "../../static/img/groupDashboard/media/2.png";
import repliesIcon from "../../static/img/groupDashboard/replies-icon.svg";
import PollCard from "../GroupChatComponents/PollCard";
import FileComponent from "../GroupChatComponents/filesComponent";
import LinkComponent from "../GroupChatComponents/linkComponent";
import NotesComponent from "../GroupChatComponents/notes";
import ImageMessage from "./ImageMessage";
import VideoMessage from "./VideoMessage";
import "./index.css";

const MessageCard = ({
  messageData,
  index,
  preview,
  setPreviewIndex,
  disableReactions,
  toggleDrawer,
}) => {
  const avatar = messageData?.senderInfo?.avatar;
  const touchTimeoutRef = useRef(null);
  const senderInfo =
    messageData?.senderInfo?.name === "System"
      ? "Zeal"
      : messageData?.senderInfo?.name;

  function truncateTextTo50Words(text) {
    const words = text.trim().split(/\s+/);

    if (words.length <= 50) {
      return text;
    }

    const truncatedText = words.slice(0, 50).join(" ") + "...";

    return truncatedText;
  }

  const renderMessageType = (incomingData) => {
    let message;
    const type = messageData.type;

    switch (type) {
      case "text":
        message = (
          <Grid
            container
            style={{
              margin: "5px 0px",
              wordBreak: "break-word",
              width: "auto",
              userSelect: "none",
              fontSize: "1rem",
              lineHeight: "17px",
              fontWeight: 400,
            }}
          >
            {!messageData?.snnipetObj && preview
              ? truncateTextTo50Words(messageData?.message)
              : messageData?.message}
            {messageData?.snnipetObj && (
              <LinkComponent incomingData={incomingData} />
            )}
            {messageData?.eventObj && (
              <Grid container className="media-container">
                <Collapse in={true}>
                  <Alert
                    icon={false}
                    severity="error"
                    style={{
                      width: "fit-content",
                      margin: "10px 0px",
                      backgroundColor: "#F9F9F9",
                      color: "#000000",
                    }}
                  >
                    {senderInfo} posted an event. Download the Zeal iOS app to
                    view events from Group Chat. You can view and RSVP to events
                    from your group’s home.
                  </Alert>
                </Collapse>
              </Grid>
            )}
          </Grid>
        );
        break;
      case "image":
        message = <ImageMessage message={incomingData} />;
        break;
      case "activePool":
        message = (
          <Grid container className="media-container">
            <img className="media-image" src={media1} alt="group-logo" />
            <img className="media-image" src={media2} alt="group-logo" />
          </Grid>
        );
        break;
      case "video":
        message = <VideoMessage message={incomingData} />;
        break;

      case "file":
        message = <FileComponent incomingData={incomingData} />;
        break;
      case "extension_document":
        message = <NotesComponent incomingData={incomingData} />;
        break;
      case "extension_poll":
        message = <PollCard incomingData={incomingData} />;
        break;

      default:
        message = <></>;
    }
    return <div>{message}</div>;
  };

  const handleTouchStart = () => {
    touchTimeoutRef.current = setTimeout(() => {
      setPreviewIndex(index);
    }, 800);
  };

  const handleTouchEnd = () => {
    clearTimeout(touchTimeoutRef.current);
  };

  return (
    <Grid container className="container" key={index}>
      <Grid
        container
        alignItems="flex-end"
        className="container"
        key={index || 0}
        onTouchStart={disableReactions ? null : handleTouchStart}
        onTouchEnd={handleTouchEnd}
        onTouchCancel={handleTouchEnd} // For handling touch cancel event (e.g., when scrolling)
        onContextMenu={(event) => {
          event.preventDefault();
        }}
      >
        <Grid item xs="auto">
          <img
            src={avatar || defaultAvatar}
            alt="group-logo"
            className="avatar"
          />
        </Grid>
        <Grid
          container
          alignItems="flex-start"
          justifyContent="flex-start"
          item
          xs={10}
          style={{
            padding: "12px",
            borderRadius: "12px",
            backgroundColor: "#F9F9F9",
            marginLeft: 6,
          }}
        >
          <Grid
            item
            xs="auto"
            className="user-name"
            style={{
              paddingTop: 0,
              paddingRight: "0.3rem",
              marginBottom: "6px",
            }}
          >
            {senderInfo}
          </Grid>
          <Grid container>
            <Grid container>
              <Grid item xs>
                {renderMessageType(messageData)}
                <Grid container>
                  {!!messageData?.replyCount && (
                    <div className="reactions-container">
                      <img
                        className="chat-icons"
                        src={repliesIcon}
                        alt="group-logo"
                        width="15px"
                      />
                      <div className="reactions-count">
                        {messageData?.replyCount}
                      </div>
                    </div>
                  )}
                  {!!messageData?.reactions &&
                    Object.keys(messageData?.reactions).map((item, index) => {
                      const reactionCount = Object.keys(
                        Object.entries(messageData?.reactions)[index][1]
                      ).length;
                      return (
                        <div
                          className="reactions-container"
                          onClick={() => {
                            toggleDrawer({ reactions: messageData?.reactions });
                          }}
                        >
                          <p className="reaction-icon">{item}</p>
                          <div className="reactions-count">{reactionCount}</div>
                        </div>
                      );
                    })}
                </Grid>
              </Grid>
            </Grid>
            <Grid
              container
              xs="auto"
              className="message-date"
              sx={{ marginTop: "6px" }}
            >
              {`${messageData?.sentAt?.month}  ${messageData?.sentAt?.date}, ${messageData?.sentAt?.time}`}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default MessageCard;
