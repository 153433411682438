import { CometChat } from "@cometchat-pro/chat";
import { Box } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { useOutletContext } from "react-router-dom";
import Composer from "../../components/Composer";
// import ZealFooter from "../../components/ZealFooter";
import { MessageController } from "../../controllers/MessageController";
import ChatList from "./ChatList";

export default function GroupChat() {
  const { groupId } = useOutletContext();
  const scrollContainerRef = useRef(null);
  const messagesRequestRef = useRef(null);
  const [groupMessageList, _setGroupMessageList] = useState([]);
  const groupMessageListRef = useRef(groupMessageList);

  const setGroupMessageList = (messages) => {
    _setGroupMessageList(messages);
    groupMessageListRef.current = messages;
  };

  useEffect(() => {
    const listenerID = `${groupId}_${new Date().getTime()}`;

    MessageController.attachMessageListeners(
      listenerID,
      onNewMessage,
      onEditMessage
    );

    return () => {
      CometChat.removeMessageListener(listenerID);
    };
  }, []);

  useEffect(() => {
    fetchMessages();
  }, []);

  const fetchMessages = async () => {
    let limit = 30;
    messagesRequestRef.current = MessageController.getMessageRequestInstance(
      groupId,
      limit
    );

    messagesRequestRef?.current
      ?.fetchPrevious()
      .then((messages) => {
        console.log("Message list fetched successfully ", messages);
        setGroupMessageList(messages);
        // scrollToBottom();
      })
      .catch((error) => {
        console.log("Message fetching failed with error:", error);
      });
  };

  const scrollToBottom = () => {
    setTimeout(() => {
      scrollContainerRef?.current?.scrollIntoView(); // add { behavior: "smooth" } if need smooth scrolling.
    }, 200);
  };

  const onNewMessage = (message) => {
    const newList = [...groupMessageListRef.current, message];
    setGroupMessageList(newList);
    scrollToBottom();
  };

  const onEditMessage = (message) => {
    let messageIdToMatch = message?.id;
    const newList = [...groupMessageListRef.current];
    let index = newList.findIndex(
      (message) => message?.id === messageIdToMatch
    );
    if (index !== -1) {
      newList[index] = message;
      setGroupMessageList(newList);
    }
  };
  

  useEffect(()=>{

    if(groupMessageList&&groupMessageList?.length>0){
      CometChat.markAsRead(groupMessageList[groupMessageList?.length-1])
    }

  },[groupMessageList])

  return (
    <>
      <ChatList
        groupMessageList={groupMessageList}
        setGroupMessageList={setGroupMessageList}
        messagesRequestRef={messagesRequestRef}
        scrollContainerRef={scrollContainerRef}
      />
      <div
        style={{
          position: "relative",
          bottom: 0,
          backgroundColor: "#ffffff",
          width: "100%",
        }}
      >
        <Composer
          receiverId={groupId}
          receiverType={CometChat.RECEIVER_TYPE.GROUP}
          scrollToBottom={scrollToBottom}
          setMessageList={setGroupMessageList}
          messageList={groupMessageListRef.current}
        />
      </div>
    </>
  );
}
