import { Box, Grid } from "@mui/material";
import React, { useState } from "react";
import styles from "./PollResultList.module.css";
import { useLocation } from "react-router-dom";

const PollResultsList = () => {
  const location = useLocation();
  const dataPayload = location.state;
  const { votedMembers, voteOptions, memberSnipptById } = dataPayload;

  return (
    <Grid container>
      {voteOptions.map((item) => {
        return (
          <>
            <Grid container className={styles.titleBackground}>
              {item?.optionText}
            </Grid>
            <Grid container>
              {item?.userIds.length ? (
                item?.userIds.map((userId) => {
                  return (
                    <Grid
                      container
                      alignItems="center"
                      className={styles.memberRow}
                    >
                      <Grid item xs="auto">
                        {memberSnipptById[userId]?.profilePicture ? (
                          <img
                            src={memberSnipptById[userId]?.profilePicture}
                            alt="Profile"
                            style={{
                              height: 40,
                              width: 40,
                              borderRadius: 50,
                              marginRight: 20,
                            }}
                          />
                        ) : (
                          <div className={styles.pillContainer}>
                            {`${memberSnipptById[
                              userId
                            ]?.firstName[0].toUpperCase()}${memberSnipptById[
                              userId
                            ]?.lastName[0].toUpperCase()}`}
                          </div>
                        )}
                      </Grid>
                      <Grid item xs>
                        {memberSnipptById[userId]?.firstName +
                          " " +
                          memberSnipptById[userId]?.lastName}
                      </Grid>
                    </Grid>
                  );
                })
              ) : (
                <Grid
                  item
                  container
                  alignItems="center"
                  xs
                  className={styles.memberRow}
                >
                  No Votes
                </Grid>
              )}
            </Grid>
          </>
        );
      })}
      {Object.keys(memberSnipptById)
        .filter((key) => !votedMembers.includes(key))
        .map((el) => {
          return (
            <>
              <Grid container className={styles.titleBackground}>
                Pending
              </Grid>
              <Grid container alignItems="center" className={styles.memberRow}>
                <Grid item xs="auto">
                  {memberSnipptById[el]?.profilePicture ? (
                    <img
                      src={memberSnipptById[el]?.profilePicture}
                      alt="Profile"
                      style={{
                        height: 40,
                        width: 40,
                        borderRadius: 50,
                        marginRight: 20,
                      }}
                    />
                  ) : (
                    <div className={styles.pillContainer}>
                      {`${memberSnipptById[
                        el
                      ]?.firstName[0].toUpperCase()}${memberSnipptById[
                        el
                      ]?.lastName[0].toUpperCase()}`}
                    </div>
                  )}
                </Grid>
                <Grid item xs>
                  {memberSnipptById[el]?.firstName +
                    " " +
                    memberSnipptById[el]?.lastName}
                </Grid>
              </Grid>
            </>
          );
        })}
    </Grid>
  );
};

export default PollResultsList;
