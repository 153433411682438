import React from "react";
import { ThemeProvider, Button, CircularProgress } from "@mui/material";
import { theme } from "../../utils/themes/primaryTheme";

const ZealButton = ({
  onClick,
  outlined,
  text,
  buttonStyle,
  disabled,
  loading,
  leftIcon
}) => {
  const disabledButtonStyle = {
    opacity: 0.5,
    cursor: "not-allowed",
  };

  const mergedButtonStyle =
    disabled || loading
      ? { ...buttonStyle, ...disabledButtonStyle }
      : buttonStyle;

  return (
    <ThemeProvider theme={theme}>
      <Button
        color="primary"
        variant={outlined ? "outlined" : "contained"}
        onClick={onClick}
        style={mergedButtonStyle}
        // disabled={loading}
        startIcon={
          leftIcon
            ? leftIcon
            : loading && (
                <CircularProgress size={24} style={{ color: "#ffffff" }} />
              )
        }
      >
        {!loading ? text : ""}
      </Button>
    </ThemeProvider>
  );
};

export default ZealButton;
