import { CometChat } from "@cometchat-pro/chat";
import { AppTypes } from "../utils/AppTypes";

export class GroupManager {
  groupRequest = null;

  constructor() {
    this.initializeUsersRequest();
  }

  initializeUsersRequest = () => {
    this.groupRequest = new CometChat.GroupsRequestBuilder()
      .setLimit(30)
      .setSearchKeyword(AppTypes.SUB_GROUP)
      .joinedOnly(true)
      .build();
  };

  fetchNextGroups() {
    return this.groupRequest.fetchNext();
  }

  static getGroupMembers = (GUID) => {
    return new CometChat.GroupMembersRequestBuilder(GUID)
      .setLimit(10)
      .build()
      .fetchNext();
  };

  static getGroupDetails = (GUID) => {
    return CometChat.getGroup(GUID);
  };

  static createGroup = (GUID, groupName, groupType, UIDs) => {
    const group = new CometChat.Group(GUID, groupName, groupType);
    group.setTags([AppTypes.SUB_GROUP]);

    let members = this.createGroupMembers(UIDs);
    let banMembers = [];

    return CometChat.createGroupWithMembers(group, members, banMembers);
  };

  static createGroupMembers = (UIDs) => {
    return UIDs.map(
      (UID) =>
        new CometChat.GroupMember(UID, CometChat.GROUP_MEMBER_SCOPE.PARTICIPANT)
    );
  };
  static getAllGroups = (limit) => {
    return new CometChat.GroupsRequestBuilder()
      .setLimit(limit)
      .joinedOnly(true)
      .build()
      .fetchNext();
  };

  static joinGroup = (GUID, groupType, passsword) => {
    return CometChat.joinGroup(GUID, groupType, passsword);
  };

  static leaveGroup = (GUID) => {
    return CometChat.leaveGroup(GUID);
  };

  static changeUserScope = (GUID, uid, scope) => {
    return CometChat.updateGroupMemberScope(GUID, uid, scope);
  };

  static removeUserFromGroup = (GUID, uid) => {
    return CometChat.kickGroupMember(GUID, uid);
  };
}
