import { createTheme } from "@mui/material";

const theme = createTheme({
  typography: {
    fontFamily: "Causten Regular",
    h6: {
      fontFamily: "Causten Bold"
    },
    h7: {
      fontFamily: "Causten Bold"
    },
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: `@font-face {
        font-family: 'Causten Regular';
        src: local('Causten Regular'), url(./fonts/Causten-Regular.otf) format('opentype');
      }
      
      @font-face {
        font-family: 'Causten Bold';
        src: local('Causten Bold'), url(./fonts/Causten-Bold.otf) format('opentype');
      }`,
    },
  },
});
export default theme;
