import React from "react";
import styles from "./styles.module.css";
import {
  Button,
  Divider,
  Grid,
  InputBase,
  InputLabel,
  Menu,
  MenuItem,
  Switch,
  TextField,
} from "@mui/material";
import ZealInput from "../../../components/ZealInput";
import { DateTimePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { timeZones } from "../../../utils/commonUtils";
import { useEffect } from "react";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
dayjs.extend(utc);

const ShareAsEvent = ({
  toggleEventSwitch,
  setToggleEventSwitch,
  noSpecificTime,
  setNoSpecificTime,
  toggleEndDate,
  setToggleEndDate,
  endDate,
  setEndDate,
  startDate,
  setStartDate,
  zipCode,
  setZipCode,
  eventLocation,
  setEventLocation,
  selectTimeZone,
  setSelectTimeZone,
  eventName,
  setEventName,
  loading,
}) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleOnChangeTimeZone = (itemIndex) => {
    setSelectTimeZone({
      label: timeZones[itemIndex].label,
      value: timeZones[itemIndex].value,
      short: timeZones[itemIndex].short,
    });
  };

  return (
    <Grid container>
      {toggleEventSwitch && (
        <>
          <Grid container className={styles.postComposerContainer}>
            <h2 style={{ marginBottom: "1rem" }}>Event Details</h2>
            <Grid container className={styles.postComposerInnerContainer}>
              <Grid container className={styles.labelContainer}>
                <InputLabel style={{ fontSize: "0.8rem", fontWeight: "bold" }}>
                  Event Name
                </InputLabel>
                <InputBase
                  focused
                  fullWidth
                  maxLength={1000}
                  scrollEnabled
                  label={"Event Name"}
                  value={eventName}
                  placeholder="What's the name of this event?"
                  onChangeText={setEventName}
                  onChange={(e) => {
                    const value = e.target.value;
                    setEventName(value);
                  }}
                />
              </Grid>
              <Grid
                container
                justifyContent="space-between"
                className={styles.labelContainer}
                gap={2}
              >
                <Grid item xs={8}>
                  <InputLabel
                    style={{ fontSize: "0.8rem", fontWeight: "bold" }}
                  >
                    Location
                  </InputLabel>
                  <InputBase
                    multiline
                    focused
                    fullWidth
                    maxLength={1000}
                    scrollEnabled
                    label={"Location"}
                    value={eventLocation}
                    placeholder="Type the location (optional)"
                    onChangeText={setEventLocation}
                    onChange={(e) => {
                      const value = e.target.value;
                      setEventLocation(value);
                    }}
                  />
                </Grid>
                <Grid item xs>
                  <InputLabel
                    style={{ fontSize: "0.8rem", fontWeight: "bold" }}
                  >
                    Zip Code
                  </InputLabel>

                  <InputBase
                    focused
                    fullWidth
                    maxLength={1000}
                    scrollEnabled
                    label={"ZipCode"}
                    value={zipCode}
                    placeholder="Zip Code"
                    onChangeText={setZipCode}
                    onChange={(e) => {
                      const value = e.target.value;
                      setZipCode(value);
                    }}
                    inputProps={{ inputMode: "numeric", pattern: "[0-9]*" }}
                  />
                </Grid>
              </Grid>
              {!noSpecificTime && (
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <Grid container marginY={2} columnGap={2}>
                    <Grid item xs>
                      <DateTimePicker
                        label="Starts"
                        value={startDate}
                        onChange={(value) => {
                          setStartDate(value.utc().format());
                        }}
                        renderInput={(params) => (
                          <TextField size="small" {...params} />
                        )}
                      />
                    </Grid>
                    {toggleEndDate && (
                      <Grid item xs>
                        <DateTimePicker
                          label="Ends"
                          value={endDate}
                          onChange={(value) => {
                            setEndDate(value.utc().format());
                          }}
                          renderInput={(params) => (
                            <TextField size="small" {...params} />
                          )}
                        />
                      </Grid>
                    )}
                  </Grid>
                </LocalizationProvider>
              )}
              <Grid container justifyContent="space-between">
                <Grid container item xs alignItems="center">
                  <Grid item className={styles.optionName}>
                    No specific time
                  </Grid>
                  <Grid
                    item
                    justifyContent="flex-end"
                    style={{ display: "flex", alignItems: "center" }}
                  >
                    <Switch
                      checked={noSpecificTime}
                      onChange={() => {
                        setNoSpecificTime(!noSpecificTime);
                      }}
                      sx={{
                        "&.MuiSwitch-root .Mui-checked": {
                          color: "#6206BF",
                        },
                        "&.MuiSwitch-root .MuiSwitch-track": {
                          backgroundColor: "#6206BF !important",
                        },
                      }}
                    />
                  </Grid>
                </Grid>
                <Grid
                  container
                  item
                  xs
                  alignItems="center"
                  justifyContent="flex-end"
                >
                  {!noSpecificTime && (
                    <>
                      <Grid item className={styles.optionName}>
                        Add an end date
                      </Grid>
                      <Grid
                        item
                        justifyContent="flex-end"
                        style={{ display: "flex", alignItems: "center" }}
                      >
                        <Switch
                          checked={toggleEndDate}
                          onChange={() => {
                            setToggleEndDate(!toggleEndDate);
                          }}
                          sx={{
                            "&.MuiSwitch-root .Mui-checked": {
                              color: "#6206BF",
                            },
                            "&.MuiSwitch-root .MuiSwitch-track": {
                              backgroundColor: "#6206BF !important",
                            },
                          }}
                        />
                      </Grid>
                    </>
                  )}
                </Grid>

                <Divider />

                <Grid container>
                  <Grid item xs className={styles.optionName}>
                    Select Time Zone
                  </Grid>

                  <Grid item xs container justifyContent="flex-end">
                    <Button
                      id="basic-button"
                      aria-controls={open ? "basic-menu" : undefined}
                      aria-haspopup="true"
                      aria-expanded={open ? "true" : undefined}
                      onClick={handleClick}
                      variant="text"
                    >
                      {selectTimeZone.label}
                    </Button>
                    <Menu
                      id="basic-menu"
                      anchorEl={anchorEl}
                      open={open}
                      onClose={handleClose}
                      MenuListProps={{
                        "aria-labelledby": "basic-button",
                      }}
                    >
                      {timeZones?.map((item, index) => {
                        return (
                          <MenuItem
                            key={index}
                            onClick={() => {
                              handleOnChangeTimeZone(index);
                              handleClose();
                            }}
                            style={styles.menuItem}
                          >
                            {item?.label}
                          </MenuItem>
                        );
                      })}
                    </Menu>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </>
      )}
    </Grid>
  );
};

export default ShareAsEvent;
