import React, { useState, useRef } from 'react';
import { ClickAwayListener } from '@mui/material';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';

const AvailableTimeslot = ({ timePair, index, onTimePairEdit }) => {
  const [openStartTimePicker, setOpenStartTimePicker] = useState(false);
  const [openEndTimePicker, setOpenEndTimePicker] = useState(false);
  let startTimePickerRef = useRef(null);
  let endTimePickerRef = useRef(null);


  return (
    <div style={{width: '100%'}}>
      <ClickAwayListener onClickAway={() => { if (openStartTimePicker) setOpenStartTimePicker(false) }}>
        <div style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between', backgroundColor: '#F4F4F8', padding: '8px 12px', borderRadius: '8px 8px 0 0', borderBottom: '1px solid #e6e6ec'}}>
          <span>From</span>
          <TimePicker
            views={['hours', 'minutes',]}
            label='Start Time'
            value={timePair.startTime}
            open={openStartTimePicker}
            onAccept={() => setOpenStartTimePicker(false)}
            onClose={() => setOpenStartTimePicker(false)}
            onChange={(e) => onTimePairEdit(e, index, 'startTime')}
            PopperProps={{ anchorEl: startTimePickerRef.current }}
            renderInput={({
              ref,
              inputProps,
              disabled,
              onChange,
              value,
              ...other
            }) => (
              <div 
                ref={ref} 
                style={{backgroundColor: '#E6E6ED', borderRadius: '8px', padding: '4px 8px', width: 'fit-content', cursor: 'pointer'}}
                onClick={() => {setOpenStartTimePicker(!openStartTimePicker);}}
              >
                <span ref={startTimePickerRef}>{timePair.startTime.format('hh:mm A')}</span>
              </div>
            )}
          />          
        </div>
      </ClickAwayListener>
      <ClickAwayListener onClickAway={() => { if (openEndTimePicker) setOpenEndTimePicker(false) }}>
        <div style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between', backgroundColor: '#F4F4F8', padding: '8px 12px', borderRadius: '0 0 8px 8px'}}>
          <span>To</span>
          <TimePicker
            views={['hours', 'minutes',]}
            label='End Time'
            value={timePair.endTime}
            open={openEndTimePicker}
            onChange={(e) => onTimePairEdit(e, index, 'endTime')}
            onAccept={() => setOpenEndTimePicker(false)}
            onClose={() => setOpenEndTimePicker(false)}
            PopperProps={{ anchorEl: endTimePickerRef.current }}
            renderInput={({
              ref,
              inputProps,
              disabled,
              onChange,
              value,
              ...other
            }) => (
              <div 
                ref={ref} 
                style={{backgroundColor: '#E6E6ED', borderRadius: '8px', padding: '4px 8px', width: 'fit-content', cursor: 'pointer'}}
                onClick={() => {setOpenEndTimePicker(!openEndTimePicker);}}
              >
                <span ref={endTimePickerRef}>{timePair.endTime.format('hh:mm A')}</span>
              </div>
            )}
          />
        </div>
      </ClickAwayListener>
    </div>
  );
};

export default AvailableTimeslot;