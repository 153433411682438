import { CometChat } from "@cometchat-pro/chat";
import { Box, Grid, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { useSelector } from "react-redux";
import { isLoaded } from "react-redux-firebase";
import { useNavigate, useOutletContext } from "react-router-dom";
import { rrulestr } from "rrule";
import { EventCard } from "../../components/EventCard";
import GradientButton from "../../components/GradientButton";
import MessageCard from "../../components/MessageCard";
import { COLORS } from "../../constants";
import { FREQ } from "../../constants/timeConstants";
import { useFirebaseAuth } from "../../utils/contexts/FirebaseAuthContext";
import { constructMessage } from "../../utils/groupChatUtils";
import rightArrow from "../../static/img/groupDashboard/files/right-arrow.svg";
import "./index.css";
import { MOMENTUM } from "../../constants/momentumConstants";

const styles = {
  headerText: {
    display: "inline-block",
    margin: "10px 6px",
  },
  comingSoon: {
    color: "#FFA500",
  },
};
export default function GroupDetails() {
  const [groupMessageList, setGroupMessageList] = useState([]);
  // const dispatch = useDispatch();
  const { groupChat } = useSelector((state) => state);

  const TIME_WINDOW = 6;
  const { groupId, group, continueCallback } = useOutletContext();
  const { user } = useFirebaseAuth();
  const navigate = useNavigate();

  const getMessages = async () => {
    // let GUID = "GUID";
    let limit = 5;
    let categories = [CometChat.CATEGORY_MESSAGE, CometChat.CATEGORY_CUSTOM];
    let messagesRequest = new CometChat.MessagesRequestBuilder()
      .setCategories(categories)
      .hideDeletedMessages(true)
      .hideReplies(true)
      .setGUID(groupId)
      .setLimit(limit)
      .build();

    messagesRequest.fetchPrevious().then(
      (messages) => {
        setGroupMessageList(messages);
        // dispatch(
        //   groupChatActions.setGroupDescription(constructMessage(messages))
        // );
        console.log("Message list fetched:", messages);
      },
      (error) => {
        console.log("Message fetching failed with error:", error);
      }
    );
  };

  useEffect(() => {
    let listenerID = groupId;

    CometChat.addMessageListener(
      listenerID,
      new CometChat.MessageListener({
        onTextMessageReceived: (textMessage) => {
          setGroupMessageList([...groupMessageList, textMessage]);
        },
        onMediaMessageReceived: (mediaMessage) => {
          setGroupMessageList([...groupMessageList, mediaMessage]);
        },
        onCustomMessageReceived: (customMessage) => {
          setGroupMessageList([...groupMessageList, customMessage]);
        },
        onMessageEdited: (message) => {
          setGroupMessageList([...groupMessageList, message]);
        },
      })
    );

    return () => {
      CometChat.removeMessageListener(listenerID);
    };
  }, [groupMessageList]);

  useEffect(() => {
    getMessages();
  }, []);

  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 5,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 2.5,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };

  const handleClick = () => {
    navigate(`/groups/${groupId}/group-chat`);
  };

  // Code mention below will be used to customize carousel left and right button.

  //   const CustomLeftArrow = ({ onClick }) => (
  //     <i onClick={() => onClick()} className="custom-left-arrow" />
  //   );
  //   const CustomRightArrow = ({ onClick }) => {
  //     return (
  //       <h1 style={{ zIndex: 100 }} onClick={() => onClick()}>
  //         Right Arrow
  //       </h1>
  //     );
  //   };

  const [upcomingEvents, setUpcomingEvents] = useState([]);

  const activityTypes = useSelector(
    (state) => state.firestore.data.activityTypes
  );

  const events = useSelector(
    (state) => state.firestore.ordered[`events-${groupId}`]
  );
  console.log(events);
  const memberSnippets = useSelector(
    (state) => state.firestore.data[`memberSnippets-${groupId}`]
  );

  // console.log(upcomingEvents)

  const getDates = (rrule, startDate, endDate) => {
    const rule = rrulestr(rrule);
    return rule.between(new Date(startDate), new Date(endDate));
  };

  const datesMatch = (date1, date2) => {
    return (
      date1.toISOString().substring(0, 16) ===
      date2.toISOString().substring(0, 16)
    );
  };

  let momentumStage;
  const criticalMass = Math.ceil(parseInt(group?.groupSize.split("-")[0]) / 2);
  const numMembers = group?.members.length;
  const primaryActivity = events?.find((el) => el.isPrimaryActivity);
  const newOptimalTimeFound =
    primaryActivity?.optimalTime !== null &&
    primaryActivity?.optimalTime !== undefined;

  if (primaryActivity?.isScheduled) {
    momentumStage = -1;
  } else if (numMembers === 1) {
    momentumStage = MOMENTUM.prebuilding;
  } else if (numMembers >= criticalMass) {
    momentumStage = MOMENTUM.criticalMass;
  } else if (numMembers < 3) {
    momentumStage = MOMENTUM.building;
  } else {
    momentumStage = MOMENTUM.reached;
  }

  const getUpcomingEvents = () => {
    const updatedUpcomingEvents = [];
    if (events) {
      const activities = events.filter(
        (el) => el.isException !== true && !el.isCanceled
      );
      const exceptions = events.filter(
        (el) => el.isException === true && !el.isCanceled
      );

      // get cards for upcoming activities (non-exceptions)
      activities.forEach((el) => {
        if (el.isRecurring) {
          if (el.isScheduled) {
            const rruleString = `${el.recurrenceStart}\n${el.recurrenceRule}`;
            const localDateString = new Date(
              new Date().getFullYear(),
              new Date().getMonth(),
              new Date().getDate()
            )
              .toISOString()
              .split("T")[0];
            const localDateStringFuture = new Date(
              new Date().getFullYear(),
              new Date().getMonth() + TIME_WINDOW,
              new Date().getDate()
            )
              .toISOString()
              .split("T")[0];

            const upcomingDates = getDates(
              rruleString,
              localDateString,
              localDateStringFuture
            );

            const exceptionsForActivity = exceptions.filter(
              (exc) => exc.parentEventId === el.id
            );

            upcomingDates.forEach((date) => {
              // check if there is an exception for this event on this date and, if so, give that priority
              const exceptionForDate = exceptionsForActivity.find(
                (exception) => {
                  const exceptionStartDate =
                    exception.parentStartDateTime.toDate();
                  const upcomingDate = new Date(
                    date.getUTCFullYear(),
                    date.getUTCMonth(),
                    date.getUTCDate(),
                    date.getUTCHours(),
                    date.getUTCMinutes()
                  );
                  if (datesMatch(exceptionStartDate, upcomingDate)) {
                    console.log(
                      `found exception for date: ${exceptionStartDate
                        .toISOString()
                        .substring(0, 16)}`
                    );
                  }
                  return datesMatch(exceptionStartDate, upcomingDate);
                }
              );

              if (!exceptionForDate) {
                updatedUpcomingEvents.push({
                  eventName: el.title,
                  eventId: el.id,
                  location: el.location || "TBD",
                  startDateTime: new Date(
                    date.getUTCFullYear(),
                    date.getUTCMonth(),
                    date.getUTCDate(),
                    date.getUTCHours(),
                    date.getUTCMinutes()
                  ),
                  duration: el.duration,
                  frequency: FREQ[rrulestr(el.recurrenceRule).options.freq],
                  responses: el.responses,
                });
              }
            });
          } else if (!el.isScheduled && el.isPrimaryActivity) {
            updatedUpcomingEvents.push({
              isMomentumCard: true,
            });
          }
        } else if (!el.isRecurring && el.isScheduled) {
          const localDate = new Date(
            new Date().getFullYear(),
            new Date().getMonth(),
            new Date().getDate()
          );
          const localDateFuture = new Date(
            new Date().getFullYear(),
            new Date().getMonth() + TIME_WINDOW,
            new Date().getDate()
          );
          if (
            localDate < el.startDateTime.toDate() &&
            el.startDateTime.toDate() < localDateFuture
          ) {
            updatedUpcomingEvents.push({
              eventName: el.title,
              eventId: el.id,
              location: el.location || "TBD",
              startDateTime: el.startDateTime.toDate(),
              duration: el.duration,
              responses: el.responses,
              frequency: "ONE TIME",
            });
          }
        }
      });

      // get cards for upcoming exceptions
      exceptions.forEach((el) => {
        const localDate = new Date(
          new Date().getFullYear(),
          new Date().getMonth(),
          new Date().getDate()
        );
        const localDateFuture = new Date(
          new Date().getFullYear(),
          new Date().getMonth() + TIME_WINDOW,
          new Date().getDate()
        );
        if (el.startDateTime) {
          if (
            localDate < el.startDateTime.toDate() &&
            el.startDateTime.toDate() < localDateFuture
          ) {
            updatedUpcomingEvents.push({
              eventName: el.title,
              eventId: el.id,
              location:
                el.location ||
                events.find((event) => event.id === el.parentEventId)
                  .location ||
                "TBD",
              startDateTime: el.startDateTime.toDate(),
              duration:
                el.duration ||
                events.find((event) => event.id === el.parentEventId).duration,
              responses: el.responses,
              frequency:
                FREQ[
                  rrulestr(
                    events.find((event) => event.id === el.parentEventId)
                      .recurrenceRule
                  ).options.freq
                ],
            });
          }
        } else if (
          localDate < el.parentStartDateTime.toDate() &&
          el.parentStartDateTime.toDate() < localDateFuture
        ) {
          updatedUpcomingEvents.push({
            eventId: el.id,
            eventName: el.title,
            location:
              el.location ||
              events.find((event) => event.id === el.parentEventId).location ||
              "TBD",
            startDateTime: el.parentStartDateTime.toDate(),
            duration:
              el.duration ||
              events.find((event) => event.id === el.parentEventId).duration,
            responses: el.responses,
            frequency:
              FREQ[
                rrulestr(
                  events.find((event) => event.id === el.parentEventId)
                    .recurrenceRule
                ).options.freq
              ],
          });
        }
      });
    }
    updatedUpcomingEvents.sort((a, b) =>
      a.startDateTime > b.startDateTime ? 1 : -1
    );
    setUpcomingEvents([
      ...updatedUpcomingEvents.filter((el) => !el.isScheduled),
      ...updatedUpcomingEvents.filter((el) => el.isScheduled),
    ]);
  };

  const isOrganizer = group?.creatorId === user.uid;
  const onlyMomentumCard =
    upcomingEvents.length === 1 && upcomingEvents[0].isMomentumCard;

  const MomentumCard = () => {
    return (
      <Box
        key="momentum"
        sx={{
          backgroundColor: COLORS.PURPLE,
          color: "#FFFFFF",
          boxShadow: "2px 4px 10px rgba(0,0,0,0.15)",
          borderRadius: "20px",
          padding: "8px 11px 25px 11px",
          marginBottom: "30px",
          marginTop: onlyMomentumCard ? "0px" : "30px",
          width: "85%",
        }}
      >
        <Typography variant="body2">{group.name}</Typography>
        <Typography variant="body1">
          {(() => {
            if (!isOrganizer) {
              return `Zeal is working on scheduling your ${activityTypes[
                group.activityId
              ].name.toLowerCase()} activity.`;
            } else {
              if (momentumStage === MOMENTUM.prebuilding) {
                return "Zeal will help schedule your activity as more people join the group!";
              } else if (
                momentumStage === MOMENTUM.reached ||
                momentumStage === MOMENTUM.building
              ) {
                return `Zeal found tentative times that work for the ${numMembers} members of the group!`;
              } else if (momentumStage === MOMENTUM.criticalMass) {
                return `Zeal found times that work for the ${numMembers} members of the group!`;
              }
            }
          })()}
        </Typography>
      </Box>
    );
  };

  const renderUpcomingEventLabel = (startDateTime) => {
    if (
      startDateTime.toLocaleString("en-us", {
        month: "long",
        day: "numeric",
        weekday: "long",
      }) ===
      new Date().toLocaleString("en-us", {
        month: "long",
        day: "numeric",
        weekday: "long",
      })
    ) {
      return "Today";
    } else {
      return "Upcoming Event";
    }
  };

  useEffect(() => {
    getUpcomingEvents();
  }, [events]);

  return (
    isLoaded(events) &&
    isLoaded(memberSnippets) &&
    isLoaded(activityTypes) && (
      <Box
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "flex-start",
          width: "100%",
          overflow: "scroll",
        }}
      >
        {/* <GroupHeader
          onPress={() => {
            continueCallback(ROUTES.AboutGroup);
          }}
          groupName={group.name}
          groupSize={Object.keys(group.members).length}
          groupActivity={activityTypes[group.activityId].name}
        /> */}
        <Carousel responsive={responsive}>
          {upcomingEvents.length > 0 ? (
            upcomingEvents.map((item, index) => {
              if (item.isMomentumCard) {
                return <MomentumCard />;
              }
              let userResponse;
              if (!(user.uid in item.responses)) {
                userResponse = "pending";
              } else if (item.responses[user.uid] === "attending") {
                userResponse = "attending";
              } else if (item.responses[user.uid] === "declined") {
                userResponse = "declined";
              }
              return (
                <Box key={index} sx={{ margin: "0px 0px 30px 4px" }}>
                  <EventCard
                    startDateTimeString={item.startDateTime.toISOString()}
                    duration={item.duration}
                    frequency={item.frequency}
                    eventName={item.eventName}
                    userResponse={userResponse}
                    attendees={Object.keys(item.responses)
                      .filter((key) => item.responses[key] === "attending")
                      .map((el, index) => {
                        return [
                          index,
                          {
                            firstName: memberSnippets[el].firstName,
                            lastName: memberSnippets[el].lastName,
                          },
                        ];
                      })}
                    groupSize={Object.keys(group.members).length}
                    location={item.location}
                    eventId={item.eventId}
                    groupId={groupId}
                    isFastTrack={group?.isFastTrack}
                  />
                </Box>
              );
            })
          ) : (
            <MomentumCard />
          )}
        </Carousel>

        <Grid
          container
          className="chat-body-container"
          onClick={() => {
            handleClick();
          }}
        >
          <Grid container justifyContent="space-between" alignItems="center">
            <Grid item xs="auto">
              <h2 className="sub-heading" variant="h6">
                Group Chat
              </h2>
            </Grid>
            <Grid item xs="auto">
              <img src={rightArrow} alt="file-icon" />
            </Grid>
          </Grid>

          {groupMessageList.length !== 0 ? (
            groupMessageList?.reverse().map((item, index) => {
              return (
                <MessageCard
                  emojiOpen={false}
                  preview={true}
                  messageData={constructMessage(item)}
                  key={index}
                />
              );
            })
          ) : (
            <Grid container justifyContent="center">
              <div className="load"></div>
            </Grid>
          )}

          <Box
            item
            xs={12}
            // container
            justifyContent="center"
            alignItems="center"
            className="footer-button-wrapper"
          >
            <GradientButton>Create Post</GradientButton>
          </Box>
        </Grid>
        <Grid container justifyContent="center" alignItems="center">
          <Grid item xs={12} md={5}>
            <p className="footer-text">
              Zeal is available on App Store -{" "}
              <a href="https://apps.apple.com/us/app/zeal-activities-with-friends/id6446917140">
                <span className="download-text">Download Here</span>
              </a>{" "}
              and we’re working on the Android app
            </p>
          </Grid>
        </Grid>
      </Box>
    )
  );
}
