import React, { useState, useEffect } from "react";
import { Box, Checkbox, Button } from "@mui/material";
import { useOutletContext } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import GradientButton from "../../components/GradientButton";

import FormCard from "../../components/FormCard";
import validator from "validator";
import { useFirestore } from "react-redux-firebase";
import { useFirebaseAuth } from "../../utils/contexts/FirebaseAuthContext.js";
import { COLORS, ROUTES } from "../../constants";
import OutlineButton from "../../components/OutlinedButton";
import "./index.css";

export default function ThankYou() {
  const { group, rsvpStatus, isFastTrack, isPersonalEvent } =
    useOutletContext();

  const navigate = useNavigate();

  const handleContinue = () => {
    navigate("/user");
  };

  const handleRedirect = () => {
    navigate("/user");
  };

  return (
    <FormCard center bottom>
      <h1>Thank You!</h1>
      <h4>
        Download Zeal to view your personal calendar, see friends on Zeal,
        message Sarah, suggest a new time, and schedule more activities.
      </h4>
      <div
        style={{
          display: "flex",
          width: "100%",
        }}
      >
        <a style={{ width: "100%", marginRight: 12 }} href={ROUTES.AppStore}>
          <GradientButton maxWidth="100%">iPhone</GradientButton>
        </a>
        <a style={{ width: "100%" }} href={ROUTES.PlayStore}>
          <GradientButton maxWidth="100%">Android</GradientButton>
        </a>
      </div>
    </FormCard>
  );
}
