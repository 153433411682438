import { Box, Grid, LinearProgress } from "@mui/material";
import React, { useEffect } from "react";
import images from "../../../assets/images";
import styles from "./index.module.css";

const SplashScreen = ({ setShowSplashScreen }) => {
  useEffect(() => {
    setTimeout(() => {
      setShowSplashScreen(false);
    }, 3500);
  }, []);

  return (
    <Grid
      container
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      className={styles.splashContainer}
    >
      <img src={images.zealLogo} alt="group-logo" className={styles.zealLogo} />
      <Box
        sx={{
          position: "absolute",
          top: "0px",
          height: "10px",
          width: "100%",
        }}
      >
        <LinearProgress
          sx={{
            backgroundColor: "#ffffff",
            color: "red",
            "& .MuiLinearProgress-barColorSecondary": {
              backgroundColor: "#ffffff",
            },
            height: "1px",
          }}
        />
      </Box>
      <h1 className={styles.title}>Zeal Admin</h1>
    </Grid>
  );
};

export default SplashScreen;
