import CheckIcon from "@mui/icons-material/Check";
import { Grid } from "@mui/material";
import React from "react";
import Emoji from "react-emojis";
import { useFirestoreConnect } from "react-redux-firebase";
import { COLORS } from "../../constants";

const CATEGORIES_RANK = {
  sports: 0,
  creative: 1,
  "table games": 2,
  food: 3,
  other: 4,
};

const Activity = ({ id, name, emoji, selected, onPress }) => {
  useFirestoreConnect(["activityTypes"]);

  const getActivityStyle = () => {
    if (selected) {
      return {
        flexDirection: "row",
        padding: "8px",
        paddingLeft: "15px",
        paddingRight: "15px",
        borderWidth: "1px",
        borderColor: "#dddddd",
        borderRadius: "5px",
        margin: "5px",
        backgroundColor: COLORS.PURPLE,
        color: "#ffffff",
        cursor: "pointer",
        minHeight: "45px",
      };
    }
    return {
      flexDirection: "row",
      padding: "8px",
      paddingLeft: "15px",
      paddingRight: "15px",
      border: 1,
      borderColor: "#dddddd",
      borderRadius: "5px",
      margin: "5px",
      backgroundColor: "#ffffff",
      cursor: "pointer",
      minHeight: "45px",
    };
  };

  return (
    <Grid container item xs={6} md={4} lg={3}>
      <Grid
        container
        item
        justifyContent="space-between"
        onClick={onPress}
        sx={getActivityStyle()}
      >
        <Grid container>
          <Grid container item xs="auto" alignItems="center">
            <Emoji emoji={emoji} />
          </Grid>

          <Grid
            container
            item
            xs
            alignItems="center"
            textAlign="left"
            style={{
              marginLeft: "8px",
              fontSize: "12px",
              fontWeight: "600",
              color: selected ? "white" : "black",
            }}
          >
            {name}
          </Grid>
          <Grid container item xs={2} alignItems="center">
            {selected && (
              <CheckIcon
                size="small"
                color="white"
                style={{ paddingLeft: "8px" }}
              />
            )}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Activity;
