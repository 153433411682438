import * as React from "react";
import "./index.css";
import { Box, TextField } from "@mui/material";
import { useState, useEffect, useRef } from "react";
import firebase from "../../services/firebaseConfig";

import FormCard from "../../components/FormCard";

import GradientButton from "../../components/GradientButton";
import HomeRedirection from "../../components/HomeRedirection";

import { ROUTES } from "../../constants";

import { COLORS } from "../../constants";

import { useFirestore } from "react-redux-firebase";

import GradientWrapper from "../../components/GradientWrapper";

import { useQuery } from "../../utils/hooks/useQuery";

import "react-phone-number-input/style.css";
import PhoneInput, { parsePhoneNumber } from "react-phone-number-input";
import { useFirebaseAuth } from "../../utils/contexts/FirebaseAuthContext";
import { CometChat } from "@cometchat-pro/chat";
import { useNavigate } from "react-router-dom";

export default function LogIn({ admin }) {
  const firestore = useFirestore();

  const [didSendCode, setDidSendCode] = useState(false);
  const [showErrorMessage, setShowErrorMessage] = useState(false);
  const [showRedirectionPrompt, setShowRedirectionPrompt] = useState(false);

  const [phoneNumber, setPhoneNumber] = useState("");
  const [phoneNumberStatic, setPhoneNumberStatic] = useState("");
  const [otp, setOTP] = useState("");
  const [final, setFinal] = useState("");
  const [verify, setVerify] = useState(null);
  const [signingIn, setSigningIn] = useState(false);
  const [dots, setDots] = useState("");

  let query = useQuery();

  let invitationId = query.get("i");
  let groupId = query.get("g");
  let eventId = query.get("e");

  const navigate = useNavigate();

  if (invitationId && groupId) {
    navigate(
      `/join/login?g=${groupId}&i=${invitationId}&${
        eventId ? `e=${eventId}` : ""
      }`
    );
  }

  useEffect(() => {
    if (signingIn) {
      const start = new Date();
      const interval = setInterval(() => {
        setDots(
          Array(1 + (Math.round((new Date() - start) / 700) % 4)).join(".")
        );
      }, 700);
      return () => clearInterval(interval);
    }
  }, [signingIn]);

  const sendVerification = async () => {
    let returnError = false;
    setPhoneNumberStatic(parsePhoneNumber(phoneNumber).nationalNumber);
    await firestore
      .collection("users")
      .where("phoneNumber", "==", parsePhoneNumber(phoneNumber).nationalNumber)
      .get()
      .then((querySnapshot) => {
        //check if this number exists in our database
        if (querySnapshot.docs.length > 0) {
          setShowErrorMessage(false);
        } else {
          setShowErrorMessage(true);
          setSigningIn(false);
          returnError = true;
        }
      })
      .catch((error) => {
        console.error("Failed to query user with exception:", error);
      });

    if (
      phoneNumber === "" ||
      parsePhoneNumber(phoneNumber).nationalNumber.length < 10 ||
      returnError
    )
      return;
    await firebase
      .auth()
      .signInWithPhoneNumber(phoneNumber, verify)
      .then((result) => {
        setFinal(result);
        setDidSendCode(true);
        setShowErrorMessage(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const validateOTP = async () => {
    if (otp === null || final === null) {
      return;
    } else if (otp === "") {
      return;
    }
    setSigningIn(true);
    await final
      .confirm(otp)
      .then((result) => {
        const createAuthToken = firebase
          .functions()
          .httpsCallable("createCometChatAuthToken");

        createAuthToken().then((res) => {
          const authToken = res.data;
          CometChat.login(authToken).then(
            () => {
              setTimeout(() => {
                navigate(`/user`, { state: { admin } });
              }, "1000");
            },
            (error) => {
              console.log("CometChat Login failed with exception:", { error });
            }
          );
        });
      })
      .catch((err) => {
        console.log(err);
        setSigningIn(false);
        alert("Wrong code. Please enter again.");
      });
  };

  const resendVerification = () => {
    if (verify && recaptchaWrapper) {
      verify.clear();
      recaptchaWrapper.current.innerHTML = `<div id="recaptcha-container" className="justify-center flex"></div>`;
    }

    setVerify(
      new firebase.auth.RecaptchaVerifier("recaptcha-container", {
        size: "invisible",
      })
    );
  };

  useEffect(() => {
    setVerify(
      new firebase.auth.RecaptchaVerifier("recaptcha-container", {
        size: "invisible",
      })
    );
  }, []);

  useEffect(() => {
    if (didSendCode) {
      sendVerification();
    }
  }, [verify]);

  const recaptchaWrapper = useRef();

  const topHeading = () => {
    if (admin) {
      return (
        <>
          <h1>Welcome Zeal Admin</h1>
          <h3>Sign in to Zeal with your phone number.</h3>
        </>
      );
    } else {
      return (
        <>
          <h1>Welcome back! What's your number?</h1>
          <h3>Zeal will text you a code so we know it's you.</h3>
        </>
      );
    }
  };

  return (
    <GradientWrapper displayHeading noScroll>
      <Box alignItems="center" justifyContent="center">
        {!showRedirectionPrompt ? (
          !didSendCode ? (
            <Box alignItems="center" justifyContent="center">
              <FormCard>
                {topHeading()}
                <Box style={{ padding: "10px" }}>
                  <PhoneInput
                    placeholder="(111)123-4567"
                    value={phoneNumber}
                    withCountryCallingCode
                    defaultCountry="US"
                    onChange={setPhoneNumber}
                  />
                </Box>
                {showErrorMessage && (
                  <span
                    style={{ marginBottom: "1rem" }}
                    className="text-warning"
                  >
                    We didn’t recognize {`${phoneNumber}`}. Please try again or
                    &nbsp;
                    <a onClick={() => setShowRedirectionPrompt(true)}>
                      Sign Up
                    </a>
                    &nbsp;for Zeal.
                  </span>
                )}

                <GradientButton
                  style={{ marginTop: "1rem" }}
                  disabled={!phoneNumber}
                  maxWidth="343px"
                  onClick={sendVerification}
                >
                  Next
                </GradientButton>
                <Box style={{ padding: "20px" }}>
                  <p>
                    We will send a text with a verification code. Message and
                    data rates may apply. By tapping continue, you are agreeing
                    to our <a href={ROUTES.TermsOfService}>Terms of Service</a>{" "}
                    and <a href={ROUTES.PrivacyPolicy}>Privacy Policy</a>.
                  </p>
                </Box>
              </FormCard>
            </Box>
          ) : (
            <FormCard>
              <Box
                alignItems="center"
                justifyContent="center"
                display="flex"
                flexDirection="column"
              >
                <h1>Confirm your number</h1>
                <h3>Please enter the code that was sent to your phone</h3>
                <TextField
                  required
                  id="outlined-required"
                  label={"OTP"}
                  value={otp}
                  onChange={(e) => setOTP(e.target.value)}
                  placeholder="6-digit code"
                />
                <p>
                  Sent to {phoneNumber}.{" "}
                  <button
                    type="button"
                    onClick={resendVerification}
                    style={{
                      background: "none",
                      color: COLORS.PURPLE,
                      border: "none",
                      padding: "0",
                      font: "inherit",
                      cursor: "pointer",
                      outline: "inherit",
                    }}
                  >
                    I did not receive a code
                  </button>
                </p>
                {!signingIn ? (
                  <GradientButton disabled={!otp} onClick={validateOTP}>
                    Confirm
                  </GradientButton>
                ) : (
                  <GradientButton disabled={!otp}>
                    <em>Signing you in{dots}</em>
                  </GradientButton>
                )}
              </Box>
            </FormCard>
          )
        ) : (
          <HomeRedirection />
        )}
      </Box>
      <div ref={recaptchaWrapper}>
        <div id="recaptcha-container" className="justify-center flex"></div>
      </div>
    </GradientWrapper>
  );
}
