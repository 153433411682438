import { Box, Grid, TextareaAutosize, styled } from "@mui/material";
import React, { useRef } from "react";
import images from "../../../assets/images";
import { MessageController } from "../../../controllers/MessageController";
import "./index.css";
import { useFirestore } from "react-redux-firebase";
import { useOutletContext } from "react-router-dom";
import triggerCloudFunctions, {
  triggerDMCloudFunction,
  triggerMultiMediaCloudFunction,
  triggerTextCloudFunction,
} from "../../../utils/triggerCloudFunctions";
import { useSelector } from "react-redux";
import validator from "validator";

// import triggerCloudFunctions from "../../../utils/triggerCloudFunctions";

const StyledTextarea = styled(TextareaAutosize)(
  ({}) => `
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  width: 100%;
  padding: 0px 5px 0px 0px;
  // firefox
  &:focus-visible {
    outline: 0;
  }
`
);

const ComposerInput = ({
  isComposerOptionsVisible,
  setIsComposerOptionsVisible,
  previewList,
  setPreviewList,
  messageType,
  setMessageType,
  receiverId,
  receiverType,
  isSendBtnEnabled,
  setIsSendBtnEnabled,
  scrollToBottom,
  setMessageList,
  messageList,
  setUploadingProgress,
  setIsUploading,
}) => {
  const inputRef = useRef(null);
  const firestore = useFirestore();
  const { groupId } = useOutletContext();
  const { uid } = useSelector((state) => state.firebase.auth);

  const sendMessage = (
    receiverId,
    receiverType,
    messageType,
    previewList = []
  ) => {
    setIsSendBtnEnabled(false);
    if (previewList?.length > 0) {
      createAndSendMediaMessage(
        receiverId,
        receiverType,
        messageType,
        previewList
      );
    } else {
      createAndSendTextMessage(receiverId, receiverType);
    }
  };

  const getMappedFirebaseUId = (cometChatUID) => {
    return firestore
      .collection("users")
      .where("cometChatUID", "==", cometChatUID)
      .get();
  };

  const createAndSendTextMessage = async (receiverId, receiverType) => {
    let text = inputRef?.current?.value?.trim("");
    let isUrl = false;
    let tags = "links";
    text?.split(" ")?.map((item) => {
      if (validator.isURL(item)) isUrl = true;
    });
    if (text?.length > 0) {
      let textMessage = MessageController.createTextMessage(
        receiverId,
        receiverType,
        text
      );
      if (isUrl) textMessage.setTags([tags]);

      MessageController.sendTextMessage(textMessage)
        .then((message) => {
          console.log("Text Message sent successfully:", message);
          setMessageList([...messageList, message]);
          inputRef.current.value = "";
          scrollToBottom();
          if (isUrl) {
            triggerMultiMediaCloudFunction(textMessage, "LINK", uid, groupId);
          } else {
            triggerTextCloudFunction(message, uid, groupId);
          }
        })
        .catch((error) => {
          console.log("Text Message sending failed with error:", error);
        });
    }
  };

  const createAndSendMediaMessage = (
    receiverId,
    receiverType,
    mediaType,
    files
  ) => {
    const mediaMessage = MessageController.createMediaMessage(
      receiverId,
      files,
      receiverType,
      mediaType
    );
    let text = inputRef?.current?.value?.trim("");
    if (text?.length > 0) mediaMessage?.setCaption(text);
    setPreviewList([]);
    setIsUploading(true);

    if (mediaMessage)
      MessageController.sendMediaMessage(mediaMessage)
        .then((message) => {
          console.log("Media message sent successfully ", message);
          setMessageType("");
          setIsComposerOptionsVisible(false);
          setMessageList([...messageList, message]);
          setIsUploading(false);
          setUploadingProgress(0);
          scrollToBottom();
          inputRef.current.value = "";
          triggerMultiMediaCloudFunction(message, "MEDIA", uid, groupId);
        })
        .catch((err) => console.log("Error while sending media message ", err));
  };

  return (
    <Box xs={12} justifyContent="center" alignItems="center">
      <Grid container className="composer-input-container">
        <Grid
          item
          xs="auto"
          className={
            !isComposerOptionsVisible
              ? "toggleOnContainer"
              : "toggleOffContainer"
          }
          onClick={() => setIsComposerOptionsVisible(!isComposerOptionsVisible)}
        >
          <img
            className={"toggleIcon"}
            src={isComposerOptionsVisible ? images.plusIcon : images.crossIcon}
            alt="group-logo"
          />
        </Grid>
        <Grid item xs>
          <Grid container className="text-input" style={{ overflow: "hidden" }}>
            <Grid item xs style={{ height: "100%" }}>
              <StyledTextarea
                ref={inputRef}
                maxRows={5}
                aria-label="maximum height"
                placeholder="Write a message..."
                onChange={(e) => {
                  if (inputRef?.current?.value?.trim() !== "")
                    setIsSendBtnEnabled(true);
                }}
                className="text-input-inner"
                onKeyPress={(e) => {
                  if (e.key === "Enter") {
                    e.preventDefault();
                    sendMessage(
                      receiverId,
                      receiverType,
                      messageType,
                      previewList
                    );
                  }
                }}
              />
            </Grid>
            <Grid item xs="auto" style={{ alignSelf: "flex-end" }}>
              <div
                className="disableSendButton"
                style={
                  isSendBtnEnabled === true
                    ? { backgroundColor: "#6206bf" }
                    : null
                }
                onClick={() => {
                  sendMessage(
                    receiverId,
                    receiverType,
                    messageType,
                    previewList
                  );
                }}
              >
                <img src={images.sendIcon} alt="group-logo" />
              </div>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

export default ComposerInput;
