export const INVITATION_STATUS = {
  claimed: "claimed",
  tentative: "tentative",
  declined: "declined",
  pending: "pending",
};

export const claimOrDeclineInvitations = async (
  firestore,
  uid,
  groupId,
  status,
  currInvitationId = ""
) => {
  const userDoc = await firestore.collection("users").doc(uid).get();
  if (!userDoc.exists) return;
  const userData = userDoc.data();
  const phoneNumber =
    (userData?.phoneCountryCode || "") + (userData?.phoneNumber || "");
  const email = userData?.email || "";

  // Find any invitations to this group for this user

  const inAppInvites = await firestore
    .collection("groups")
    .doc(groupId)
    .collection("inAppInvitations")
    .where("toId", "==", uid)
    .where("status", "in", [
      INVITATION_STATUS.pending,
      INVITATION_STATUS.tentative,
    ])
    .get();
  const phoneInvites = await firestore
    .collection("groups")
    .doc(groupId)
    .collection("contactBasedInvitations")
    .where("phoneNumber", "==", phoneNumber)
    .where("status", "in", [
      INVITATION_STATUS.pending,
      INVITATION_STATUS.tentative,
    ])
    .get();

  const emailInvites = await firestore
    .collection("groups")
    .doc(groupId)
    .collection("contactBasedInvitations")
    .where("status", "in", [
      INVITATION_STATUS.pending,
      INVITATION_STATUS.tentative,
    ])
    .where("email", "==", email)
    .get();

  const userInvites = await firestore
    .collection("users")
    .doc(uid)
    .collection("invitations")
    .where("groupId", "==", groupId)
    .where("status", "in", [
      INVITATION_STATUS.pending,
      INVITATION_STATUS.tentative,
    ])
    .get();

  const inviteDocs = phoneInvites.docs.concat(
    emailInvites.docs,
    userInvites.docs,
    inAppInvites.docs
  );

  await Promise.all(
    inviteDocs.map(async (doc) => {
      await doc.ref.update({ status });
    })
  );

  // Find any contact based or in app invitations that match the currInvitationId
  // Note: this accounts for the edge case where currInvitationId is not found in above queries
  // e.g. phone number or email was not inputted by user

  const currInvitationContactBased = await firestore
    .collection("groups")
    .doc(groupId)
    .collection("contactBasedInvitations")
    .doc(currInvitationId)
    .get();

  if (
    currInvitationContactBased.exists &&
    !inviteDocs.some((doc) => doc.ref.id === currInvitationContactBased.ref.id)
  ) {
    await currInvitationContactBased.ref.update({ status });
  }
  const currInvitationInApp = await firestore
    .collection("groups")
    .doc(groupId)
    .collection("inAppInvitations")
    .doc(currInvitationId)
    .get();

  if (
    currInvitationInApp.exists &&
    !inviteDocs.some((doc) => doc.ref.id === currInvitationInApp.ref.id)
  ) {
    await currInvitationInApp.ref.update({ status });
  }
};

export const addInvitationToUser = async (
  firestore,
  uid,
  groupId,
  senderId,
  senderName,
  status,
  eventId = null
) => {
  // if (group.members.includes(uid)) return;

  const existingInvitations = await firestore
    .collection("users")
    .doc(uid)
    .collection("invitations")
    .where("groupId", "==", groupId)
    .where("status", "in", ["pending", "tentative"])
    .get();

  if (existingInvitations.size > 0) return;

  // If no existing invitations for this group, add one
  await firestore.collection("users").doc(uid).collection("invitations").add({
    groupId,
    senderId,
    senderName,
    status,
    inApp: false,
    eventId,
  });
};
