import { Grid, Typography } from "@mui/material";
import { applyActionCode } from "firebase/auth";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import GradientButton from "../../components/GradientButton";
import GradientWrapper from "../../components/GradientWrapper";
import { auth } from "../../services/firebaseConfig";
import "./index.css";

const EmailVerified = () => {
  const [response, setResponse] = useState(null);

  const location = useLocation();
  const queryString = location.search;
  const urlParams = new URLSearchParams(queryString);
  const actionCode = urlParams.get("oobCode");

  const goToSignIn = () => {
    console.log("action");
  };

  const verifyEmail = () => {
    applyActionCode(auth, actionCode)
      .then((res) => {
        setResponse({
          heading: "Thank you!",
          message: "We have confirmed that this is your email address!",
        });
        console.log("Verified");
      })
      .catch((err) => {
        setResponse({
          heading: "Oops!",
          message:
            "Something went wrong, we are unable to verify your email address!",
        });
      });
  };

  useEffect(() => {
    verifyEmail();
  }, []);

  return (
    <GradientWrapper noScroll>
      <Grid
        container
        alignItems="center"
        flexDirection="column"
        className="innerWhiteBox"
      >
        <Grid item xs={8} container flexDirection="column" alignItems="center">
          {response ? (
            <>
              <img
                className="logoImg"
                src="https://firebasestorage.googleapis.com/v0/b/joy-prototype.appspot.com/o/zeal-logo-stacked.svg?alt=media&token=d5b70703-8a4d-4626-a75f-63ab7664f6f7"
                alt="zeal logo"
              />
              <Typography
                className="heading"
                sx={[{ marginTop: "40px", marginBottom: "24px" }]}
                variant="h2"
              >
                {response?.heading}
              </Typography>
              <Typography className="message" sx={[{ marginBottom: "24px" }]}>
                {response?.message}
              </Typography>
              <GradientButton
                maxWidth="343px"
                onClick={() => {
                  goToSignIn();
                }}
              >
                <span className="button">Continue to Zeal</span>
              </GradientButton>
            </>
          ) : (
            <h1>Verifying...</h1>
          )}
        </Grid>
      </Grid>
    </GradientWrapper>
  );
};

export default EmailVerified;
