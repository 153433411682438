import * as React from 'react';
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import MobileStoreButton from 'react-mobile-store-button';
import './index.css'; // Tell webpack that Button.js uses these styles
import { Navigate } from "react-router-dom";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : 'rgba(255, 255, 255, 0.5)',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary
}));

export default function Home() {

  window.location.href = "https://getzeal.co"

  // Navigate to static word press page for now
  // TODO: Implement Zeal home page here.

  // const [toSignUp, setToSignUp] = React.useState(false);

  // if (toSignUp === true) {
  //   return <Navigate to="/signup" />;
  // }

  // return(
  //   <>
  //     <Grid container spacing={2} alignItems='center' justifyContent='center'>
  //       <Grid item xs={2} md={4}>
  //           <Item><h1>Find Your People On <strong>Zeal</strong></h1>
  //           <p style={{marginBottom: 50, fontFamily:'Ubuntu, sans-serif'}}>Zeal's mission is to promote social bonds by learning & doing activities together to drive well being.</p>
  //           <Grid container spacing={5} alignItems='center' justifyContent='center' textAlign='center' alignContent='center' flexDirection='column'>
  //             <MobileStoreButton
  //               store="ios"
  //               url={'https://itunes.apple.com/us/app'}
  //               linkProps={{ title: 'iOS Store Button' }}
  //             />
  //             {/* <MobileStoreButton
  //               store="android"
  //               url={'google play url'}
  //               linkProps={{ title: 'Google Play Button' }}
  //             /> */}
  //             <br></br>
  //           </Grid>
  //         </Item>
  //       </Grid>
  //       <Grid item xs={4} md={6}>
  //           <img style={{"maxWidth": "60vw", "height": "auto"}} className="img" alt="mockup" src={require('../../static/img/angled_mockup.png')} />
  //       </Grid>
  //     </Grid>
  //     <br/><br/>
  //     <div style={{alignItems: 'center', justifyContent: 'center', textAlign: 'center'}}>
  //       <Item>
  //         <h1>Ready to find your people?</h1>
  //         <div id="circle-btn">
  //           <div className="btn-container" style={{position: 'relative'}}>
  //             <svg xmlns="http://www.w3.org/2000/svg" version="1.1" id="arrow-svg" x="0px" y="0px" viewBox="0 0 512 512" width="30px" height="30px">
  //               <g>
  //                 <path style={{"fill":"#2196F3"}} d="M511.189,259.954c1.649-3.989,0.731-8.579-2.325-11.627l-192-192  c-4.237-4.093-10.99-3.975-15.083,0.262c-3.992,4.134-3.992,10.687,0,14.82l173.803,173.803H10.667  C4.776,245.213,0,249.989,0,255.88c0,5.891,4.776,10.667,10.667,10.667h464.917L301.803,440.328  c-4.237,4.093-4.355,10.845-0.262,15.083c4.093,4.237,10.845,4.354,15.083,0.262c0.089-0.086,0.176-0.173,0.262-0.262l192-192  C509.872,262.42,510.655,261.246,511.189,259.954z" data-original="#2196F3"/>
  //                 <path d="M309.333,458.546c-5.891,0.011-10.675-4.757-10.686-10.648c-0.005-2.84,1.123-5.565,3.134-7.571L486.251,255.88  L301.781,71.432c-4.093-4.237-3.975-10.99,0.262-15.083c4.134-3.992,10.687-3.992,14.82,0l192,192  c4.164,4.165,4.164,10.917,0,15.083l-192,192C314.865,457.426,312.157,458.546,309.333,458.546z" data-original="#000000" className="active-path" data-old_color="#000000" style={{"fill":"#FFFFFF"}}/>
  //                 <path d="M501.333,266.546H10.667C4.776,266.546,0,261.771,0,255.88c0-5.891,4.776-10.667,10.667-10.667h490.667  c5.891,0,10.667,4.776,10.667,10.667C512,261.771,507.224,266.546,501.333,266.546z" data-original="#000000" className="active-path" data-old_color="#000000" style={{"fill":"#FFFFFF"}}/>
  //               </g>
  //             </svg>
  //             <button onClick={() => setToSignUp(true)} id="cta-btn">Get Zeal</button>
  //           </div>
  //         </div>
  //       </Item>
  //     </div>
  //   </>)
}