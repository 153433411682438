export const getFormattedTimeFromDate = (date) => {
  if (!date || date == undefined) {
    return null;
  }
  return date.toLocaleString("en-US", {
    hour: "numeric",
    minute: "numeric",
    hour12: true,
  });
};

export const mergeOverlappingTimes = (times) => {
  for (const day in times) {
    const timesList = times[day];
    const combinedTimes = timesList.flat();
    const newTimes = [];
    combinedTimes.sort((a, b) => {
      if (a.startTime < b.startTime) {
        return -1;
      } else if (b.startTime > a.startTime) {
        return 1;
      } else {
        return 0;
      }
    });
    if (combinedTimes.length) {
      newTimes.push(combinedTimes[0]);
      for (let i = 1; i < combinedTimes.length; i++) {
        const top = newTimes[newTimes.length - 1];
        if (top.endTime < combinedTimes[i].startTime) {
          newTimes.push(combinedTimes[i]);
        } else if (top.endTime < combinedTimes[i].endTime) {
          top.endTime = combinedTimes[i].endTime;
        }
      }
    }
    times[day] = newTimes;
  }
};
