import { Grid } from "@mui/material";
import React, { useState } from "react";
import MediaOptions from "../MediaOptions";
import "./index.css";
import images from "../../../assets/images";
import { useNavigate, useOutletContext } from "react-router-dom";

const ComposerOptions = ({
  setPreviewList,
  setMessageType,
  setIsSendBtnEnabled,
  setCompressionProgress,
}) => {
  const [isMediaOptionsVisible, setIsMediaOptionsVisible] = useState(false);
  const { groupId } = useOutletContext();
  const navigate = useNavigate();

  return (
    <Grid container item sm={12} md={9} lg={7} className="composer-container">
      <Grid
        container
        item
        xs
        wrap="nowrap"
        className="composer-option-container"
        onClick={() => {
          setIsMediaOptionsVisible(!isMediaOptionsVisible);
        }}
      >
        <Grid item xs="auto" className="composer-option-icon">
          <img src={images.mediaIcon} alt="group-logo" />
        </Grid>
        <Grid item xs>
          Media
        </Grid>
      </Grid>
      <Grid
        container
        wrap="nowrap"
        item
        xs
        className="composer-option-container"
        onClick={() => {
          navigate(`/groups/${groupId}/create-poll`);
        }}
      >
        <Grid item xs="auto" className="composer-option-icon">
          <img src={images.pollIcon} alt="group-logo" />
        </Grid>
        <Grid item xs>
          Poll
        </Grid>
      </Grid>
      {/* <Grid
        container
        wrap="nowrap"
        item
        xs
        className="composer-option-container"
      >
        <Grid item xs="auto" className="composer-option-icon">
          <img src={images.fileIcon} alt="group-logo" />
        </Grid>
        <Grid item xs>
          File
        </Grid>
      </Grid> */}

      {isMediaOptionsVisible && (
        <MediaOptions
          setMessageType={setMessageType}
          setPreviewList={setPreviewList}
          setIsMediaOptionsVisible={setIsMediaOptionsVisible}
          setIsSendBtnEnabled={setIsSendBtnEnabled}
          setCompressionProgress={setCompressionProgress}
        />
      )}
    </Grid>
  );
};

export default ComposerOptions;
